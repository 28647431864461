import React, { createContext } from 'react';

const { Provider, Consumer } = createContext();

export { Provider, Consumer };

export default (Component) => (props) => (
  <Consumer>
    {(api) => <Component api={api} {...props} />}
  </Consumer>
);
