import { useEffect, useRef } from 'react';
import { func } from 'prop-types';

import { withAddPictures } from 'alp-shared-components/dist/bigdef/pictures/add/AddPicturesProvider';

import actionCableConsumer from '../../action-cable';

const PictureUpdater = (props) => {
  const { updatePicture } = props;

  const bigdefPicturesChannelRef = useRef();

  useEffect(() => {
    initBigdefPicturesChannel();

    return () => {
      removeActionCableSubscriptions(bigdefPicturesChannelRef.current)
    };
  }, []);

  const initBigdefPicturesChannel = () => {
    bigdefPicturesChannelRef.current = actionCableConsumer.subscriptions.create({ channel: 'BigdefPicturesChannel' }, {
      received: (newPicture) => {
        updatePicture(newPicture);
      }
    });
  };

  const removeActionCableSubscriptions = (subscription) => {
    if (subscription) {
      actionCableConsumer.subscriptions.remove(subscription);
    }
  }

  return null;
};

PictureUpdater.propTypes = {
  updatePicture: func
};

export default withAddPictures(['updatePicture'])(PictureUpdater);
