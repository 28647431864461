import React, { Component } from 'react';
import { array, func } from 'prop-types';
import { InView } from 'react-intersection-observer';
import { Helmet } from "react-helmet";

import Fab from '@material-ui/core/Fab';
import ReplayIcon from '@material-ui/icons/Replay';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { FloatingMediaSettingsProvider } from 'alp-shared-components/dist/shared/floating-media/settings-provider';
import { isMobile } from "alp-shared-components/dist/shared/utils";

import css from './styles.module.scss';

import Tile from './tile';
import Banner from '../shared/banner';

import { mediaItemsActions } from './actions';
import { queryActions } from './query/actions';
import { menuActions } from '../shared/menu/actions';

import Media from 'react-media';
import WordLists from "./word-lists";
import CurrentFilter from './current-filter';
import Landing from "../shared/landing";
import Groups from "./groups";
import Filter from "./filter";

const mobile = isMobile();

export class MediaItems extends Component {
  static propTypes = {
    mediaItems: array.isRequired, // from mediaItems store
    setMenuVariant: func.isRequired, // from menuActions
    fetchMediaItemsData: func.isRequired, // from mediaItemsActions
    setPage: func.isRequired, // from queryActions
    incrementPage: func.isRequired, // from queryActions
    setMediaItemsData: func.isRequired // from mediaItemsActions
  };

  state = {
    mediaItemsUpdatedAt: 0,
    wordListsMediaItem: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchMediaItems();
  }

  componentWillUnmount() {
    const { setPage, setMediaItemsData } = this.props;
    setPage(1);
    setMediaItemsData([]);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { mediaItems } = this.props;
    if (mediaItems !== prevProps.mediaItems) {
      const mediaItemsUpdatedAt = Date.now();
      this.setState({ mediaItemsUpdatedAt });
    }
  }

  fetchMediaItems() {
    const { fetchMediaItemsData } = this.props;
    fetchMediaItemsData();
  }

  renderMediaItems() {
    const { mediaItems } = this.props;

    return mediaItems.map((mediaItem) => (
      <Tile key={mediaItem.id} mediaItem={mediaItem} openWordListsDialog={this.openWordListsDialog} />
    ));
  }

  onChangeMedia = (matchMedia) => {
    const { setMenuVariant, closeMenu } = this.props;
    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  fetchNextPage() {
    const { incrementPage, hasMoreItems, fetchMediaItemsData } = this.props;
    if (!hasMoreItems) { return }

    incrementPage();

    fetchMediaItemsData();
  };

  handleProgressVisibilityChange = (inView) => {
    const { fetchingMediaItems } = this.props;
    if (inView && !fetchingMediaItems) {
      this.fetchNextPage();
    }
  };

  renderMetaTags() {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - learn ${process.env.REACT_APP_LANGUAGE_NAME} with interactive transcripts`;
    const description = `${process.env.REACT_APP_PROJECT_NAME} - an audio/video platform with karaoke-style, interactive Russian, Ukrainian, Czech, Polish, Hungarian, Romanian transcripts`;

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = process.env.REACT_APP_3EARS_PRODUCTION_URL;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  openWordListsDialog = (mediaItem) => {
    if (!mobile) {
      this.setState({wordListsMediaItem: mediaItem});
    }
  }

  closeWordListsDialog = () => {
    this.setState({ wordListsMediaItem: null });
  }

  render() {
    const { hasMoreItems, hasError, fetchingMediaItems, t } = this.props;
    const { mediaItemsUpdatedAt, wordListsMediaItem } = this.state;

    return (
      <FloatingMediaSettingsProvider>
        <Media
          query={{ minWidth: 1200 }}
          onChange={this.onChangeMedia}
        >
          {() =>
            <div className={css.mediaItems}>
              { process.env.REACT_APP_INDEX_LANDING_VISIBLE && <Landing /> }
              { process.env.REACT_APP_INDEX_BANNER_VISIBLE && <Banner /> }
              { this.renderMetaTags() }

              <Filter />

              <div className={css.tiles}>
                <CurrentFilter />

                <Groups />
                <div className={css.wrapper}>
                  {this.renderMediaItems()}
                </div>
                {(hasError ? fetchingMediaItems : hasMoreItems) && (
                  <InView
                    key={`spinner-${mediaItemsUpdatedAt}`}
                    as="div"
                    className={css.loadingIndicatorWrapper}
                    onChange={this.handleProgressVisibilityChange}
                  >
                    <span className={css.loader}>{t('mediaItems.loading')}</span>
                  </InView>
                )}
                {hasError && !fetchingMediaItems && (
                  <div className={css.loadingIndicatorWrapper}>
                    <Fab color="primary" onClick={() => this.fetchMediaItems()}>
                      <ReplayIcon fontSize="large" />
                    </Fab>
                  </div>
                )}
              </div>
            </div>
          }
        </Media>

        { !mobile && <WordLists mediaItem={wordListsMediaItem} onClose={this.closeWordListsDialog} /> }
      </FloatingMediaSettingsProvider>
    );
  }
}

const mapStateToProps = ({ mediaItems, entry }) => (
  {
    mediaItems: mediaItems.data,
    hasMoreItems: mediaItems.hasMoreItems,
    hasError: Boolean(mediaItems.error),
    fetchingMediaItems: entry.fetchingData
  }
);

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu,
  fetchMediaItemsData: mediaItemsActions.fetchData,
  setMediaItemsData: mediaItemsActions.setData,
  setPage: queryActions.setPage,
  incrementPage: queryActions.incrementPage
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MediaItems));
