import React, { createContext, forwardRef } from 'react';

const { Provider, Consumer } = createContext();

export { Provider, Consumer };

export default (Component) => forwardRef((props, ref) => (
  <Consumer>
    {(api) => <Component ref={ref} authenticationApi={api} {...props} />}
  </Consumer>
));
