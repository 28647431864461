import React, { useRef, useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { KnownWordIdsProvider } from 'alp-shared-components/dist/shared/providers/known-word-ids';
import { WordListWordIdsProvider } from 'alp-shared-components/dist/shared/providers/word-list-word-ids';
import { WordListsDialogProvider } from 'alp-shared-components/dist/shared/word-lists-dialog/provider';
import { KnownWordActionsProvider } from "alp-shared-components/dist/shared/providers/known-word-actions";
import { ListFunctionsProvider } from "alp-shared-components/dist/words/word-list/providers/list-functions/provider";

import MediaItemWordLists from "alp-shared-components/dist/words/media-item-word-lists";

import withBigdefAPI from '../../api/context-bigdef';
import withLearnerDataAPI from '../../api/context-learner-data';

import withAPI from "../../api/context";
import {default as BigdefContainer} from "../../shared/bigdef/container";

const WordLists = (props) => {
  const { mediaItem, onClose, bigdefApi, learnerDataApi, api, currentUserId } = props;

  const listActions = useRef({});

  const [bigdefWord, setBigdefWord] = useState(null);
  const [knownWords, setKnownWords] = useState([]);
  const [wordListWords, setWordListWords] = useState([]);

  useEffect(() => {
    if(currentUserId) {
      Promise.all([bigdefApi.knownDictionaryWordIds(), bigdefApi.wordListDictionaryWordIds()]).then((words) => {
        setKnownWords(words[0]);
        setWordListWords(words[1]);
      });
    } else {
      setKnownWords([]);
      setWordListWords([]);
    }
  }, [currentUserId]);

  const setActions = (wordListId, actions) => {
    listActions.current[wordListId] = actions;
  }

  const openBigdef = (word) => {
    setBigdefWord(word);
  }

  const closeBigdefContainer = () => {
    setBigdefWord(null);
  }

  const renderBigdefContainer = () => {
    return (
      <BigdefContainer
        open={Boolean(bigdefWord)}
        onClose={closeBigdefContainer}
        word={bigdefWord}
        getTranslations={bigdefApi.fetchDictionaryWordTranslations}
        fetchSampleSentences={api.fetchSampleSentences}
        fetchAudioSamples={bigdefApi.fetchAudioSamples}
        fetchPictures={bigdefApi.fetchPictures}
        lookup={bigdefApi.lookup}
        upVote={api.upVote}
        downVote={api.downVote}
        removeVote={api.removeVote}
        onSubmitSuggestion={bigdefApi.submitDictionaryWordSuggestion}
      />
    )
  }

  const checkDictionaryWordInWordList = (attrs) => {
    const { wordListId, dictionaryWordItem, checked } = attrs;

    return learnerDataApi.checkDictionaryWordInWordList(wordListId, dictionaryWordItem, checked);
  }

  const successCheckDictionaryWord = () => {
    bigdefApi.wordListDictionaryWordIds().then((data) => setWordListWords(data))
  }

  const changeKnownWord = (dictionaryWordId, checked) => {
    if (checked) {
      return learnerDataApi.knownSingleDefinitionForMediaItem(dictionaryWordId, mediaItem.id).then((data) => data);
    }

    return learnerDataApi.unknownSingleDefinitionForMediaItem(dictionaryWordId, mediaItem.id).then((data) => data);
  };

  const successChangeKnownWord = (data, checked, dictionaryWordId) => {
    if (data.known_word) {
      listActions.current['known'].addToList(data.known_word)

      if (!data.unknown_word) {
        listActions.current['unknown'].removeAllDefinitionsFromList(data.known_word)
      }
    }

    if (data.unknown_word) {
      listActions.current['unknown'].addToList(data.unknown_word)

      if (!data.known_word) {
        listActions.current['known'].removeAllDefinitionsFromList(data.unknown_word)
      }
    }

    bigdefApi.knownDictionaryWordIds().then((data) => setKnownWords(data))
  }

  const moveToKnown = (medItemId, item) => {
    return learnerDataApi.knownForMediaItem(medItemId, item).then(data => {
      bigdefApi.knownDictionaryWordIds().then((data) => setKnownWords(data))

      return data;
    })
  }

  const moveToUnknown = (medItemId, item) => {
    return learnerDataApi.unknownForMediaItem(medItemId, item).then(data => {
      bigdefApi.knownDictionaryWordIds().then((data) => setKnownWords(data))

      return data;
    })
  }

  const renderWordLists = () => {
    return (
      <ListFunctionsProvider
        openBigdef={openBigdef}
        fetchAdditionalItemInfo={learnerDataApi.fetchAdditionalInfoUserWord}
      >
        <MediaItemWordLists
          mediaItem={mediaItem}
          currentUserId={currentUserId}
          getData={bigdefApi.dictionaryWordsWithMediaItem}
          moveToKnown={moveToKnown}
          moveToUnknown={moveToUnknown}
          onClose={onClose}
          setActions={setActions}
        />
      </ListFunctionsProvider>
    )
  }

  return (
      <WordListWordIdsProvider wordListWordIds={wordListWords}>
        <KnownWordIdsProvider knownWordIds={knownWords}>
          <KnownWordActionsProvider
            changeKnownWord={changeKnownWord}
            successChangeKnownWord={successChangeKnownWord}
          >
            <WordListsDialogProvider
              fetchWordLists={learnerDataApi.fetchWordLists}
              checkDictionaryWord={checkDictionaryWordInWordList}
              successCheckDictionaryWord={successCheckDictionaryWord}
              createWordList={learnerDataApi.createWordList}
              saveWordList={learnerDataApi.updateWordList}
            >
              { renderWordLists() }
              { renderBigdefContainer() }
            </WordListsDialogProvider>
          </KnownWordActionsProvider>
        </KnownWordIdsProvider>
      </WordListWordIdsProvider>
  )
}

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

export default connect(mapStateToProps)(
  withBigdefAPI(
    withLearnerDataAPI(
      withAPI(WordLists)
    )
  )
);
