const PROFILE_STATISTIC_NAME_SPACE = 'profile_statistic';
const LEFT_MENU_NAME_SPACE = 'left_menu';
const I18N_NAME_SPACE = 'i18n';
const QUERY_FILTER_NAME_SPACE = 'query_filter'

const PROFILE_STATISTIC_KEYS = {
  leftRange: `${PROFILE_STATISTIC_NAME_SPACE}.leftRange`,
  rightRange: `${PROFILE_STATISTIC_NAME_SPACE}.rightRange`,
  wtypes: `${PROFILE_STATISTIC_NAME_SPACE}.wtypes`
}

const LEFT_MENU_KEYS = {
  open: `${LEFT_MENU_NAME_SPACE}.open`,
  openTheme: `${LEFT_MENU_NAME_SPACE}.openTheme`,
  openSubscriptions: `${LEFT_MENU_NAME_SPACE}.openSubscriptions`
}

const UI_LANGUAGE_KEYS = {
  lng: `${I18N_NAME_SPACE}.lng`,
}

const QUERY_FILTER_KEYS = {
  params: `${QUERY_FILTER_NAME_SPACE}.params`,
}

export { PROFILE_STATISTIC_KEYS, LEFT_MENU_KEYS, UI_LANGUAGE_KEYS, QUERY_FILTER_KEYS };
