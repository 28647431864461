import React from "react";
import { useTranslation } from 'react-i18next';
import Notifications from '@material-ui/icons/Notifications';

import css from './styles.module.scss';
import cssText from '../../../css/text.module.scss';
import cssBox from '../../../css/box.module.scss';
import cssVariables from '../../../css/variables.scss';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className={css.empty}>
      <div className={css.content}>
        <div className={`${css.symbol} ${cssBox.circle} ${cssBox.bg_light_background}`}>
          <div className={css.icon}>
            <Notifications style={{fontSize: 90, color: cssVariables.grey_primary}} />
          </div>
        </div>
        <div className={`${cssText.roboto_regular} ${cssText.small} ${cssText.cl_bluegrey_cards}`}>
          { t('header.notifications.emptyTitle') }
        </div>
      </div>
    </div>
  )
};

export default Empty;
