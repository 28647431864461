import { alertifyActions } from '../alertify/actions';
import { currentUserActions } from '../../current-user/actions';

const actions = {
  OPEN_AUTHENTICATION_MODAL: 'OPEN_AUTHENTICATION_MODAL',
  DISMISS_AUTHENTICATION_MODAL: 'DISMISS_AUTHENTICATION_MODAL',
  SET_ACTIVE_FORM: 'SET_ACTIVE_AUTHENTICATION_FORM'
};

const FBPixelEvents = (data, loginType, socialAccountProvider) => {
  console.log('loginType', loginType, socialAccountProvider);

  if (loginType === 'join') {
    window.fbq && window.fbq("trackCustom", "JoinViaEmail", {email: data.email});
  } else if (socialAccountProvider && data.new_user) {
    window.fbq && window.fbq("trackCustom", "JoinViaSocial", { provider: socialAccountProvider, email: data.email });
  }
};

const openAuthenticationModal = (reason) => (
  (dispatch, getState, { ITL }) => (
    ITL.login(reason)
      .then(({ data, loginType, socialAccountProvider }) => {
        dispatch(currentUserActions.setData(data));

        FBPixelEvents(data, loginType, socialAccountProvider);

        dispatch(alertifyActions.openAlertify({
          type: 'success',
          text: `authentication.${loginType}.successMessage`,
          interpolations: {
            fullName: data.full_name,
            provider: socialAccountProvider,
            email: data.email
          }
        }));

        return data;
      })
      .catch((error) => {
        dispatch(alertifyActions.openAlertify({type: 'error', text: error.response.data.error}));
        return Promise.reject(error);
      })
  )
);

const dismissAuthenticationModal = () => ({
  type: actions.DISMISS_AUTHENTICATION_MODAL
});

const setActiveForm = (activeForm) => ({
  type: actions.SET_ACTIVE_FORM,
  payload: activeForm
});

const authenticationModalActions = {
  openAuthenticationModal,
  dismissAuthenticationModal,
  setActiveForm
};

export { actions, authenticationModalActions };
