import React from 'react';
import { string } from "prop-types";

import { makeStyles } from '@material-ui/core/styles';

import Svg from "../../ui/svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '24px',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    }
  }
}));

const SocialAccountIcon = (props) => {
  const { kind } = props;
  const styles = useStyles();

  return (
    <Svg name={kind} css={styles.icon} />
  )
}

SocialAccountIcon.props = {
  kind: string
}

export default SocialAccountIcon
