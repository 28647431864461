import React from 'react';
import { useTranslation } from 'react-i18next';

import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import InfoIcon from '@material-ui/icons/Info';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import StyleIcon from '@material-ui/icons/Style';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import css from './styles.module.scss';

const iconStyles = makeStyles((theme) => ({
  root: {
    fontSize: 48
  }
}))

const Header = () => {
  const classes = iconStyles();
  const { t } = useTranslation();

  const renderItem = (icon, title, description) => {
    return (
      <div className={css.item}>
        { icon }
        <Typography noWrap variant="h6" color="textPrimary">
          { title }
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          { description }
        </Typography>
      </div>
    )
  }

  return (
    <div className={css.header}>
      { renderItem(
        <VideoLabelIcon className={classes.root} />,
        t('landing.header.interactivePlayer.title'),
        t('landing.header.interactivePlayer.description')
      ) }
      { renderItem(
        <InfoIcon className={classes.root} />,
        t('landing.header.wordInformation.title'),
        t('landing.header.wordInformation.description')

      ) }
      { renderItem(
        <ListAltIcon className={classes.root} />,
        t('landing.header.wordLists.title'),
        t('landing.header.wordLists.description')

      ) }
      { renderItem(
        <EqualizerIcon className={classes.root} />,
        t('landing.header.wordStatistics.title'),
        t('landing.header.wordStatistics.description')
      ) }
      { renderItem(
        <StyleIcon className={classes.root} />,
        t('landing.header.flashcards.title'),
        t('landing.header.flashcards.description')
      ) }
    </div>
  )
}

export default Header;
