import React, { Component } from 'react';
import { func, array, bool } from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Media from 'react-media';
import { Helmet } from "react-helmet";

import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';

import { connect } from 'react-redux';

import { queryActions } from './query/actions';
import { feedbacksActions } from './actions';
import { menuActions } from "../shared/menu/actions";

import css from './styles.module.scss';

import Item from './item';
import SearchPanel from "./search-panel";
import Empty from '../shared/empty-content';

class Feedbacks extends Component {
  static propTypes = {
    feedbacks: array, // from store
    hasMore: bool.isRequired, // from feedbacks
    fetchFeedbacksData: func.isRequired, // from feedbacksActions
    setFeedbacksData: func.isRequired, // from feedbacksActions
    setPage: func.isRequired, // from queryActions
    incrementPage: func.isRequired, // from queryActions
    setMenuVariant: func.isRequired, // from menuActions
    closeMenu: func.isRequired // from menuActions
  };

  static defaultProps = {
    feedbacks: []
  };

  componentDidMount() {
    this.fetchFeedbacks();
  }

  componentWillUnmount() {
    const { setPage, setFeedbacksData } = this.props;
    setPage(1);
    setFeedbacksData([])
  }

  fetchFeedbacks() {
    const { fetchFeedbacksData } = this.props;

    fetchFeedbacksData();
  }

  renderFeedbacks() {
    const { feedbacks } = this.props;

    if (isEmpty(feedbacks)) {
      return <Empty />;
    }

    return (
      <List disablePadding>
        { feedbacks.map((fedback) => <Item key={fedback.id} feedback={fedback} />) }
      </List>
    )
  }

  onChangeMedia = (matchMedia) => {
    const { setMenuVariant, closeMenu } = this.props;

    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  getScrollParent = () => this.props.scrollParent;

  fetchData = () => {
    const { incrementPage, hasMore, fetchFeedbacksData } = this.props;
    if (!hasMore) { return }

    incrementPage();
    fetchFeedbacksData();
  };

  renderMetaTags() {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - Feedback`;
    const description = "User's Feedback";

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/feedback`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  };

  render() {
    const { hasMore } = this.props;

    return (
      <Media
        query={{ minWidth: 1400 }}
        onChange={this.onChangeMedia}
      >
        {() =>
          <div className={css.feedbacks}>
            { this.renderMetaTags() }

            <SearchPanel />
            <div className={css.items}>
              <InfiniteScroll
                initialLoad={false}
                pageStart={1}
                loadMore={this.fetchData}
                hasMore={hasMore}
                threshold={0}
                loader={
                  <div key={0} className={css.has_more_loader}>
                    <CircularProgress />
                  </div>
                }
                useWindow={false}
                getScrollParent={this.getScrollParent}
              >
                {this.renderFeedbacks()}
              </InfiniteScroll>
            </div>
          </div>
        }
      </Media>
    )
  }
}

const mapStateToProps = ({ feedbacks, feedbacks: { query } }) => (
  {
    queryOrder: query.order,
    feedbacks: feedbacks.data,
    hasMore: feedbacks.hasMore,
  }
);

const mapDispatchToProps = {
  fetchFeedbacksData: feedbacksActions.fetchData,
  setFeedbacksData: feedbacksActions.setData,
  setPage: queryActions.setPage,
  incrementPage: queryActions.incrementPage,
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedbacks);
