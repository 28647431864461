import React from 'react';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import Empty from '../../shared/empty-content';
import { authenticationModalActions } from '../../shared/authentication-modal/actions';
import { profileActions } from '../actions';
import { profileUrl } from '../utils';

import Content from './content';

const Words = (props) => {
  const { isYourProfile, currentUserSlug, login } = props;

  const { t } = useTranslation();

  const handleLogin = () => {
    login();
    // TODO make it
    // login().then(() => {
    //   setCurrentTab(tabs.words)
    // })
  }

  const renderEmptyContent = () => {
    if (currentUserSlug) {
      return (
        <Link component={RouterLink} to={profileUrl(currentUserSlug)} underline="none">
          <Button color="primary">
            {t('profile.words.openYourProfileButtonTitle')}
          </Button>
        </Link>
      )
    } else {
      return(
        <Button onClick={handleLogin} color="primary">
          {t('profile.words.loginButtonTitle')}
        </Button>
      )
    }
  }

  if (!isYourProfile) {
    return (
      <Empty emptyText={t('profile.words.emptyMessage')}>
        { renderEmptyContent() }
      </Empty>
    )
  }

  return (
    <Content />
  )
}

const mapStateToProps = ({ profile, currentUser }) => ({
  currentUserSlug: currentUser.slug,
  isYourProfile: profile.id === currentUser.id
});

const mapDispatchToProps = {
  login: authenticationModalActions.openAuthenticationModal,
  setCurrentTab: profileActions.setCurrentTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Words)
