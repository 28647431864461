import { filesActions } from './files/actions';
import { currentUserActions } from '../current-user/actions';
import { alertifyActions } from "../shared/alertify/actions";

const nameSpace = 'PROFILE_';

const actions = {
  SET_DATA: nameSpace + 'SET_DATA',
  ADD_SOCIAL_ACCOUNT: nameSpace + 'ADD_SOCIAL_ACCOUNT',
  DELETE_SOCIAL_ACCOUNT: nameSpace + 'DELETE_SOCIAL_ACCOUNT',
  SET_CURRENT_TAB: nameSpace + 'SET_CURRENT_TAB'
};

const fetchData = (userId, currentFolderId = null) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.fetchProfileData(userId).then((data) => {
      dispatch(filesActions.fetchData(data.id, currentFolderId));
      dispatch(setData(data));
      return data;
    })
  )
);

const removeAvatar = () => (
  (dispatch, getState, { ITL }) => (
    ITL.api.removeAvatar().then((data) => {

      dispatch(setData(data));
      dispatch(currentUserActions.setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.info.settings.general.avatar.removeSuccessMessage'
      }));

      return data;
    })
  )
);

const toggleFollow = (followeeId) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.toggleFollow(followeeId).then((data) => {
      dispatch(setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: data.is_followed ? 'profile.info.successFollowMessage' : 'profile.info.successUnFollowMessage'
      }));

      return data;
    })
  )
);

const update = (profile) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.updateProfile(profile).then((data) => {
      dispatch(setData(data));
      dispatch(currentUserActions.setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.info.successUpdateMessage'
      }));

      return data;
    })
  )
);

const createSocialAccount = (account) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.createProfileSocialAccount(account).then((data) => {
      dispatch(addSocialAccount(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.info.settings.socialAccounts.createSocialAccountSuccessMessage'
      }));

      return data;
    })
  )
);

const removeSocialAccount = (id) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.removeProfileSocialAccount(id).then((data) => {
      dispatch(deleteSocialAccount(data.id));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.info.settings.socialAccounts.removeSocialAccountSuccessMessage'
      }));

      return data;
    })
  )
);

const updateEmailNotificationSetting = (params) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.updateProfileEmailNotificationSetting(params).then((data) => {
      dispatch(setData({ profile_email_notification_setting: data }));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.info.settings.notifications.updateSuccessMessage'
      }));

      return data;
    })
  )
);

const setData = (data) => (
  {
    type: actions.SET_DATA,
    payload: data
  }
);

const addSocialAccount = (data) => (
  {
    type: actions.ADD_SOCIAL_ACCOUNT,
    payload: data
  }
);

const deleteSocialAccount = (id) => (
  {
    type: actions.DELETE_SOCIAL_ACCOUNT,
    payload: id
  }
);

const setCurrentTab = (tab) => (
  {
    type: actions.SET_CURRENT_TAB,
    payload: tab
  }
);

const profileActions = {
  fetchData,
  setData,
  removeAvatar,
  toggleFollow,
  update,
  createSocialAccount,
  removeSocialAccount,
  updateEmailNotificationSetting,
  setCurrentTab
};

export { actions, profileActions };
