import React from 'react';
import { object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';

import { withSubscription } from './providers/subscription';

const useStyles = makeStyles((theme) => ({
  subscribedButton: {
    color: 'white',
    backgroundColor: green[700],
    border: '1px solid rgba(56, 142, 60, 0.5)',
    '&:hover': {
      border: '1px solid rgba(56, 142, 60, 0.5)',
      backgroundColor: green[800],
    }
  }
}));

const Subscribe = (props) => {
  const { group, toggleSubscription, subscribed } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  const handleToggleSubscription = () => {
    toggleSubscription(group.id)
  }

  return (
    <Button
      className={subscribed ? styles.subscribedButton : ''}
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleToggleSubscription}
    >
      { subscribed ? t('group.subscription.unsubscribe') : t('group.subscription.subscribe') }
    </Button>
  )
}

Subscribe.propTypes = {
  group: object,
  toggleSubscription: func,
  subscribed: bool
}

export default withSubscription(Subscribe);
