import React from 'react';

import Order from './order';
import KindFilter from "./kind-filter";
import StateFilter from './state-filter';

import css from './styles.module.scss';

const SearchPanel = () =>
  <div className={css.search_panel}>
    <div className={css.filters}>
      <KindFilter />
      <StateFilter />
    </div>
    <Order />
  </div>;

export default SearchPanel;
