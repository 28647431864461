import React from 'react';
import { string, func } from 'prop-types';

import { connect } from 'react-redux';

import { default as SharedOrder } from '../../shared/order';
import { queryActions } from '../query/actions';
import { feedbacksActions } from '../../feedbacks/actions';

const Order = (props) => {
  const {
    queryOrder,
    setOrderToQuery,
    setPage,
    fetchFeedbacksData
  } = props;

  const changeOrder = (order) => {
    setOrderToQuery(order);
    setPage(1);
    fetchFeedbacksData();
  };

  return (
    <SharedOrder queryOrder={queryOrder} onChange={changeOrder} />
  )
};

const mapStateToProps = ({ feedbacks: { query } }) => (
  { queryOrder: query.order }
);

const mapDispatchToProps = {
  setOrderToQuery: queryActions.setOrder,
  setPage: queryActions.setPage,
  fetchFeedbacksData: feedbacksActions.fetchData
};

Order.propTypes = {
  queryOrder: string.isRequired, // from feedbacks query
  setOrderToQuery: func.isRequired, // from queryActions
  setPage: func.isRequired, // from queryActions
  fetchFeedbacksData: func.isRequired // from feedbacksActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
