import React from 'react';
import { Trans } from 'react-i18next';
import Button from '@material-ui/core/Button'

import css from './styles.module.scss';

const ErrorTemplate = () => {
  const reload = () => {
    window.location.reload()
  }

  return (
    <div className={css.grid}>
      <Trans i18nKey="errorBoundary.errorTemplate">
        <div className={css.ears}>ERROR</div>
        <div className={css.alerts}>We are sorry, but something went wrong</div>
        <Button color="primary" variant="contained" onClick={reload}>Click here to reload this page</Button>
        <div className={css.description}>Or</div>
        <div className={css.description}>Please contact us via our feedback form</div>
        <div className={css.description}>by clicking on the button at the bottom right.</div>
      </Trans>
    </div>
  )
}

export default ErrorTemplate;
