import React, {useEffect, useState} from 'react';
import { array, object, number, func } from 'prop-types';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';

import css from './styles.module.scss';

import MenuList from './list';
import { queryActions } from "../../media-items/query/actions";
import { profileUrl } from '../../profile/utils';
import { getDataFromLocalStorage, setDataToLocalStorage } from "../../local-storage";
import { LEFT_MENU_KEYS } from "../../local-storage/constants";

const listOpenedStored = getDataFromLocalStorage(LEFT_MENU_KEYS.openSubscriptions)

const Subscriptions = (props) => {

  const {
    subscriptions,
    history,
    producerId,
    setProducer
  } = props;

  const [listOpened, setListOpened] = useState(listOpenedStored == undefined ? true : listOpenedStored);

  useEffect(() => {
    setDataToLocalStorage(LEFT_MENU_KEYS.openSubscriptions, listOpened)
  }, [listOpened])

  const { t } = useTranslation();

  const isSubscriptionInQuery = (subscription) => {
    return producerId === subscription.id
  };

  const clickOnSubscription = (subscription) => () => {
    history.replace('/');

    if (producerId === subscription.id) {
      setProducer({});
    }
    else {
      setProducer({ id: subscription.id, fullName: subscription.full_name });
    }
  };

  const handleClickOnAvatar = (e) => {
    e.stopPropagation();
  }

  return (
    <MenuList
      head={t('menu.subscriptions', { count: subscriptions.length })}
      listOpened={listOpened}
      setListOpened={setListOpened}
    >
      {subscriptions.map((subscription) => (
        <ListItem
          className={css.subscription}
          selected={isSubscriptionInQuery(subscription)}
          button
          key={subscription.id}
          onClick={clickOnSubscription(subscription)}
        >
          <ListItemAvatar>
            <Link onClick={handleClickOnAvatar} component={RouterLink} to={profileUrl(subscription.slug)} color="inherit" underline="none">
              <Avatar src={subscription.avatar.small} className={css.avatar} />
            </Link>
          </ListItemAvatar>

          <ListItemText primary={subscription.full_name}/>
          {isSubscriptionInQuery(subscription) && <CheckIcon color="primary" />}
        </ListItem>
      ))}
    </MenuList>
  )
};

Subscriptions.propTypes = {
  subscriptions: array.isRequired,
  history: object.isRequired,
  producerId: number,
  setProducer: func.isRequired
};

const mapStateToProps = ({ menu, mediaItems: { query } }) => (
  {
    subscriptions: menu.subscriptions,
    producerId: query.producer.id
  }
);

const mapDispatchToProps = {
  setProducer: queryActions.setProducer
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
