import React, { useRef } from 'react';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

const CommonField = ({ field, isError, label, type }) => {
  const labelRef = useRef(null);
  const labelWidth = labelRef.current ? labelRef.current.offsetWidth : 0;

  return (
    <FormControl margin="dense" error={isError} fullWidth variant="outlined">
      <InputLabel ref={labelRef}>{label}</InputLabel>
      <OutlinedInput
        {...field}
        type={type}
        autoComplete={type}
        labelWidth={labelWidth}
      />
      <FormHelperText>
        <ErrorMessage name={field.name}/>
      </FormHelperText>
    </FormControl>
  )
};

const PasswordField = ({ field, form: { touched, errors }}) => {
  const { t } = useTranslation();
  const isError = errors.password && touched.password;
  const label = t('authentication.inputs.password.label');

  return (
    <CommonField
      field={field}
      isError={isError}
      label={label}
      type="password"
    />
  )
};

export { PasswordField }
