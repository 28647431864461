import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { string, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MUButton from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Svg from '../../ui/svg';

import css from './styles.module.scss';
import { queryActions } from '../query/actions';

const Button = withStyles((theme) => ({
  root: {
    textTransform: 'none'
  }
}))(MUButton);

const themeHeaderStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    '& > i': {
      marginRight: 10
    }
  }
}))

const ThemeHeader = (props) => {
  const { theme, queryTheme, setTheme } = props;
  const themeStyles = themeHeaderStyles();
  const { t } = useTranslation();

  const seeMore = () => {
    setTheme(theme)
  }

  return (
    <div className={css.themeHeader}>
      <Typography
        classes={{ root: themeStyles.header }}
        variant="h6"
        weight="bold"
        bottomSpace="small"
        color="textPrimary"
      >
        <Svg name={theme} />
        { t(`themesList.${theme}`) }
      </Typography>
      { !queryTheme && (
        <Button color="primary" onClick={seeMore}>
          { t('mediaItems.groups.seeMore') }
        </Button>
      )}
    </div>
  )
}

ThemeHeader.propTypes = {
  theme: string,
  queryTheme: string,
  setTheme: func
}

const mapStateToProps = ({ mediaItems: { query } }) => (
  {
    queryTheme: query.theme
  }
);

const mapDispatchToProps = {
  setTheme: queryActions.setTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeHeader)
