import React from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import { makeStyles } from'@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default
  }
}));

const Empty = (props) => {
  const { children, emptyText, className } = props;
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={clsx(className, css.empty)}>
      <div className={clsx(css.wrapper, styles.wrapper)}>
        <div className={css.content}>
          <div className={css.folder}>
            <FolderIcon color="primary" />
          </div>
          <Typography color="textSecondary" className={css.title}>
            { emptyText || t('emptyContent.title') }
          </Typography>
          { children }
        </div>
      </div>
    </div>
  );
};

Empty.propTypes = {
  children: node, // from props
  emptyText: string, // from props
  className: string // from props
};

export default Empty;
