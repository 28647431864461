import React from 'react';
import { object } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Tag from "./tag";

const useStyles = makeStyles((theme) => ({
  items: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

const MediaTags = (props) => {
  const { group } = props;

  const styles = useStyles();

  return (
    <div className={styles.items}>
      { group.media_tags.map((tag) => <Tag key={tag.id} tag={tag}/>) }
    </div>
  )
}

MediaTags.propTypes = {
  group: object
}

export default MediaTags
