import React from 'react';
import { object } from 'prop-types';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import FaceIcon from '@material-ui/icons/Face';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import css from './styles.module.scss';
import { profileUrl } from '../../../profile/utils';

const Creator = (props) => {
  const { feedback } = props;
  const { user } = feedback;

  return user ?
    <Link className={css.creator} component={RouterLink} to={profileUrl(user.slug)} underline="none">
      <Chip
        color="primary"
        label={user.full_name}
        variant="outlined"
        avatar={<Avatar src={feedback.user.avatar.small} />}
        className={css.user_chip}
      />
    </Link>
    :
    <Chip
      color="primary"
      variant="outlined"
      label="Guest"
      icon={<FaceIcon color="primary" />}
    />
};

Creator.propTypes = {
  feedback: object // from props
};

export default Creator;
