import React, { Fragment } from 'react';
import { func, node, number, string, bool, object } from 'prop-types';
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import css from './styles.module.scss';

const Section = (props) => {
  const { children, onClick, tab, header, isYourProfile, primaryTypographyProps } = props;

  const editSection = () => {
    onClick(tab);
  };

  const renderEditIcon = () => {
    if (isYourProfile && onClick) {
      return (
        <IconButton onClick={editSection}>
          <EditIcon />
        </IconButton>
      );
    }

    return null;
  };

  return(
    <ListItem>
      <ListItemText primaryTypographyProps={primaryTypographyProps} classes={{primary: css.item}}
        primary={
          <Fragment>
            <span className={css.header}>{ header }</span>
            { renderEditIcon() }
          </Fragment>
        }
        secondary={children}
      />
    </ListItem>
  )
};

Section.propTypes = {
  children: node,
  onClick: func,
  tab: number.isRequired,
  header: string.isRequired,
  isYourProfile: bool.isRequired,
  primaryTypographyProps: object
};

Section.defaultProps = {
  primaryTypographyProps: {}
};

const mapStateToProps = ({ profile, currentUser }) => (
  {
    isYourProfile: profile.id === currentUser.id
  }
);

export default connect(mapStateToProps)(Section);
