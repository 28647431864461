import React from 'react';
import { bool, func } from 'prop-types';

import { useTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';

import Item from '../../../../../ui/menu/item';

const Add = (props) => {
  const { onClick, visible } = props;
  const { t } = useTranslation();

  if (visible) {
    return (
      <Item icon={<AddIcon color="primary" />} onClick={onClick} text={t('profile.files.folderActions.new')} />
    )
  }

  return null;
};

Add.propsTypes = {
  visible: bool.isRequired, // from props
  onClick: func.isRequired, // from props
};

export default Add;
