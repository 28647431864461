import React, { createContext } from 'react';

const { Provider, Consumer } = createContext();

export { Provider };

export default (Component) => (props) => (
  <Consumer>
    {({ sendMessage, setAction }) => (
      <Component sendMessageToIframe={sendMessage} setSendMessageToIframe={setAction} {...props} />
    )}
  </Consumer>
);
