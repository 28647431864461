import React, { Component } from 'react'
import LazyLoad from "vanilla-lazyload";
import { string } from "prop-types";

import { withStyles } from '@material-ui/core/styles';

const config =  {
  elements_selector: ".lazy"
};

const styles = (theme) => ({
  picture: {
    '& img': {
      width: '100%',
      display: 'block',
      backgroundColor: theme.palette.divider
    }
  }
});

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad(config);
}

class LazyPicture extends Component {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    document.lazyLoadInstance.update();
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    document.lazyLoadInstance.update();
  }

  render() {
    const { alt, webpSrc, src, classes } = this.props;

    return (
      <picture className={classes.picture}>
        <source
          type="image/webp"
          data-srcset={webpSrc}
        />
        <img
          alt={alt}
          className="lazy"
          data-src={src}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEElEQVR42mNkIAAYRxWAAQAG9gAKqv6+AwAAAABJRU5ErkJggg==" // placeholder
        />
      </picture>
    );
  }
}

LazyPicture.propTypes = {
  alt: string,
  webpSrc: string,
  src: string
}

export default withStyles(styles)(LazyPicture);
