import React from 'react';
import { useTranslation } from 'react-i18next';

import withAPI from '../../api/context';
import Avatar from '../../ui/avatar';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";

import css from './styles.module.scss';
import { profileUrl } from '../../profile/utils';
import Svg from '../../ui/svg';

const Person = (props) => {
  const { profileData } = props;

  const { t } = useTranslation();

  if (profileData) {
    return(
      <div className={css.person}>
        <div className={css.wrapper}>
          <RouterLink
            to={{
              pathname: profileUrl(profileData.slug)
            }}
          >
            { profileData.avatar && (
              <Avatar img={profileData.avatar.medium} size="medium"/>
            )}
          </RouterLink>

          <div className={css.description}>
            <Typography variant="overline" display="block" gutterBottom>
              { profileData.volunteer_description }
            </Typography>
            <h3>
              { t('thankYou.person.iAm', { fullName: profileData.full_name }) }
            </h3>
            <p dangerouslySetInnerHTML={{ __html: profileData.about }} />

            <div className={css.social_accounts}>
              {
                profileData.profile_social_accounts.map((account) => (
                  <Link key={account.kind} href={account.link} target='_blank'>
                    <Svg name={account.kind}/>
                  </Link>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null;
}

export default withAPI(Person);
