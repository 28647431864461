import React, { Component, createRef } from 'react';
import { object, func, node, string } from 'prop-types';

import { Formik } from 'formik';
import css from './styles.module.scss';

import Fab from '@material-ui/core/Fab';

class Form extends Component {
  constructor(props) {
    super(props);

    this.formRef = createRef();
  }

  componentDidMount() {
    this.initErrors();
  }

  initErrors() {
    const { errors } = this.props;

    this.formRef.current.setErrors(errors);
    Object.keys(errors).forEach((fieldName) => this.formRef.current.setFieldTouched(fieldName, true, false))
  }

  render() {
    const {
      initialValues,
      onSubmit,
      children,
      submitButtonText
    } = this.props;

    return (
      <div className={css.form}>
        <Formik
          ref={this.formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={formProps => {
            return (
              <form>
                {children}
                <div className={css.actions}>
                  <Fab
                    className={css.submit_button}
                    size="medium"
                    variant="extended"
                    color="primary"
                    onClick={formProps.submitForm}
                  >
                    { submitButtonText }
                  </Fab>
                </div>
              </form>
            )
          }}
        />
      </div>
    );
  }
};

Form.propTypes = {
  initialValues: object.isRequired, // from props
  errors: object, // from props
  onSubmit: func.isRequired, // from props
  children: node.isRequired, // from props
  submitButtonText: string.isRequired // from props
};

Form.defaultProps = {
  errors: {}
};

export default Form;
