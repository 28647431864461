import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { default as MUDialog } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import css from './styles.module.scss';

const Dialog = (props) => {
  const { t } = useTranslation();

  const {
    children,
    headerText,
    onClose,
    onCancel,
    onSubmit,
    open,
    ...restProps
  } = props;

  return (
    <MUDialog
      { ...restProps }
      open={open}
      onClose={onClose}
      classes={{
        root: css.root,
        paper: css.paper
      }}
    >
      <DialogTitle onClose={onClose}>
        {headerText}
      </DialogTitle>
      { children }
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('buttonTitles.cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary">
          {t('buttonTitles.submit')}
        </Button>
      </DialogActions>
    </MUDialog>

  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired, // from props
  children: PropTypes.object, // from props
  headerText: PropTypes.string, // from props
  onClose: PropTypes.func.isRequired, // from props
  onCancel: PropTypes.func.isRequired, // from props
  onSubmit: PropTypes.func.isRequired // from props
};

Dialog.defaultProps = {
  headerText: null
};

export default Dialog;
