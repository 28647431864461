import React, { useRef, useEffect } from 'react';
import { array, func, number } from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import ReactCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import css from "./styles.module.scss";
import Tile from "../../tile";
import SeeMore from "../../see-more-tile";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 6
  },
  largeDesktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 820 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 820, min: 420 },
    items: 2
  },
  smallMobile: {
    breakpoint: { max: 420, min: 0 },
    items: 1
  }
};

const Carousel = (props) => {
  const { groups, menuOpened, menuVariant, handleSeeMore, perPage } = props;

  const carouselRef = useRef(null);

  const ifSeeMore = groups.length >= perPage

  useEffect(() => {
    if (menuVariant === 'persistent') {
      setTimeout(() => {
        carouselRef.current && carouselRef.current.setItemsToShow(true)
      }, 300);
    }

  }, [menuOpened]);

  useEffect(() => {
    carouselRef.current && carouselRef.current.setState({
      currentSlide: 0,
      transform: 0,
      totalItems: ifSeeMore ? groups.length + 1 : groups.length // for see more title
    });

  }, [groups]);

  return (
    <ReactCarousel
      renderButtonGroupOutside
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      shouldResetAutoplay={false}
      className={clsx(css.carousel)}
      containerClass="container"
      dotListClass=""
      draggable={false}
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderDotsOutside={false}
      partialVisible
      responsive={responsive}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      ref={carouselRef}
    >
      { groups.map((group) => (
        <Tile
          key={group.id}
          group={group}
        />
      ))}
      { ifSeeMore && <SeeMore onClick={handleSeeMore} /> }
    </ReactCarousel>
  )
}

Carousel.propsTypes = {
  groups: array,
  handleSeeMore: func,
  perPage: number
}

const mapStateToProps = ({ menu }) => (
  {
    menuOpened: menu.opened,
    menuVariant: menu.variant
  }
);

export default connect(mapStateToProps)(Carousel)
