import {entryActions} from "../entry/actions";

const nameSpace = 'FEEDBACKS_';

const actions = {
  SET_DATA: nameSpace + 'SET_DATA',
  ADD_DATA: nameSpace + 'ADD_DATA',
  SET_HAS_MORE: nameSpace + 'SET_HAS_MORE'
};

const fetchData = () => (
  (dispatch, getState, { ITL }) => {
    dispatch(entryActions.startFetchData());

    const { page, perPage, order, kind, state } = getState().feedbacks.query;

    return ITL.api.fetchFeedbacks({ page, perPage, order, kind, state }).then((data) => {
      dispatch(setHasMore(data.length >= perPage));

      page === 1 ? dispatch(setData(data)) : dispatch(addData(data));

      dispatch(entryActions.endFetchData());
      return data
    }).catch(error => {
      dispatch(entryActions.endFetchData());
    });
  }
);

const setData = (data) => (
  {
    type: actions.SET_DATA,
    payload: data
  }
);

const addData = (data) => (
  {
    type: actions.ADD_DATA,
    payload: data
  }
);

const setHasMore = (value) => (
  {
    type: actions.SET_HAS_MORE,
    payload: value
  }
);

const feedbacksActions = {
  fetchData, setData
};

export { actions, feedbacksActions };
