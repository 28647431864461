import React from "react";
import Media from 'react-media';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { menuActions } from "../shared/menu/actions";

import css from './styles.module.scss';

const Base = (props) => {
  const { children, setMenuVariant, closeMenu, cssGrid } = props;

  const onChangeMedia = (matchMedia) => {
    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  return (
    <Media
      query={{ minWidth: 1440 }}
      onChange={onChangeMedia}
    >
      {() =>
        <div className={css.wrapper}>
          <Paper className={clsx(css.grid, cssGrid)}>
            {children}
          </Paper>
        </div>
      }
    </Media>
  )
};

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu
};

export default connect(null, mapDispatchToProps)(Base);
