/**
 * Gets Array of ancestors from tree
 *
 * @param {Object} id - key of a element when path ends
 * @param {Array} tree - a tree. Each elem must have :children and :id key.
 * @param {Array} path - init path. This pass will be as a first elem
 *
 * @returns {Array<Object>}
 */
const pathTree = (id, tree, path = []) => {
  if (!id) {
    return path;
  }

  let result = [];

  const iterator = (sum) => (elem) => {
    if (elem.id === id) {
      result = sum.concat(elem);
      return true;
    }

    return (
      Array.isArray(elem.children) && elem.children.some(
        iterator(sum.concat(elem))
      )
    );
  };

  tree.some(iterator([]));
  return path.concat(result);
};

export { pathTree };
