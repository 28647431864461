import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import LayersIcon from '@material-ui/icons/Layers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      lineHeight: 'initial',
      marginLeft: '2px'
    }
  }
}));

const MetaInfo = (props) => {
  const { group } = props;
  const { duration_average, items_count } = group;
  const classes = useStyles();

  const renderEpisodes = () => {
    return (
      <Typography className={classes.root} component="div" color="textSecondary" variant="caption">
        <LayersIcon fontSize="small" />
        <div>
          <Trans i18nKey='mediaItems.groups.group.metaInfo.episodes' values={{itemsCount: items_count }}>
            itemsCount Episodes/Items
          </Trans>
        </div>
      </Typography>
    )
  }

  const renderDuration = () => {
    return (
      <Typography className={classes.root} component="div" color="textSecondary" variant="caption">
        <AccessTimeIcon fontSize="small" />
        <div>
          <Trans i18nKey='mediaItems.groups.group.metaInfo.duration' values={{duration: moment.duration(duration_average, 'seconds').format("hh:mm:ss", { stopTrim: "mm" }) }}>
            duration time avg.
          </Trans>
        </div>
      </Typography>
    )
  }

  return (
    <Fragment>
      { renderEpisodes() }
      { renderDuration() }
    </Fragment>
  )
}

export default MetaInfo;
