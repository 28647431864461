import React from "react";
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import findIndex from 'lodash/findIndex';

import {connect} from "react-redux";
import { filesActions } from '../../actions';

import css from './styles.module.scss';

const Crumb = (props) => {
  const { crumb, breadCrumbs, setFolders, setBreadCrumbs } = props;
  const { t } = useTranslation();

  const onClick = () => {
    const index = findIndex(breadCrumbs, { id: crumb.id });
    const newBreadCrumbs = breadCrumbs.slice(0, index + 1);

    setFolders(crumb.children);
    setBreadCrumbs(newBreadCrumbs)
  };

  return(
    <Typography
      onClick={onClick}
      variant="h6"
      color="textPrimary"
      className={css.crumb}
    >
      { crumb.name || t('profile.files.breadCrumbs.rootName') }
    </Typography>
  )
};

Crumb.propTypes = {
  crumb: PropTypes.object.isRequired, // from props
  breadCrumbs: PropTypes.array.isRequired, // from profile.files state
  setFolders: PropTypes.func.isRequired, // from filesActions
  setBreadCrumbs: PropTypes.func.isRequired // from filesActions
};

const mapStateToProps = ({ profile: { files } }) => (
  {
    breadCrumbs: files.breadCrumbs
  }
);

const mapDispatchToProps = {
  setFolders: filesActions.setFolders,
  setBreadCrumbs: filesActions.setBreadCrumbs
};

export default connect(mapStateToProps, mapDispatchToProps)(Crumb);
