import React from 'react';
import { array, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Popover from '../../../src/ui/modals/popover';

import clsx from 'clsx';

import css from './styles.module.scss';

const FieldFilter = (props) => {
  const {
    items,
    selectedItem,
    emptyTitle,
    setQueryField,
    itemTitle,
    onChange
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const openList = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clearFilter = () => {
    setQueryField(null);
    handleOnChange();
  };

  const selectItem = (kind) => () => {
    setQueryField(kind);
    handleOnChange();
  };

  const handleOnChange = () => {
    handleClose();
    onChange();
  };

  const renderItems = () => (
    items.map((field) => (
      <ListItem key={field} button onClick={selectItem(field)}>
        <ListItemText primary={itemTitle(field)} />
      </ListItem>
    ))
  );

  const renderList = () => {
    return (
      <List component="div" disablePadding className={css.list}>
        <ListItem button onClick={clearFilter}>
          <ListItemText primary={t('filter.clearFilter')} />
        </ListItem>

        <Divider />

        { renderItems() }
      </List>
    )
  };

  return(
    <div className={css.field_filter}>
      <div onClick={openList} className={css.anchor}>
        <Typography color="textPrimary" className={clsx(css.title)}>
          { selectedItem ? itemTitle(selectedItem): emptyTitle }
        </Typography>
        <ExpandMoreIcon color="primary" />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        { renderList() }
      </Popover>
    </div>
  );
};

FieldFilter.propTypes = {
  items: array.isRequired, // from props
  selectedItem: string, // from props
  emptyTitle: string.isRequired, // from props
  setQueryField: func.isRequired, // from props
  itemTitle: func.isRequired, // from props
  onChange: func.isRequired // from props
};

export default FieldFilter;
