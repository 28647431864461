import React from 'react';
import { array } from 'prop-types';

import css from './styles.module.scss';
import Tile from "../../../media-items/groups/tile";

import cssWithTheme from '../../../media-items/groups/with-theme/styles.module.scss'

const Groups = (props) => {
  const { groups } = props;

  return(
    <div className={css.tiles}>
      {
        groups.map((group) => (
          <div key={group.id} className={cssWithTheme.group}>
            <Tile
              group={group}
            />
          </div>
        ))
      }
    </div>
  )
}

Groups.propTypes = {
  groups: array
}

export default Groups;
