import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

import Humanize from 'humanize-plus';

import { tabs } from "../../../profile/constants";
import { profileUrl } from "../../../profile/utils";
import { profileActions } from "../../../profile/actions";

import css from './styles.module.scss';
import withBigdefAPI from '../../../api/context-bigdef';
import Svg from "../../../ui/svg";

import ahoy from '../../../setup_ahoy';
import actionCableConsumer from "../../../action-cable";

const MAX_BADGE_VALUE = 1000000;
const defaultProfileSlug = process.env.REACT_APP_DEFAULT_PROFILE_SLUG;

const WordsIcon = (props) => {
  const { currentUserSlug, setCurrentProfileTab, bigdefApi } = props;

  const [badgeCount, setBadgeCount] = useState(0);

  const { t } = useTranslation();

  const knownWordsChannelRef = useRef();

  const onReceived = (data) => {
    setBadgeCount(data.length)
  }

  useEffect(() => {
    if (currentUserSlug) {
      initKnownWordsChannel();
    } else {
      removeActionCableSubscriptions(knownWordsChannelRef.current);
    }

    return () => {
      removeActionCableSubscriptions(knownWordsChannelRef.current);
    };
  }, [currentUserSlug]);

  const removeActionCableSubscriptions = (subscription) => {
    if (subscription) {
      actionCableConsumer.subscriptions.remove(subscription);
    }
  }

  const initKnownWordsChannel = () => {
    knownWordsChannelRef.current = actionCableConsumer.subscriptions.create({
      channel: 'KnownWordsChannel',
      type: 'known_words'
    }, {
      received: (data) => {
        onReceived(data);
      }
    });
  };

  const fetchKnownWordsCount = () => {
    const params = { wordListId: null, fields: ['lemma'] }
    bigdefApi.dictionaryWordsWithWordList(params).then((data) => {
      setBadgeCount(data.length)
    })
  }

  useEffect(() => {
    if (currentUserSlug) {
      fetchKnownWordsCount();
    } else {
      setBadgeCount(0);
    }
  }, [currentUserSlug]);

  const humanizedBadgeCount = Humanize.compactInteger(badgeCount, 2)
  const tooltip = badgeCount >= 1000 ?
    t('header.wordsIcon.tooltips.withCount', { count: badgeCount }) :
    t('header.wordsIcon.tooltips.withoutCount');

  const renderIcon = () => {
    return (
      <IconButton size="small" onClick={onClick} className={css.badge}>
        <Tooltip title={tooltip}>
          <Badge
            classes={{root: css.root, badge: badgeCount !== 0 ? css.count : ''}}
            badgeContent={humanizedBadgeCount}
            max={MAX_BADGE_VALUE}
            showZero
            color={badgeCount === 0 ? 'error' : 'primary'}
          >
            <Svg css={css.icon} name='icSunburst' />
          </Badge>
        </Tooltip>
      </IconButton>
    )
  }

  const onClick = () => {
    ahoy().track('press_sunburst_icon');
    setCurrentProfileTab(tabs.statistic);
  }

  const url = currentUserSlug ? `${profileUrl(currentUserSlug)}` : `${profileUrl(defaultProfileSlug)}`

  return (
    <Link component={RouterLink} to={url} color="inherit" underline="none">
      { renderIcon() }
    </Link>
  )
}

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserSlug: currentUser.slug,
  }
);

const mapDispatchToProps = {
  setCurrentProfileTab: profileActions.setCurrentTab
};

export default withBigdefAPI(
  connect(mapStateToProps, mapDispatchToProps)(WordsIcon)
)
