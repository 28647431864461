import React from 'react';
import { object, node, func } from 'prop-types';
import List from '@material-ui/core/List';

import Popover from '../modals/popover';

const Menu = (props) => {
  const { anchorEl, onClose, children, ...rest } = props;

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      { ...rest }
    >
      <List dense>
        { children }
      </List>
    </Popover>
  );
};

Menu.propTypes = {
  anchorEl: object, // from props
  children: node.isRequired, // from props
  onClose: func.isRequired // from props
};

Menu.defaultProps = {
  anchorEl: null,
  disablePortal: true,

  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

export default Menu;
