import React from 'react';
import { number, object } from 'prop-types';

import { connect } from 'react-redux';

import css from "./styles.module.scss";
import { map } from "lodash";
import Carousel from './carousel';
import ThemeHeader from "../theme-header";
import { queryActions } from '../../query/actions';

const WithoutTheme = (props) => {
  const { groups, setTheme, perPage } = props;

  const handleSeeMore = (theme) => () => {
    setTheme(theme)
  }

  const renderGroup = (values, theme) => {
    if (values.length === 0) {
      return
    }

    return (
      <div className={css.group} key={theme}>
        <ThemeHeader theme={theme} />
        <Carousel perPage={perPage} groups={values} handleSeeMore={handleSeeMore(theme)} />
      </div>
    )
  }

  return (
    <div className={css.groups}>
      { map(groups, (values, theme) =>renderGroup(values, theme)) }
    </div>
  )
}

WithoutTheme.propTypes = {
  groups: object,
  perPage: number
}

const mapDispatchToProps = {
  setTheme: queryActions.setTheme,
};

export default connect(null, mapDispatchToProps)(WithoutTheme)
