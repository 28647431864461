import React, { Component, Suspense } from 'react';
import { func, bool, string, object, number } from 'prop-types';

import { Trans, withTranslation } from 'react-i18next';
import { TranslatorProvider } from 'alp-shared-components/dist/shared/providers/translator';
import AlertifyProvider from "alp-shared-components/dist/shared/alertify/provider";

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import clsx from 'clsx';

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/BrowserRouter.js
import history from '../history';
import { Router, Route, Switch } from 'react-router-dom';
import { currentUserActions } from '../current-user/actions';
import Header from '../shared/header';

import css from './styles.module.scss';

import LoadingOverlay from '../shared/loading-overlay';
import Alertify from '../shared/alertify';
import AuthenticationModal from '../shared/authenticationModal';

import FeedbackCreator from '../shared/feedback-creator';
import ScrollToTop from '../shared/scroll-to-top';

import ErrorBoundary from "../shared/error-boundary";
import RecaptchaModal from "./recaptcha-modal";

import Provider from "../shared/authentication-modal/provider";
import { Provider as IframeProvider } from '../media-item/iframe-provider'
import SubscriptionModal from '../shared/monetization/subscription-modal';
import { MonetizationProvider } from "../shared/monetization/provider";

import { alpNameSpace } from "../i18n";

import MediaItems from '../media-items';
import MediaItem from '../media-item';
import Group from '../group';
import Feedbacks from '../feedbacks';
import Feedback from '../feedback';
import Profile from '../profile';
import Terms from '../static-pages/terms';
import About from '../static-pages/about';
import ThankYou from '../static-pages/thank-you';
import Faq from '../static-pages/faq';
import Cancelled from '../shared/donation/cancelled';
import Completed from '../shared/donation/completed';
import NotFound from '../not-found';
import Posts from "../blog/posts";
import Post from "../blog/post";

const styles = (theme) => ({
  entry: {
    backgroundColor: theme.palette.background.default
  }
});

class Entry extends Component {
  static propTypes = {
    menuOpened: bool.isRequired, // From store
    menuVariant: string.isRequired, // From store
    fetchingData: bool.isRequired, // From entry store
    alertify: object.isRequired, // From store
    t: func.isRequired, // from withTranslation
    currentUserId: number // from store
  };

  constructor(props) {
    super(props);

    this.contentElement = null;

    this.state = {
      sendMessageToIframe: null,
      userDataFetched: false
    }
  }

  componentDidMount() {
    this.fetchCurrentUser()
  }

  componentDidUpdate(prevProps) {
    const { currentUserId } = this.props
    if (currentUserId && currentUserId !== prevProps.currentUserId) {
      window.gtag && window.gtag('set', {'user_id': currentUserId});
    }
  }

  fetchCurrentUser() {
    const { fetchCurrentUser } = this.props;
    fetchCurrentUser().then(() => this.setState({ userDataFetched: true }));
  }

  isMoveToRight() {
    const { menuOpened, menuVariant } = this.props;
    return menuOpened && menuVariant === 'persistent';
  }

  setSendMessageToIframe = (action) => {
    this.setState({ sendMessageToIframe: action });
  }

  render() {
    const {
      fetchingData,
      alertify,
      t,
      currentUserId,
      classes
    } = this.props;

    const { userDataFetched } = this.state;

    if (!userDataFetched) {
      return (
        <LinearProgress color="primary" />
      )
    }

    const { sendMessageToIframe } = this.state;

    const contentClassName = clsx(css.content, {[css.moveToRight]: this.isMoveToRight()});

    return (
      <TranslatorProvider ns={alpNameSpace} t={t} TransComponent={Trans}>
        <div className={clsx(css.entry, classes.entry)}>
          <Alertify
            open={alertify.open}
            text={t(alertify.text, alertify.interpolations) || alertify.text}
            type={alertify.type}
          />
          <LoadingOverlay visible={fetchingData} />

          <AlertifyProvider>
            <MonetizationProvider>
              <IframeProvider value={{ sendMessage: sendMessageToIframe, setAction: this.setSendMessageToIframe }}>
                <Provider>
                  <Router history={history}>
                    <ScrollToTop scrollableElement={this.contentElement} />
                    <Route path="/" component={Header} />

                    <ErrorBoundary>
                      <div className={contentClassName} ref={(ref) => this.contentElement = ref}>
                        <Suspense fallback={<div />}>
                          <Switch>
                            <Route path="/" exact component={MediaItems} />
                            <Route path="/media/:id" component={MediaItem} />
                            <Route path="/g/:id" component={Group} />
                            <Route path="/profiles/:id" render={(props) => <Profile key={currentUserId} {...props} />} />
                            <Route path="/feedback" exact render={(props) => (
                              <Feedbacks scrollParent={this.contentElement} {...props} />
                            )} />
                            <Route path="/feedback/:id" component={Feedback} />
                            <Route path="/blog" exact render={(props) => (
                              <Posts scrollParent={this.contentElement} {...props} />
                            )} />
                            <Route path="/blog/:id" component={Post} />

                            {/*<Route path="/words" component={WordsAuthenticated}/>*/}

                            <Route path="/donation-cancelled" component={Cancelled} />
                            <Route path="/donation-completed" component={Completed} />

                            <Route path="/terms" component={Terms} />
                            <Route path="/about" component={About} />
                            <Route path="/thank-you" component={ThankYou} />
                            <Route path="/faq" component={Faq} />

                            <Route path="*" component={NotFound} />
                          </Switch>
                        </Suspense>
                      </div>
                    </ErrorBoundary>

                    <Route path="/" component={FeedbackCreator} />
                    <RecaptchaModal />
                    <AuthenticationModal />
                    { process.env.REACT_APP_INDEX_LANDING_VISIBLE && <SubscriptionModal /> }
                  </Router>
                </Provider>
              </IframeProvider>
            </MonetizationProvider>
          </AlertifyProvider>
        </div>
      </TranslatorProvider>
    );
  }
}

const mapStateToProps = ({ menu, entry, alertify, currentUser }) => (
  {
    menuOpened: menu.opened,
    menuVariant: menu.variant,
    fetchingData: entry.fetchingData,
    currentUserId: currentUser.id,
    alertify
  }
);

const mapDispatchToProps = {
  fetchCurrentUser: currentUserActions.fetch
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Entry)));
