import React from 'react';
import { func, array } from 'prop-types';

import { useTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';

import Section from '../section';

import css from './styles.module.scss';
import SocialAccountIcon from "../social-account-icon";

const ProfileSocialAccounts = (props) => {
  const { openSettingsWithTab, profile_social_accounts } = props;
  const { t } = useTranslation();

  return (
    <Section onClick={openSettingsWithTab} tab={1} header={t('profile.info.sections.accounts')}>
        <span className={css.socialAccounts}>
          {
            profile_social_accounts.map((account) => (
              <Link key={account.kind} href={account.link} target='_blank'>
                <SocialAccountIcon kind={account.kind}/>
              </Link>
            ))
          }
        </span>
    </Section>
  )
}

ProfileSocialAccounts.propTypes = {
  openSettingsWithTab: func,
  profile_social_accounts: array
}

export default ProfileSocialAccounts;
