import React from 'react';
import { number } from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2)
  }
}));

const Connections = (props) =>  {
  const { followees, followers } = props;
  const styles = useStyles();

  const { t } = useTranslation();

  const renderPrimaryText = (title, count) => {
    return (
      <div>
        { title }
        <span className={styles.count}>{ count }</span>
      </div>
    )
  }

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <AccountCircle color="primary" fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={renderPrimaryText(t('profile.info.connections.followers'), followers)}
          primaryTypographyProps={{
            variant: 'h6',
            color: "textPrimary"
          }}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <CheckCircle color="primary" fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={renderPrimaryText(t('profile.info.connections.followees'), followees)}
          primaryTypographyProps={{
            variant: 'h6',
            color: "textPrimary"
          }}
        />
      </ListItem>
    </List>
  );

};

Connections.propTypes = {
  followees: number,
  followers: number
};

Connections.defaultProps = {
  followees: 0,
  followers: 0
};

const mapStateToProps = ({ profile }) => (
  {
    followees: profile.followees_count,
    followers: profile.followers_count
  }
);

export default connect(mapStateToProps)(Connections);
