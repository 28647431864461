const ACTION_TYPE_PREFIX = 'MONETIZATION';

const ACTION_TYPES = {
  SET_OPEN_SUBSCRIPTION_DIALOG: `${ACTION_TYPE_PREFIX}_SET_OPEN_SUBSCRIPTION_DIALOG`
};

const setOpenSubscriptionDialog = (open) => ({
  type: ACTION_TYPES.SET_OPEN_SUBSCRIPTION_DIALOG,
  payload: open
});

const monetizationActions = {
  setOpenSubscriptionDialog
};

export { ACTION_TYPES, monetizationActions };
