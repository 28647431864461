import React from "react";
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';

import css from './styles.module.scss';

import BreadCrumbs from './bread-crumbs';
import Folders from './folders';
import Items from './items';

import Empty from '../../shared/empty-content';

const Files = (props) => {
  const { folders, mediaItems, groups } = props;

  const { t } = useTranslation();

  const renderEmpty = () => {
    if (folders.length === 0 && mediaItems.length === 0 && groups.length === 0) {
      return (
        <Empty>
          <Link to="/" className={css.link}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
            >
              { t('profile.files.empty.suggestion') }
            </Fab>
          </Link>
        </Empty>
      )
    }

    return null;
  };

  return (
    <div className={css.files}>
      <BreadCrumbs />
      <Folders folders={folders} />
      <Items />

      { renderEmpty() }
    </div>
  )
};

Files.propTypes = {
  folders: PropTypes.array.isRequired, // from profile.files store
  mediaItems: PropTypes.array.isRequired // from profile.files store
};

const mapStateToProps = ({ profile: { files } }) => (
  {
    folders: files.folders,
    mediaItems: files.mediaItems,
    groups: files.groups,
  }
);

export default connect(mapStateToProps)(Files);
