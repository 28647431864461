import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Item from '../../../../../ui/menu/item';
import { profileUrl } from '../../../../../profile/utils';

const Profile = (props) => {
  const { onClick, currentUserSlug } = props;
  const { t } = useTranslation();

  return (
    <Link onClick={onClick} component={RouterLink} to={profileUrl(currentUserSlug)} underline="none">
      <Item icon={<AccountCircleIcon color="primary" />} text={t('header.userBlock.menu.items.profile')} />
    </Link>
  );
};

Profile.propsTypes = {
  onClick: func.isRequired // from props
};

const mapStateToProps = ({ currentUser }) => ({
  currentUserSlug: currentUser.slug,
});

export default connect(mapStateToProps)(Profile)
