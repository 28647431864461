import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import bannerData from './banner.json'

lottie.setQuality('low');

const defaultOptions = (container) => ({
  container,
  loop: true,
  autoplay: true,
  animationData: JSON.parse(JSON.stringify(bannerData)), // https://github.com/airbnb/lottie-web/issues/1702#issuecomment-508553873
  renderer: 'svg',

  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
});

const Animation = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation(defaultOptions(animationContainer.current));
    animation.setSubframe(false);

    return () => {
      animation.stop();
      animation.destroy();
    };
  }, []);

  return (
    <div ref={animationContainer}></div>
  )
};

export default Animation;
