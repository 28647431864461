import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import css from './styles.module.scss';

const Donation = (props) => {
  const { children } = props;
  const { t } = useTranslation();

  return(
    <div className={css.grid}>
      <div className={css.text}>{ children }</div>

      <Link to="/" className={css.link}>
        { t('donation.goBackMessage') }
      </Link>
    </div>
  )
};

export default Donation;
