import React from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { feedbackCreatorActions } from "../../../feedback-creator/actions";
import { FEEDBACK_KIND } from 'alp-shared-components/dist/feedback/constants';
import { Typography } from "../shared";

const ContactUs = (props) => {
  const { setKind } = props;
  const { t } = useTranslation();

  const openFeedback = () => setKind(FEEDBACK_KIND.BUG);

  return (
    <Typography variant="caption" color="textSecondary" onClick={openFeedback}>
      { t('footer.links.contact') }
    </Typography>
  )
};

const mapDispatchToProps = {
  setKind: feedbackCreatorActions.setKind
};

export default connect(null, mapDispatchToProps)(ContactUs);
