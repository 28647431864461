import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import last from 'lodash/last';
import { connect } from 'react-redux';

import css from './styles.module.scss';

import { filesActions } from '../actions';
import MediaItems from "./media-items";
import Groups from "./groups";

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingItems: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { currentFolder, currentUserId } = this.props;

    if ((prevProps.currentFolder.id !== currentFolder.id) ||
      (prevProps.currentFolder.media_item_ids !== currentFolder.media_item_ids) ||
      (prevProps.currentFolder.group_ids !== currentFolder.group_ids) ||
      (currentUserId !== prevProps.currentUserId)) {
        this.fetchData();
    }
  }

  fetchData() {
    const { currentFolder, fetchMediaItems, fetchGroups } = this.props;
    const mediaItemIds = currentFolder.media_item_ids || [];
    const groupIds = currentFolder.group_ids || [];

    const { loadingItems } = this.state;

    if (loadingItems) {
      return;
    }

    this.setState({ loadingItems: true })
    Promise.all([fetchMediaItems(mediaItemIds), fetchGroups(groupIds)]).then(() => {
      this.setState({ loadingItems: false });
    })
  }

  successAddMediaItemToFolder = (folder, data) => {
    this.handleSuccessActions();
  }

  successRemoveMediaItemFromFolder = (fromFolder) => {
    this.handleSuccessActions();
  }

  successCreateFolder = (name, selectedNodeId, data) => {
    this.handleSuccessActions();
  }

  handleSuccessActions = () => {
    const { currentUserId, isYourProfile, fetchData, currentFolder } = this.props;

    if (isYourProfile) {
      fetchData(currentUserId, currentFolder.id)
    }
  }

  canRemoveFromCurrentFolder() {
    const { isYourProfile, currentFolder } = this.props;

    return isYourProfile && currentFolder.owned;
  }

  render() {
    const { t, mediaItems, groups, currentFolder } = this.props;
    const { loadingItems } = this.state;

    if (loadingItems) {
      return (
        <div className={css.loader}>
          <CircularProgress size={120} />
        </div>
      )
    }

    return (
      <div className={css.media_items}>
        { currentFolder.id && (
          <Typography
            className={css.header}
            variant="h6"
            color="textPrimary"
          >
            {t('profile.files.mediaItems.title')}
          </Typography>
        )}

        <Groups
          groups={groups}
          canRemoveFromCurrentFolder={this.canRemoveFromCurrentFolder()}
          currentFolder={currentFolder}
          successCreateFolder={this.successCreateFolder}
          successAction={this.handleSuccessActions}
        />

        <MediaItems
          mediaItems={mediaItems}
          canRemoveFromCurrentFolder={this.canRemoveFromCurrentFolder()}
          currentFolder={currentFolder}
          successCreateFolder={this.successCreateFolder}
          successAction={this.handleSuccessActions}
        />
      </div>
    );
  }
};

const mapStateToProps = ({ profile, profile: { files }, currentUser }) => (
  {
    currentFolder: last(files.breadCrumbs) || {},
    mediaItems: files.mediaItems,
    groups: files.groups,
    isYourProfile: profile.id === currentUser.id,
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  fetchMediaItems: filesActions.fetchMediaItems,
  fetchGroups: filesActions.fetchGroups,
  fetchData: filesActions.fetchData
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Items));
