import React from 'react';

import { useTranslation } from "react-i18next";
import MUTypography from "@material-ui/core/Typography"

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import css from './styles.module.scss';

const socialLinks = {
  facebook: 'https://www.facebook.com/3earsRussian',
  instagram: 'https://www.instagram.com/3ears_russian'
};

const Typography = withStyles((theme) => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
    marginRight: 10,
    marginBottom: 10,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))(MUTypography);

const LinkToPage = (pageName) => {
  const { t } = useTranslation();

  return (
    <Typography variant="caption" color="textSecondary">
      <Link component={RouterLink} to={`/${pageName}`} color="inherit" underline="none">
        {t(`footer.links.${pageName}`, { projectName: process.env.REACT_APP_PROJECT_NAME })}
      </Link>
    </Typography>
  )
};

const LinkToSocial = (social) => (
  <a
    href={socialLinks[social]}
    target="_blank"
    className={css.social}
    rel="noopener noreferrer"
  >
    <div className={css.community}>
      <div className={css[social]} />
    </div>
  </a>
);


export { LinkToPage, LinkToSocial, Typography }
