import last from 'lodash/last';
import { entryActions } from '../../entry/actions';
import { alertifyActions } from '../../shared/alertify/actions';

import { pathTree } from '../../utils';

const nameSpace = 'PROFILE_FILES_';

const rootBreadCrumb = {
  id: null,
  parent_id: null
};

const actions = {
  SET_FOLDERS: nameSpace + 'SET_FOLDERS',
  ADD_BREAD_CRUMB: nameSpace + 'ADD_BREAD_CRUMB',
  SET_BREAD_CRUMBS: nameSpace + 'SET_BREAD_CRUMBS',
  SET_MEDIA_ITEMS: nameSpace + 'SET_MEDIA_ITEMS',
  SET_GROUPS: nameSpace + 'SET_GROUPS'
};

const fetchData = (userId, currentFolderId = null) => (
  (dispatch, getState, { ITL }) => {
    dispatch(entryActions.startFetchData());

    ITL.api.fetchFolders(userId).then((data) => {
      rootBreadCrumb.children = data;
      const breadCrumbs = pathTree(currentFolderId, data, [rootBreadCrumb]);

      dispatch(setBreadCrumbs(breadCrumbs));
      dispatch(setFolders(last(breadCrumbs).children));

      dispatch(entryActions.endFetchData());
    }).catch(error => {
      dispatch(entryActions.endFetchData());
    });
  }
);

const fetchMediaItems = (ids = []) => (
  (dispatch, getState, { ITL }) => {
    if (ids && ids.length > 0) {
      return (
        ITL.api.fetchMediaItems({ ids, perPage: null }).then((data) => {
          dispatch(setMediaItems(data));
        })
      )
    }

    dispatch(setMediaItems([]));
  }
);

const fetchGroups = (ids = []) => (
  (dispatch, getState, { ITL }) => {
    if (ids && ids.length > 0) {
      return (
        ITL.api.fetchGroups({ ids, perPage: null }).then((data) => {
          dispatch(setGroups(data));
        })
      )
    }

    dispatch(setGroups([]));
  }
);

const createFolder = (name, parentId = null) => (
  (dispatch, getState, { ITL }) => {
    return ITL.api.createFolder(name, parentId).then((data) => {
      const currentUserId = getState().currentUser.id;

      dispatch(fetchData(currentUserId, parentId));
      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.files.createModal.successMessage',
        interpolations: { folderName: name }
      }));

      return data;
    })
  }
);

const deleteFolder = (id, parentId = null) => (
  (dispatch, getState, { ITL }) => {
    return ITL.api.deleteFolder(id).then((data) => {
      const currentUserId = getState().currentUser.id;

      dispatch(fetchData(currentUserId, parentId));
      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.files.deleteDialog.successMessage',
        interpolations: { folderName: data.name }
      }));

      return data
    })
  }
);

const saveFolder = (id, parentId = null) => (
  (dispatch, getState, { ITL }) => {
    return ITL.api.saveFolder(id).then((data) => {
      const userId = getState().profile.id;

      dispatch(fetchData(userId, parentId));
      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.files.successSaveMessage',
        interpolations: { folderName: data.name }
      }));

      return data
    })
  }
);

const removeFolder = (id, parentId = null) => (
  (dispatch, getState, { ITL }) => {
    return ITL.api.removeFolder(id).then((data) => {
      const userId = getState().profile.id;

      dispatch(fetchData(userId, parentId));
      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.files.removeDialog.successMessage',
        interpolations: { folderName: data.name }
      }));

      return data
    })
  }
);

const updateFolder = (id, attributes, parentId = null) => (
  (dispatch, getState, { ITL }) => {
    return ITL.api.updateFolder(id, attributes).then((data) => {
      const currentUserId = getState().currentUser.id;

      dispatch(fetchData(currentUserId, parentId));
      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'profile.files.editModal.successMessage'
      }));

      return data
    })
  }
);

const setFolders = (folders) => (
  {
    type: actions.SET_FOLDERS,
    payload: folders
  }
);

const setBreadCrumbs = (breadCrumbs) => (
  {
    type: actions.SET_BREAD_CRUMBS,
    payload: breadCrumbs
  }
);

const addBreadCrumb = (folder) => (
  {
    type: actions.ADD_BREAD_CRUMB,
    payload: folder
  }
);

const setMediaItems = (mediaItems) => (
  {
    type: actions.SET_MEDIA_ITEMS,
    payload: mediaItems
  }
);

const setGroups = (groups) => (
  {
    type: actions.SET_GROUPS,
    payload: groups
  }
);

const filesActions = {
  fetchData,
  fetchMediaItems,
  fetchGroups,
  setFolders,
  setBreadCrumbs,
  addBreadCrumb,
  createFolder,
  deleteFolder,
  saveFolder,
  removeFolder,
  updateFolder,
  setMediaItems
};

export { actions, filesActions };
