import React from 'react';

import { useTranslation } from 'react-i18next';

import UISwitcher from "alp-shared-components/dist/languages/ui-switcher";
import { setDataToLocalStorage } from '../../../local-storage';
import { UI_LANGUAGE_KEYS } from '../../../local-storage/constants';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const onChangeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode, () => {
      setDataToLocalStorage(UI_LANGUAGE_KEYS.lng, languageCode)
    });
  };

  return (
    <UISwitcher languageCode={i18n.language} changeLanguage={onChangeLanguage} />
  )
};

export default LanguageSwitcher;
