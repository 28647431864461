import React from 'react';
import { func, object, string } from "prop-types";

import clsx from 'clsx';

import { matchPath } from 'react-router';

import { connect } from 'react-redux';

import { FeedbackProvider } from "alp-shared-components/dist/feedback/provider";
import Feedback from "alp-shared-components/dist/feedback";

import { feedbackCreatorActions } from './actions';

import withAPI from '../../api/context';

import css from './styles.module.scss';
import { queryActions } from '../../feedbacks/query/actions';
import { feedbacksActions } from '../../feedbacks/actions';

const apiFunctions = (api) => (
  {
    createFeedback: api.createFeedback,
    attachMediaToFeedback: api.attachMediaToFeedback
})

const clientVersion = process.env.REACT_APP_CLIENT_VERSION

const FeedbackCreator = (props) => {
  const {
    kind,
    setKind,
    currentUser,
    history,
    location,
    api,
    resetFeedbacksQuery,
    fetchFeedbacksData,
    setFeedbacksData
  } = props;

  const isMediaPage = matchPath(location.pathname, { path: '/media/:id', exact: true });

  const storeActions = {
    resetFeedbacksQuery,
    fetchFeedbacksData,
    setFeedbacksData
  }

  const isFeedbacksPage = () => {
    const { pathname } = location;

    return matchPath(pathname, {
      path: '/feedback',
      exact: true,
      strict: false
    });
  };

  return (
    <FeedbackProvider
      clientVersion={clientVersion}
      isFeedbacksPage={isFeedbacksPage()}
      api={apiFunctions(api)}
      storeActions={storeActions}
    >
      <div className={clsx(css.feedback_creator, { [css.hidden]: isMediaPage })}>
        <Feedback
          kind={kind}
          setKind={setKind}
          currentUser={currentUser}
          history={history}
        />
      </div>
    </FeedbackProvider>
  )
}

FeedbackCreator.propTypes = {
  kind: string,
  setKind: func,
  currentUser: object,
  history: object,
  location: object,
  api: object,
  resetFeedbacksQuery: func,
  fetchFeedbacksData: func,
  setFeedbacksData: func
}

const mapStateToProps = ({ currentUser, feedbackCreator }) => (
  {
    currentUser,
    kind: feedbackCreator.kind
  }
);

const mapDispatchToProps = {
  resetFeedbacksQuery: queryActions.resetQuery,
  fetchFeedbacksData: feedbacksActions.fetchData,
  setFeedbacksData: feedbacksActions.setData,
  setKind: feedbackCreatorActions.setKind
};

export default connect(mapStateToProps, mapDispatchToProps)(withAPI(FeedbackCreator));
