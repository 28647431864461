import React from 'react';
import { bool, func } from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTranslation } from 'react-i18next';

import Item from '../../../../../ui/menu/item';

const Delete = (props) => {
  const { onClick, visible } = props;
  const { t } = useTranslation();

  if (visible) {
    return (
      <Item
        onClick={onClick}
        icon={<DeleteIcon color="error" />}
        text={t('profile.files.folderActions.delete')}
      />
    )
  }

  return null;
};

Delete.propsTypes = {
  visible: bool.isRequired, // from props
  onClick: func.isRequired // from props
};

export default Delete;
