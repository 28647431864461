const actions = {
  SET_LEVELS: 'SET_LEVELS',
  ADD_LEVEL: 'ADD_LEVEL',
  REMOVE_LEVEL: 'REMOVE_LEVEL',
  SET_THEME: 'SET_THEME',
  SET_PRODUCER: 'SET_PRODUCER',
  SET_ORDER: 'SET_ORDER',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_PAGE: 'SET_PAGE',
  INCREMENT_PAGE: 'INCREMENT_PAGE',
  SET_PAYER: 'SET_PAYER',
  SET_WITH_PRICE: 'SET_WITH_PRICE',
  TOGGLE_WITH_PRICE: 'TOGGLE_WITH_PRICE',
  SET_MULTI_LANGUAGE: 'SET_MULTI_LANGUAGE',
  SET_SOURCE_LANGUAGE: 'SET_SOURCE_LANGUAGE',
  SET_TRANSLATION_LANGUAGE: 'SET_TRANSLATION_LANGUAGE',
  SET_WATCHER: 'SET_WATCHER',
  CLEAR_ALL: 'CLEAR_ALL_QUERY'
};

const setLevels = (levels) => ({
  type: actions.SET_LEVELS,
  payload: levels
});

const addLevel = (level) => ({
  type: actions.ADD_LEVEL,
  payload: level
});

const removeLevel = (level) => ({
  type: actions.REMOVE_LEVEL,
  payload: level
});

const setTheme = (theme) => ({
  type: actions.SET_THEME,
  payload: theme
});

const setProducer = (producer) => ({
  type: actions.SET_PRODUCER,
  payload: producer
});

const setWatcher = (watcher) => ({
  type: actions.SET_WATCHER,
  payload: watcher
});

const setOrder = (order) => ({
  type: actions.SET_ORDER,
  payload: order
});

const setSearchValue = (value) => ({
  type: actions.SET_SEARCH_VALUE,
  payload: value
});

const setPage = (page) => ({
  type: actions.SET_PAGE,
  payload: page
});

const incrementPage = () => ({
  type: actions.INCREMENT_PAGE
});

const setPayer = (payer) => ({
  type: actions.SET_PAYER,
  payload: payer
});

const toggleWithPrice = () => ({
  type: actions.TOGGLE_WITH_PRICE
});

const setWithPrice = (withPrice) => ({
  type: actions.SET_WITH_PRICE,
  payload: withPrice
});

const setMultiLanguage = (isMultiLanguage) => ({
  type: actions.SET_MULTI_LANGUAGE,
  payload: isMultiLanguage
});

const setSourceLanguage = (language) => ({
  type: actions.SET_SOURCE_LANGUAGE,
  payload: language
});

const setTranslationLanguage = (languageCode) => ({
  type: actions.SET_TRANSLATION_LANGUAGE,
  payload: languageCode
});

const clearAll = () => ({
  type: actions.CLEAR_ALL
});

const queryActions = {
  setLevels,
  addLevel,
  removeLevel,
  setTheme,
  setProducer,
  setPayer,
  setOrder,
  setSearchValue,
  setPage,
  incrementPage,
  toggleWithPrice,
  setWithPrice,
  setMultiLanguage,
  setSourceLanguage,
  setTranslationLanguage,
  setWatcher,
  clearAll
};

export { actions, queryActions };
