const nameSpace = 'FEEDBACKS_QUERY_';

const actions = {
  SET_PAGE: nameSpace + 'SET_PAGE',
  INCREMENT_PAGE: nameSpace + 'INCREMENT_PAGE',
  RESET_QUERY: nameSpace + 'RESET_QUERY',
  SET_ORDER: nameSpace + 'SET_ORDER',
  SET_KIND: nameSpace + 'SET_KIND',
  SET_STATE: nameSpace + 'SET_STATE'
};

const setPage = (page) => ({
  type: actions.SET_PAGE,
  payload: page
});

const incrementPage = () => ({
  type: actions.INCREMENT_PAGE
});

const resetQuery = () => ({
  type: actions.RESET_QUERY
});

const setOrder = (order) => ({
  type: actions.SET_ORDER,
  payload: order
});

const setKind = (kind) => ({
  type: actions.SET_KIND,
  payload: kind
});

const setState = (state) => ({
  type: actions.SET_STATE,
  payload: state
});

const queryActions = {
  setPage,
  incrementPage,
  resetQuery,
  setOrder,
  setKind,
  setState
};

export { actions, queryActions };
