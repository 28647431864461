import React from 'react';
import { array } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Input from './input';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { kinds } from "./constants";

import css from './styles.module.scss';

const SocialAccounts = (props) => {
  const { profileSocialAccounts } = props;
  const { t } = useTranslation();

  return (
    <div className={css.socialAccounts}>
      <Typography variant="h6" paragraph>
        { t('profile.info.settings.socialAccounts.title') }
      </Typography>

      { kinds.map((kind) => <Input key={kind} kind={kind} account={find(profileSocialAccounts, { kind: kind } )} />) }
    </div>
  )
};

const mapStateToProps = ({ profile }) => (
  {
    profileSocialAccounts: profile.profile_social_accounts
  }
);

SocialAccounts.propTypes = {
  profileSocialAccounts: array
};

SocialAccounts.defaultProps = {
  profileSocialAccounts: []
};

export default connect(mapStateToProps)(SocialAccounts);
