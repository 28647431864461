import React, { useState, useEffect, useRef } from 'react';

import { Link as RouterLink } from "react-router-dom";
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import MUCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import HoverTile from "./hover-tile";

import css from './styles.module.scss'

import { groupPath } from "../../group/utils";
import MediaTags from "../../group/media-tags";
import LazyPicture from "../../shared/lazy-picture";

const HOVERED_TIMEOUT = 350 // ms

const Card = withStyles({
  root: {
    overflow: 'visible',
    position: 'relative',
    borderRadius: 0
  }
})(MUCard);

const Tile = (props) => {
  const { group } = props;

  const [hovered, setHovered] = useState(false);

  const hoveredTimeout = useRef(null);

  useEffect(() => {
    return () => clearTimeout(hoveredTimeout.current);
  }, []);

  const onHover = () => {
    hoveredTimeout.current = setTimeout(() => {
      setHovered(true)
    }, HOVERED_TIMEOUT);
  }

  const onLeave = () => {
    clearTimeout(hoveredTimeout.current);
    setHovered(false)
  }

  return (
    <Card
      className={clsx(css.tile, { [css.hovered]: hovered })}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <Link component={RouterLink} to={groupPath(group.slug)}>
        <LazyPicture alt={group.title} webpSrc={group.webp_image_medium_url} src={group.image_medium_url} />
      </Link>
      { hovered && (
        <div className={css.hoverTileWrapper}>
          <CardContent>
            <HoverTile group={group} />
          </CardContent>
        </div>
      )}
      <div className={css.mediaTags}>
        <MediaTags group={group} />
      </div>
    </Card>
  );
}

export default Tile;
