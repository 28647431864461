import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { debounce, cloneDeep, values, sum } from "lodash";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Helmet } from "react-helmet";

import Sunburst from "alp-shared-components/dist/chart/Sunburst";

import StatisticFilter, { getLeftRange, getRightRange } from "./StatisticFilter";
import chartDataModel, { WTYPES, SELECTED_WTYPES } from "./statisticsChart.model";
import css from "./styles.module.scss";

import withBigdefAPI from "../../api/context-bigdef";
import ManageWords from './manage-words';
import withAPI from "../../api/context";
import Wtypes from "./filter/wtypes";
import withLearnerDataAPI from '../../api/context-learner-data';
import Empty from "../../shared/empty-content";

import { setDataToLocalStorage, getDataFromLocalStorage } from '../../local-storage';
import { PROFILE_STATISTIC_KEYS } from '../../local-storage/constants';
import SharedButtons from "./shared-buttons";

import { profileUrl } from '../utils';

const LANGUAGE_CODE = process.env.REACT_APP_MAIN_LANGUAGE_CODE;

const chartStyles = {
  width: "100%",
  height: "100%"
};

const mapStatistic = (statisticData) => {
  const statisticArr = Object.entries(statisticData);
  const statistic = statisticArr.reduce(
    (stat, next) => {
      const [key, val] = next;
      return stat.map((type) => {
        if (type.key !== key) {
          return type;
        }

        type.value = val;
        return type;
      });
    },
    [...chartDataModel]
  );

  return statistic;
};

const getTotalRow = (statisticData) => {
  let totalKnown = 0;
  let totalUnknown = 0;

  WTYPES.forEach((wtype) => {
    totalKnown += statisticData[wtype];
    totalUnknown += statisticData[`u${wtype}`];
  })

  const percentage = (totalKnown / (totalKnown + totalUnknown)) * 100

  return { label: 'Total', known: totalKnown, unknown: totalUnknown, percentage: Math.floor(percentage) };
}

const getWtypes = (statisticData) => (
  WTYPES.map((wtype) => {
    const known = statisticData[wtype];
    const unknown = statisticData[`u${wtype}`];
    const knownPercentage = (known / (unknown + known)) * 100

    return {
      wtype: wtype,
      known,
      unknown,
      knownPercentage: Math.floor(knownPercentage)
    }
  })
)

const shareLink = (slug) => {
  return `${process.env.REACT_APP_3EARS_PRODUCTION_URL}${profileUrl(slug)}?tab=statistic`;
}

const initialTotalRow = { label: 'total', known: 0, unknown: 0 }

const Statistics = ({ isYourProfile, profileId, profileFullName, bigdefApi }) => {
  const [totalWordsNumber, setTotalWordsNumber] = useState(-1);
  const [wtypes, setWtypes] = useState([]);
  const [totalRow, setTotalRow] = useState(initialTotalRow);
  const [emptyData, setEmptyData] = useState(false);

  const [currentNode, setCurrentNode] = useState({});

  const [leftRange, setLeftRange] = useState(getLeftRange());
  const [rightRange, setRightRange] = useState(getRightRange());

  const [selectedWtypes, setSelectedWtypes] = useState(
    getDataFromLocalStorage(PROFILE_STATISTIC_KEYS.wtypes) || SELECTED_WTYPES
  );

  const sunburstActions = useRef(null);

  const [wordListAttrs, setWordListAttrs] = useState({});

  const { t } = useTranslation();

  const loadStatistics = (left, right) => {
    const params = {
      user_id: profileId,
      q: {
        language_code_eq: LANGUAGE_CODE,
        rank_gteq: left,
        rank_lteq: right,
        wtype_in: selectedWtypes.length === 0 ? ['wtype not present'] : selectedWtypes
      }
    }

    return bigdefApi.dictionaryWordsStatistic(params).then((statisticData) => {
      const count = sum(values(statisticData));

      if (count === 0) {
        setEmptyData(true);
        setWtypes(getWtypes(statisticData));
        setTotalRow(getTotalRow(statisticData))
        return;
      }

      setEmptyData(false);

      setWtypes(getWtypes(statisticData));
      setTotalRow(getTotalRow(statisticData))

      const statistic = mapStatistic(statisticData);
      sunburstActions.current.setData(left > right ? [] : cloneDeep(statistic));
    })
  };

  const initTotalRank = () => {
    bigdefApi.dictionaryWordsStatisticTotalRank().then((totalRank) => {
      setTotalWordsNumber(totalRank);
    })
  }

  useEffect(() => {
    initTotalRank();
  }, []);

  useEffect(() => {
    if (profileId) {
      loadStatistics(leftRange, rightRange);
    }
  }, [selectedWtypes, profileId]);

  useEffect(() => {
    setDataToLocalStorage(PROFILE_STATISTIC_KEYS.leftRange, leftRange);
    setDataToLocalStorage(PROFILE_STATISTIC_KEYS.rightRange, rightRange);
    setDataToLocalStorage(PROFILE_STATISTIC_KEYS.wtypes, selectedWtypes);
  }, [leftRange, rightRange, selectedWtypes]);

  const setActions = useCallback(
    (actions) => {
      sunburstActions.current = actions;
    }, [],
  );

  const handleRangeChange = debounce((left, right) => {
    setLeftRange(left)
    setRightRange(right)

    loadStatistics(left, right);
  }, 500);

  const onSeriesClick = useCallback(
    ({ point }) => {
      const { key, name } = point;

      if (!key) {
        return;
      }

      const wType = name === 'Unknown' ? key.substring(1) : key;

      setWordListAttrs({ wType, name, key })
    }, []
  );

  const onCellClick = useCallback(
    (name, row) => {
      const key = name === 'unknown' ? `u${row.wtype}` : row.wtype;

      setWordListAttrs({ wType: row.wtype, name, key })
    }, []
  );

  const getCurrentNode = useCallback(
    (node) => {
      setCurrentNode(node || {})
    }, [],
  );

  const closeWordListsDialog = () => {
    if (isYourProfile) {
      loadStatistics(leftRange, rightRange);
    }
    setWordListAttrs({})
  }

  const renderSunburst = () => {
    if (emptyData) {
      return (
        <Empty emptyText={t('profile.statistics.empty')} className={css.empty}/>
      )
    }

    return (
      <Sunburst
        chartStyles={chartStyles}
        data={null}
        animation={true}
        setActions={setActions}
        onSeriesClick={onSeriesClick}
        getCurrentNode={getCurrentNode}
      />
    )
  }

  const renderMetaTags = () => {
    // TODO try to use user's language here
    const title = `${process.env.REACT_APP_PROJECT_NAME} - statistic`;

    const description = `Checkout these word statistics on ${process.env.REACT_APP_PROJECT_NAME} by ${profileFullName}!`

    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/profiles/${profileId}?tab=statistic`;
    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/sunburst.png`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  };

  return (
    <div className={css.statisticContainer}>
      <div className={clsx(css.chart)}>
        { renderSunburst() }
      </div>

      <SharedButtons shareLink={shareLink(profileId)} />

      <StatisticFilter
        totalWordsNumber={totalWordsNumber}
        wtypes={wtypes}
        handleRangeChange={handleRangeChange}
      />

      <Wtypes
        rows={wtypes}
        totalRow={totalRow}
        selectedWtypes={selectedWtypes}
        setSelectedWtypes={setSelectedWtypes}
        onCellClick={onCellClick}
        currentNode={currentNode}
      />

      <ManageWords
        closeWordListsDialog={closeWordListsDialog}
        wordListAttrs={wordListAttrs}
        profileId={profileId}
        isYourProfile={isYourProfile}
        languageCode={LANGUAGE_CODE}
        ranges={{ left: leftRange, right: rightRange }}
      />

      { renderMetaTags() }
    </div>
  );
};

const mapStateToProps = ({ profile, currentUser }) => ({
  profileId: profile.id,
  profileFullName: profile.full_name,
  isYourProfile: profile.id === currentUser.id
});

export default React.memo(withAPI(
  withBigdefAPI(
    withLearnerDataAPI(
      connect(mapStateToProps)(Statistics)
    )
  )
))
