import React from 'react';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

const LoadingOverlay = (props) => {
  const { visible } =  props;

  if (visible) {
    return <div className={css.loading_overlay} />
  }

  return null;
};

LoadingOverlay.propTypes = {
  visible: PropTypes.bool // from props
};

LoadingOverlay.defaultProps = {
  visible: false
};

export default LoadingOverlay;
