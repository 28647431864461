import React, { useRef, useEffect, useState } from 'react';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import css from './styles.module.scss';
import SocialAccountIcon from "../social-account-icon";

const CommonField = ({ field, isError, label, type, inputProps = {} }) => {
  const { t } = useTranslation();
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef(null);

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  return (
    <FormControl margin="dense" error={isError} fullWidth variant="outlined">
      <InputLabel className={css.fieldLabel} ref={labelRef}>{label}</InputLabel>
      <OutlinedInput
        {...field}
        type={type}
        autoComplete={type}
        labelWidth={labelWidth}
        {...inputProps}
      />
      <FormHelperText>
        <ErrorMessage name={field.name}>
          { (msg) => t(`validations.errorMessages.${msg}`) }
        </ErrorMessage>
      </FormHelperText>
    </FormControl>
  )
};

const FullNameField = ({ field, form: { touched, errors }}) => {
  const { t } = useTranslation();
  const isError = errors.full_name && touched.full_name;
  const label = t('profile.info.settings.general.info.fields.fullName');

  return (
    <CommonField
      field={field}
      isError={isError}
      label={label}
      type="text"
    />
  )
};

const SocialAccountLinkField = ({ field, form: { touched, errors }, kind, disabled }) => {
  const { t } = useTranslation();
  const isError = errors.link && touched.link;
  const label = t(`profile.info.settings.socialAccounts.kinds.${kind}.label`);

  return (
    <CommonField
      field={field}
      isError={isError}
      label={label}
      type="text"
      inputProps={
        { disabled,
          startAdornment: (
            <InputAdornment position="start">
              <SocialAccountIcon kind={kind} />
            </InputAdornment>
          )
        }
      }
    />
  )
};

const PasswordField = ({ field, form: { touched, errors }}) => {
  const { t } = useTranslation();
  const isError = errors.password && touched.password;
  const label = t('profile.info.settings.general.info.fields.password');

  return (
    <CommonField
      field={field}
      isError={isError}
      label={label}
      type="password"
    />
  )
};

export { FullNameField, SocialAccountLinkField, PasswordField }
