import React, { Fragment } from 'react';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import Avatar from "../../../ui/avatar";

import css from './styles.module.scss';

const Main = (props) =>  {
  const { avatar, fullName } = props;

  return (
    <Fragment>
      { avatar && <Avatar img={avatar.large} css={css.avatar} size="large"/> }
      <Typography variant="h5" color="textPrimary">
        {fullName}
      </Typography>
    </Fragment>
  );

};

Main.propTypes = {
  avatar: object,
  fullName: string
};

const mapStateToProps = ({ profile }) => (
  {
    avatar: profile.avatar,
    fullName: profile.full_name
  }
);

export default connect(mapStateToProps)(Main);
