import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const FREQUENCIES = ['day', 'week'];

const FrequencyButton = (props) => {
  const { frequency, onChangeFrequency } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (frequency) => () => {
    setOpen(false);
    onChangeFrequency(frequency)
  };

  return (
    <div>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
        <Button onClick={handleOpen}>
          { frequency ?
            t(`profile.info.settings.notifications.frequency.${frequency}`) :
            t(`profile.info.settings.notifications.frequency.noEmails`)
          }
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow">
              <MenuList>
                {FREQUENCIES.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === frequency}
                    onClick={handleMenuItemClick(option)}
                  >
                    { t(`profile.info.settings.notifications.frequency.${option}`) }
                  </MenuItem>
                ))}

                <MenuItem
                  selected={!frequency}
                  onClick={handleMenuItemClick(null)}
                >
                  { t(`profile.info.settings.notifications.frequency.noEmails`) }
                </MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
};

export default FrequencyButton;
