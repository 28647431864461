import React from 'react';
import { number, func } from 'prop-types';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { landingActions } from "./actions";

const CloseIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 48
  }
}))(HighlightOffIcon);

const CloseButton = (props) => {
  const { currentUserId, hideLanding } = props;

  if (currentUserId) {
    return (
      <IconButton size="small" onClick={hideLanding}>
        <CloseIcon />
      </IconButton>
    )
  }

  return null;
}

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  hideLanding: landingActions.hideLanding
};

CloseButton.propTypes = {
  currentUserId: number,
  hideLanding: func
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseButton)
