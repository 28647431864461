import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';

import MuiAlert from '@material-ui/lab/Alert';

import { alertifyActions } from './actions';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alertify = (props) => {
  const { open, type, text, dismissAlertify } = props;
  if (!text) {
    return null;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dismissAlertify();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert onClose={handleClose} severity={type}>
        { text }
      </Alert>
    </Snackbar>
  );
};

Alertify.propTypes = {
  open: PropTypes.bool, // from props
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  text: PropTypes.string, // from props
  dismissAlertify: PropTypes.func.isRequired // from alertifyActions
};

Alertify.defaultProps = {
  open: false,
  text: null
};

const mapDispatchToProps = {
  dismissAlertify: alertifyActions.dismissAlertify
};

export default connect(null, mapDispatchToProps)(Alertify);
