import React, { Fragment, useState } from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';

import { isEmptyString } from 'alp-shared-components/dist/shared/utils';
import ConfirmationDialog from 'alp-shared-components/dist/shared/modals/Dialog/ConfirmationDialog';

import Button from '@material-ui/core/Button';
import Editor from 'alp-shared-components/dist/shared/editor';

import {profileActions} from "../../../../actions";
import { FullNameField, PasswordField } from "../../fields";

import css from './styles.module.scss';

const editorToolbar = 'bold italic underline strikethrough blockquote | alignleft aligncenter alignright alignjustify | styleselect | bullist numlist | hr formats link';
const editorPlugins = 'lists hr link';

const Info = (props) => {
  const { info, updateProfile } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const { t } = useTranslation();

  const beforeSubmit = (submitForm, values) => (e) => {
    if (!isEmptyString(values.password)) {
      setOpenConfirmationDialog(true)
    } else {
      submitForm(e)
    }
  }

  const closeConfirmationDialog = () => {
    setOpenConfirmationDialog(false)
  }

  const onSubmit = (profile, actions) => {
    const params = {
      about: profile.about,
      full_name: profile.full_name
    }

    if (!isEmptyString(profile.password)) {
      params.password = profile.password
    }

    updateProfile(params).then(() => {
      if (profile.password) {
        setTimeout(() => {
          window.location.href = '/'
        }, 1000);
      }
      closeConfirmationDialog();
    }, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      closeConfirmationDialog();
    })
  };

  return (
    <div className={css.info}>
      <Formik
        initialValues={info}
        onSubmit={onSubmit}
        openConfirmationDialog={openConfirmationDialog}
        render={(attrs) => {
          const { submitForm, setFieldValue, errors, touched, values } = attrs;
          return (
            <Fragment>
              <Field component={FullNameField} name="full_name" />
              <Field component={PasswordField} name="password" />

              <span className={css.aboutPlaceholder}>{ t('profile.info.settings.general.info.fields.aboutPlaceholder') }</span>
              <Editor
                toolbar={editorToolbar}
                plugins={editorPlugins}
                initialValue={info.about}
                error={touched.about && errors.about}
                name="about"
                onChange={setFieldValue}
              />

              <Button variant="contained" color="primary" onClick={beforeSubmit(submitForm, values)}>
                { t('buttonTitles.submit') }
              </Button>

              <ConfirmationDialog
                title={t('profile.info.settings.general.confirmationDialog.title')}
                description={t('profile.info.settings.general.confirmationDialog.description')}
                open={openConfirmationDialog}
                onClose={closeConfirmationDialog}
                onSubmit={submitForm}
              />
            </Fragment>
          )
        }}
      />
    </div>
  )
};

Info.propTypes = {
  info: object,
  updateProfile: func.isRequired
};

Info.defaultProps = {
  info: {
    about: '',
    full_name: '',
    password: ''
  }
};

const mapStateToProps = ({ profile }) => (
  {
    info: {
      about: profile.about,
      full_name: profile.full_name,
      password: ''
    }
  }
);

const mapDispatchToProps = {
  updateProfile: profileActions.update
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
