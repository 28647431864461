import React, { createContext } from 'react';
import { bool, func, object } from 'prop-types';

import { connect } from 'react-redux';

import { monetizationActions } from './actions';

const { Provider: ContextProvider, Consumer } = createContext();

const Provider = (props) => {
  const {
    openSubscriptionDialog,
    setOpenSubscriptionDialog,
    monetizationSubscription,
    children
  } = props;

  const values = {
    monetizationSubscription,
    openSubscriptionDialog,
    setOpenSubscriptionDialog
  };

  return (
    <ContextProvider value={values}>
      { children }
    </ContextProvider>
  );
};

const mapStateToProps = ({ monetization, currentUser }) => ({
  openSubscriptionDialog: monetization.openSubscriptionDialog,
  monetizationSubscription: currentUser.monetization_subscription
});

const mapDispatchToProps = {
  setOpenSubscriptionDialog: monetizationActions.setOpenSubscriptionDialog
};

Provider.propTypes = {
  openSubscriptionDialog: bool,
  setOpenSubscriptionDialog: func.isRequired,
  children: object,
  monetizationSubscription: object
};

const MonetizationProvider = connect(mapStateToProps, mapDispatchToProps)(Provider);

const withMonetization = (Component) => (props) => (
  <Consumer>
    {(values) => <Component {...values} {...props} />}
  </Consumer>
);

export { MonetizationProvider, withMonetization };
