import React, { useMemo, useState, Fragment } from 'react';

import useGlobalStyles from "alp-shared-components/dist/shared/hooks/useGlobalStyles";

import { createMuiTheme, responsiveFontSizes, ThemeProvider as MUThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { DEFAULT_OBJECT } from "../ui/theme";

import ThemeModal from "./theme-modal";

const ThemeProvider = (props) => {
  const { children, theme } = props;
  useGlobalStyles({ theme });

  return (
    <MUThemeProvider theme={theme}>{ children }</MUThemeProvider>
  )
}

const DynamicTheme = (props) => {
  const { children } = props;

  const [themeObject, setThemeObject] = useState(DEFAULT_OBJECT);
  const [openThemeModal, setOpenThemeModal] = useState(false);

  const handleOpenThemeModal = () => {
    setOpenThemeModal(true);
  };

  const handleCloseThemeModal = () => {
    setOpenThemeModal(false);
  }

  const theme = useMemo(() => {
    const createdTheme = createMuiTheme(themeObject)
    return responsiveFontSizes(createdTheme);
  }, [themeObject]);

  const onChangeThemeObject = (newTheme) => {
    setThemeObject(newTheme)
  }

  return (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        disableElevation
        onClick={handleOpenThemeModal}
        color="default"
        style={{position: 'absolute', zIndex: 1300, bottom: 0}}
      >
        Open Theme Modal
      </Button>
      <ThemeProvider theme={theme}>
        <ThemeModal
          theme={themeObject}
          onChangeThemeObject={onChangeThemeObject}
          open={openThemeModal}
          onClose={handleCloseThemeModal}
        />
        { children }
      </ThemeProvider>
    </Fragment>
  );
}

export default DynamicTheme;
