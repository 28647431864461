import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import css from './styles.module.scss';

import { Link as RouterLink } from "react-router-dom";
import MetaInfo from "../../../group/meta-info";
import { groupPath } from "../../../group/utils";
import WatchAsPlaylist from "../../../group/watch-as-playlist";
import withAPI from "../../../api/context";

const useStyles = makeStyles((theme) => ({
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      }
    },
    textAlign: 'center'
  }
}));

const HoverTile = (props) => {
  const { api } = props;
  const styles = useStyles();
  const { t } = useTranslation();

  const [group, setGroup] = useState(props.group);

  const mediaItemLevels = (group.mediaitem_levels && group.mediaitem_levels.map((level) => t(`levelsList.${level}`))) || []

  useEffect(() => {
    fetchHoveredInfo()
  }, []);

  const fetchHoveredInfo = () => {
    const params = {
      fields: ['first_media_item_id', 'items_count', 'duration_average', 'last_watched_point', 'theme', 'mediaitem_levels']
    }

    api.fetchMediaItemsGroup(group.id, params).then((data) => {
      const newGroup = { ...group, ...data }
      setGroup(newGroup);
    })
  }

  const renderActions = () => {
    return (
      <div className={styles.actions}>
        <WatchAsPlaylist group={group} />
        <Button
          component={RouterLink}
          to={groupPath(group.slug)}
          size="small"
          variant="outlined"
          color="primary"
        >
          { t('mediaItems.groups.hoverTile.actions.moreInfo') }
        </Button>
      </div>
    )
  }

  return (
    <Fragment>
      <Typography gutterBottom variant="h5" component="h2">
        { group.title }
      </Typography>
      <div className={css.metaInfo}>
        <MetaInfo group={group} />
      </div>
      <Typography paragraph variant="body2" color="textPrimary" component="p">
        <div dangerouslySetInnerHTML={{__html: group.short_description}} />
      </Typography>
      <Typography paragraph variant="caption" color="textSecondary">
        { t(`themesList.${group.theme}`) } - { mediaItemLevels.join(', ') }
      </Typography>
      { renderActions() }
    </Fragment>
  )
}

export default withAPI(HoverTile);
