import React from 'react';

import { connect } from 'react-redux';

import MUAttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.mediaTag.background,
    position: 'absolute',
    top: '4px',
    left: '4px',
    borderRadius: '50%',
    display: 'flex',
    zIndex: 1
  }
}));

const AttachMoneyIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.mediaTag.color
  }
}))(MUAttachMoneyIcon);

const SubscriptionLabel = (props) => {
  const { mediaItem, monetizationSubscription } = props;

  const styles = useStyles();

  if (mediaItem.monetization_restrict_access) {
    if (monetizationSubscription) {
      return null;
    }

    return (
      <div className={styles.root}>
        <AttachMoneyIcon color="primary" fontSize="large" />
      </div>
    )
  }

  return null;
}

const mapStateToProps = ({ currentUser }) => (
  {
    monetizationSubscription: currentUser.monetization_subscription
  }
);

export default connect(mapStateToProps)(SubscriptionLabel)
