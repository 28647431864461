import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { mediaPath } from '../media-item/utils';

const WatchAsPlaylist = (props) => {
  const { group } = props;
  const { last_watched_point, first_media_item_id } = group;
  const { t } = useTranslation();

  if (last_watched_point) {
    let mediaItemPath = `${mediaPath(last_watched_point.mediaitem_id)}?time=${last_watched_point.played_time / 1000}`

    if (!group.is_paid) {
      mediaItemPath = `${mediaItemPath}&group_id=${group.slug}`
    }

    return (
      <Button component={RouterLink} to={mediaItemPath}  size="small" variant="contained" color="primary">
        { t('mediaItems.groups.seriesInfo.actions.continueWatching') }
      </Button>
    )
  }

  let mediaItemPath = mediaPath(first_media_item_id)

  if (!group.is_paid) {
    mediaItemPath = `${mediaItemPath}?group_id=${group.slug}`
  }

  return (
    <Button component={RouterLink} to={mediaItemPath}  size="small" variant="contained" color="primary">
      { t('mediaItems.groups.seriesInfo.actions.startWatching') }
    </Button>
  )
}

WatchAsPlaylist.propTypes = {
  group: object
}

export default WatchAsPlaylist;
