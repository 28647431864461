import React, { Component, createRef, Fragment } from 'react';
import { object } from 'prop-types';
import clsx from 'clsx';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import find from 'lodash/find';

import Badge from '@material-ui/core/Badge';
import { default as NotificationsIcon } from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';

import { withRouter } from 'react-router';
import withAPI from '../../../api/context';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MULink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";
import { isMobile } from "alp-shared-components/dist/shared/utils";
import DateToString from 'alp-shared-components/dist/shared/date-to-string';

import css from './styles.module.scss';

import Popover from '../../../ui/modals/popover';
import { profileActions } from "../../../profile/actions";
import { goToProfile } from "../../../profile/utils";
import { goToMedia } from "../../../media-item/utils";
import Empty from './empty';
import actionCableConsumer from '../../../action-cable';

const KEYS = {
  FOLLOW_CREATE: 'learner_data_follow.create',
  FOLLOW_DESTROY: 'learner_data_follow.destroy',
  MEDIAITEM_PUBLISH: 'mediaitem.publish'
}

const styles = (theme) => ({
  itemNotRead: {
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  }
});

const Link = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2)
  }
}))(MULink);

const mobile = isMobile();

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      notifications: [],
      fetching: false,
      unreadNotificationsCount: 0
    };

    this.notificationChannel = createRef();
  }

  componentDidMount() {
    this.fetchUnreadNotificationsCount();
    this.initNotificationChannel();
  }

  componentWillUnmount() {
    this.removeActionCableSubscriptions(this.notificationChannel)
  }

  removeActionCableSubscriptions(subscription) {
    if (subscription) {
      actionCableConsumer.subscriptions.remove(subscription);
    }
  }

  handleUnreadNotificationsCount = (count) => {
    this.setState({ unreadNotificationsCount: count })
  };

  initNotificationChannel() {
    this.notificationChannel = actionCableConsumer.subscriptions.create({
      channel: 'NotificationChannel'
    }, {
      received: (data) => {
        this.handleUnreadNotificationsCount(data)
      }
    });
  }

  fetchUnreadNotificationsCount() {
    const { api } = this.props;

    api.fetchUnreadNotificationsCount().then((count) => {
      this.setState({ unreadNotificationsCount: count })
    })
  }

  closeNotifications = () => {
    this.setState({ anchorEl: null });
  };

  openNotifications = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  fetchNotifications = () => {
    const { api } = this.props;

    api.fetchNotifications().then((notifications) => {
      this.setState({ notifications, fetching: false });
    }).catch(() => this.setState({ fetching: false }));
  };

  clearNotifications = () => {
    this.setState({ notifications: [] });
  };

  startFetching = () => {
    this.setState({ fetching: true });
  };

  isMediaitemPublishNotification(notification) {
    return notification.key === KEYS.MEDIAITEM_PUBLISH;
  };

  isFollowCreateNotification(notification) {
    return notification.key === KEYS.FOLLOW_CREATE;
  };

  clickOnItem = (notification) => () => {
    const { history } = this.props;
    const {owner, trackable} = notification;

    if (!notification.read) {
      this.makeNotificationAsRead(notification.id);
    }

    if (this.isMediaitemPublishNotification(notification) && trackable) {
      goToMedia(history, trackable.id)
    }

    if (this.isFollowCreateNotification(notification) && owner) {
      goToProfile(history, owner.slug)
    }

    this.closeNotifications();
  };

  makeNotificationAsRead(notificationId) {
    const { api } = this.props;

    api.updateNotification(notificationId, { read: true })
  };

  isClickableNotification(notification) {
    if (notification.read) {
      return false;
    }

    return (this.isMediaitemPublishNotification(notification) && Boolean(notification.trackable)) ||
      (this.isFollowCreateNotification(notification) && Boolean(notification.owner));
  };

  renderDividerItems(notification) {
    return (
      <Divider key={`divider-${notification.id}`} component="li"/>
    )
  };

  renderTrackable(notification) {
    const { t } = this.props;
    const { trackable, key } = notification;

    switch (key) {
      case KEYS.MEDIAITEM_PUBLISH:
        return (
          <div className={css.trackable}>
            { t(`header.notifications.keys.${notification.key}`) }
            <div className={css.mediaitem_publish}>
              { <Avatar className={css.avatar} variant="square" src={trackable.small_poster} /> }
              { trackable.title }
            </div>
          </div>
        )
      default:
        return t(`header.notifications.keys.${notification.key}`);
    }
  }

  renderNotification(notification) {
    const { i18n, classes } = this.props;

    return (
      <ListItem
        alignItems="flex-start"
        dense
        button={this.isClickableNotification(notification)}
        key={notification.id}
        onClick={this.clickOnItem(notification)}
        className={clsx(css.item, {[classes.itemNotRead]: !notification.read})}
      >
        <ListItemAvatar>
          <Avatar src={notification.owner && notification.owner.avatar.small}/>
        </ListItemAvatar>
        <ListItemText
          className={css.text}
          secondaryTypographyProps={{ component: 'div' }}
          primary={notification.owner_full_name}
          secondary={this.renderTrackable(notification)}
        />

        <Typography variant="caption" className={css.caption}>
          <DateToString fromNow format='' languageCode={i18n.language}>
            {notification.created_at}
          </DateToString>
        </Typography>
      </ListItem>
    )
  };

  renderNotificationsList() {
    const { notifications } = this.state;

    return (
      <List dense disablePadding>
        {
          notifications.reduce(
            (acc, notification) => (
              acc.length ?
                [...acc, this.renderDividerItems(notification), this.renderNotification(notification)] :
                [this.renderNotification(notification)]
            ), [])
        }
      </List>
    )
  };

  markAllAsRead = () => {
    const { api } = this.props;

    api.markAllNotificationsAsRead().then((newNotifications) => {
      this.setState({ notifications: newNotifications });
      this.fetchUnreadNotificationsCount()
    })
  };

  renderHeader() {
    const { t } = this.props;
    const { notifications } = this.state;

    const isAnyUnreadNotification = find(notifications, { read: false });

    return (
      <Fragment>
        <DialogTitle onClose={!isAnyUnreadNotification && this.closeNotifications}>
          { t('header.notifications.header') }

          {isAnyUnreadNotification && (
            <Link
              component="button"
              variant="body2"
              onClick={this.markAllAsRead}
            >
              { t('header.notifications.markAllAsRead') }
            </Link>
          )}
        </DialogTitle>
        <Divider />
      </Fragment>
    )
  }

  render () {
    const { unreadNotificationsCount, anchorEl, fetching, notifications } = this.state;

    if (mobile && !unreadNotificationsCount) {
      return null;
    }

    return (
      <div>
        <IconButton size="small" onClick={this.openNotifications} color="inherit">
          <Badge badgeContent={unreadNotificationsCount} color="error">
            <NotificationsIcon onClick={this.openNotifications} fontSize="large" />
          </Badge>
        </IconButton>

        <Popover
          PaperProps={{
            className: css.paper
          }}
          className={css.popover}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.closeNotifications}

          onEnter={this.startFetching}
          onEntered={this.fetchNotifications}
          onExited={this.clearNotifications}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          disablePortal={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          { this.renderHeader() }

          <div className={css.content}>
            {fetching ? <div className={css.progress}><CircularProgress size={90}/></div> : (
              notifications.length > 0 ? this.renderNotificationsList() : <Empty/>
            )}
          </div>
        </Popover>
      </div>
    );
  }
};

const mapStateToProps = ({ profile }) => (
  {
    profileEmailNotificationSetting: profile.profile_email_notification_setting
  }
);

const mapDispatchToProps = {
  updateEmailNotificationSetting: profileActions.updateEmailNotificationSetting
};

Notifications.propTypes = {
  api: object,
  history: object
};

export default withAPI(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
      withTranslation()(withStyles(styles)(Notifications))
    )
  )
);
