import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MUTypography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withAPI from '../../api/context';

const clientVersion = process.env.REACT_APP_CLIENT_VERSION

const Typography = withStyles(() => ({
  root: {
    fontSize: '10px'
  }
}))(MUTypography);

const Version = (props) => {
  const { api } = props;
  const [serverVersion, setServerVersion] = useState(null);
  const { t } = useTranslation()

  useEffect(() => {
    fetchServerVersion()
  }, []);

  const fetchServerVersion = () => {
    api.fetchCurrentServerVersion().then(setServerVersion)
  }

  return (
    <Typography color="textSecondary" variant="caption">
      { t('menu.footer.version', { clientVersion, serverVersion }) }
    </Typography>
  )
}

export default withAPI(Version);
