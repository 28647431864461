import React, { Fragment } from 'react';
import { object, bool, func, string } from 'prop-types';

import { connect } from 'react-redux';

import AuthenticationModal from 'alp-shared-components/dist/authentication';
import { ApiProvider as AuthenticationApiProvider } from 'alp-shared-components/dist/authentication/providers/api';

import { authenticationModalActions } from "./actions";
import { currentUserActions } from "../../current-user/actions";
import withIframeActions from '../../media-item/iframe-provider';

import withAuthenticationAPI from "../../api/context-authentication";

const Provider = (props) => {
  const {
    children,
    isAuthenticationModalOpen,
    closeAuthenticationModal,
    setCurrentUser,
    authenticationApi,
    sendMessageToIframe,
    reason
  } = props;

  const userLoggedSendToIframe = (user) => {
    sendMessageToIframe && sendMessageToIframe({
      type: 'login',
      payload: user
    })
  }

  const handleLogin = (user) => {
    return authenticationApi.login(user).then((data) => {
      closeAuthenticationModal();
      setCurrentUser(data);

      userLoggedSendToIframe(data)

      return data;
    });
  }

  const handleJoin = (user, reCaptchaToken) => {
    return authenticationApi.join(user, reCaptchaToken).then((data) => {
      setCurrentUser(data);

      userLoggedSendToIframe(data)

      return data;
    });
  }

  const sendResetPasswordInstructions = (user) => {
    return authenticationApi.sendResetPasswordInstructions(user).then((data) => {
      return data;
    });
  }

  const socialNetworkAuthenticate = (user, socialAccount) => {
    return authenticationApi.socialNetworkAuthenticate(user, socialAccount).then((data) => {
      closeAuthenticationModal();
      setCurrentUser(data);
      userLoggedSendToIframe(data)

      return data;
    });
  }

  return (
    <Fragment>
      { children }

      <AuthenticationApiProvider
        login={handleLogin}
        join={handleJoin}
        sendResetPasswordInstructions={sendResetPasswordInstructions}
        socialNetworkAuthenticate={socialNetworkAuthenticate}
      >
        <AuthenticationModal
          isOpen={isAuthenticationModalOpen}
          onClose={closeAuthenticationModal}
          reason={reason}
        />
      </AuthenticationApiProvider>
    </Fragment>
  )
}

Provider.propTypes = {
  children: object,
  isAuthenticationModalOpen: bool,
  closeAuthenticationModal: func,
  setCurrentUser: func,
  authenticationApi: object,
  reason: string
}

const mapStateToProps = ({ authenticationModal }) => ({
  isAuthenticationModalOpen: authenticationModal.open,
  reason: authenticationModal.reason
});

const mapDispatchToProps = {
  closeAuthenticationModal: authenticationModalActions.closeAuthenticationModal,
  setCurrentUser: currentUserActions.setData
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticationAPI(
  withIframeActions(Provider)
));
