const actions = {
  TOGGLE_MENU: 'TOGGLE_MENU',
  OPEN_MENU: 'OPEN_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
  SET_VARIANT: 'SET_VARIANT',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
};

const toggleMenu = () => ({
  type: actions.TOGGLE_MENU
});

const setVariant = (variant) => ({
  type: actions.SET_VARIANT,
  payload: variant
});

const openMenu = () => ({
  type: actions.OPEN_MENU
});

const closeMenu = () => ({
  type: actions.CLOSE_MENU
});

const setSubscriptions = (subscriptions) => ({
  type: actions.SET_SUBSCRIPTIONS,
  payload: subscriptions
});

const fetchSubscriptions = () => (
  (dispatch, getState, { ITL }) => (
    ITL.api.fetchSubscriptions().then((subscriptions) => {
      dispatch(setSubscriptions(subscriptions));
      return subscriptions;
    })
  )
);

const menuActions = {
  toggleMenu, setVariant, openMenu, closeMenu, fetchSubscriptions
};

export { actions, menuActions };
