import { actions } from './actions';

const initialState = {
  folders: [],
  mediaItems: [],
  groups: [],
  breadCrumbs: []
};

const files = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FOLDERS:
      return { ...state, folders: action.payload };
    case actions.ADD_BREAD_CRUMB:
      return { ...state, breadCrumbs: [...state.breadCrumbs, action.payload] };
    case actions.SET_BREAD_CRUMBS:
      return { ...state, breadCrumbs: action.payload };
    case actions.SET_MEDIA_ITEMS:
      return { ...state, mediaItems: action.payload };
    case actions.SET_GROUPS:
      return { ...state, groups: action.payload };
    default:
      return state;
  }
};

export default files;
