import React, { useEffect, useState, useRef } from 'react';
import { number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import MUCircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';

import MUTooltip from '@material-ui/core/Tooltip';

import actionCableConsumer from '../../action-cable';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '1rem'
  }
}))(MUTooltip);

const CircularProgress = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '50%',
    border: '1px solid #4caf50'
  },
  colorPrimary: {
    color: '#4caf50'
  }
}))(MUCircularProgress);

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    display: 'flex'
  }
}));

const KnownWordsProgress = (props) => {
  const { mediaItem, currentUserId, openWordListsDialog } = props;
  const { words_count, known_words_count } = mediaItem;

  const [knownWordsCount, setKnownWordsCount] = useState(known_words_count);

  const styles = useStyles();

  const { t } = useTranslation();

  const progressValue = Math.floor(knownWordsCount * 100 / words_count);

  let renderTip = '';

  if (currentUserId) {
    renderTip = t('mediaItems.tile.knownWordsProgress.toolTip', {
      knowWordsCount: knownWordsCount, wordsCount: words_count, progressValue
    });
  } else {
    renderTip = t('mediaItems.tile.knownWordsProgress.unsignedToolTip', { wordsCount: words_count, progressValue });
  }

  const knownWordsChannelRef = useRef();

  useEffect(() => {
    setKnownWordsCount(known_words_count)
  }, [known_words_count]);

  useEffect(() => {
    if (currentUserId) {
      initKnownWordsChannel();
    } else {
      removeActionCableSubscriptions(knownWordsChannelRef.current)
    }

    return () => {
      removeActionCableSubscriptions(knownWordsChannelRef.current)
    };
  }, [currentUserId]);

  const initKnownWordsChannel = () => {
    knownWordsChannelRef.current = actionCableConsumer.subscriptions.create({
      channel: 'KnownWordsChannel',
      type: 'mediaitem_known_words',
      mediaitem_id: mediaItem.id
    }, {
      received: (data) => {
        receiveSocketData(data);
      }
    });
  };

  const removeActionCableSubscriptions = (subscription) => {
    if (subscription) {
      actionCableConsumer.subscriptions.remove(subscription);
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    openWordListsDialog(mediaItem);
  }

  const receiveSocketData = (data) => {
    setKnownWordsCount(data.known_words_count)
  }

  return (
    <div className={styles.root} onClick={handleOnClick}>
      <Tooltip
        disableFocusListener
        arrow
        title={renderTip}
        placement="top"
        enterTouchDelay={0}
        enterDelay={0}
      >
        <CircularProgress size={32} thickness={20} variant="static" value={progressValue} />
      </Tooltip>
    </div>
  );
};

KnownWordsProgress.propTypes = {
  currentUserId: number,
  openWordListsDialog: func
}

const mapStateToProps = ({ currentUser }) => (
  { currentUserId: currentUser.id }
);

export default connect(mapStateToProps)(KnownWordsProgress);
