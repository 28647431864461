import { actions } from './actions';

const initialState = {
  kind: null
};

const feedbackCreator = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_KIND:
      return { ...state, kind: action.payload };
    default:
      return state;
  }
};

export default feedbackCreator;
