const nameSpace = 'FEEDBACK_CREATOR_';

const actions = {
  SET_KIND: nameSpace + 'SET_KIND'
};

const setKind = (kind) => ({
  type: actions.SET_KIND,
  payload: kind
});

const feedbackCreatorActions = { setKind };

export { actions, feedbackCreatorActions };
