import React, { Component } from 'react';
import { shape, string, func } from 'prop-types';
import { connect } from 'react-redux';
import Media from 'react-media';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';

import { feedbackActions } from './actions';

import css from './styles.module.scss';

import Info from './info';
import Comments from './comments';
import {menuActions} from "../shared/menu/actions";

class Feedback extends Component {
  static propTypes = {
    match: shape({ // from Router
      params: shape({
        id: string.isRequired
      }).isRequired
    }).isRequired,
    fetchFeedback: func.isRequired, // from feedbackActions
    setMenuVariant: func.isRequired, // from menuActions
  };

  componentDidMount() {
    const {
      match: { params: { id } },
      fetchFeedback
    } = this.props;

    fetchFeedback(id);
  }

  onChangeMedia = (matchMedia) => {
    const { setMenuVariant, closeMenu } = this.props;

    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  renderMetaTags() {
    const { feedback, t } = this.props;

    const title = `${process.env.REACT_APP_PROJECT_NAME} - Feedback ${feedback.id} | ${t(`feedback.kinds.${feedback.kind}`)} | ${t(`feedback.states.${feedback.state}`)}`;
    const description = feedback.preview_body;

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/feedback/${feedback.id}`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  };

  render() {
    const { feedback } = this.props;

    return (
      <Media
        query={{ minWidth: 1400 }}
        onChange={this.onChangeMedia}
      >
        {() =>
          <div className={css.feedback}>
            { this.renderMetaTags() }

            <Info feedback={feedback} />
            <Comments comments={feedback.comments} />
          </div>
        }
      </Media>
    )
  }
}

const mapStateToProps = ({ feedback }) => (
  { feedback: feedback }
);


const mapDispatchToProps = {
  fetchFeedback: feedbackActions.fetchData,
  setMenuVariant: menuActions.setVariant,
  closeMenu :menuActions.closeMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Feedback));
