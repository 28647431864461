import React, { Fragment } from 'react';
import { object, string, func } from 'prop-types';
import { SocialAccountLinkField } from "../fields";

import { connect } from 'react-redux';

import { Formik, Field } from 'formik';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { profileActions } from "../../../actions";

import css from './styles.module.scss';

const Input = (props) => {
  const { account, kind, createSocialAccount, removeSocialAccount } = props;

  const onSubmit = (newAccount, actions) => {
    newAccount.kind = kind;

    createSocialAccount(newAccount).then(null, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
    })
  };

  const remove = () => {
    removeSocialAccount(account.id);
  };

  const renderAddIcon = (submitForm) => {
    if (account.link) {
      return null;
    }

    return (
      <IconButton onClick={submitForm}>
        <AddIcon/>
      </IconButton>
    )
  };

  const renderRemoveIcon = () => {
    if (account.link) {
      return (
        <IconButton onClick={remove}>
          <DeleteIcon/>
        </IconButton>
      )
    }

    return null;
  };

  return (
    <div className={css.input}>
      <Formik
        enableReinitialize
        initialValues={account}
        onSubmit={onSubmit}
        render={( { submitForm } ) => {
          return (
            <Fragment>
              <Field component={SocialAccountLinkField} name='link' kind={kind} disabled={Boolean(account.link)} />
              { renderAddIcon(submitForm) }
            </Fragment>
          )
        }}
      />
      { renderRemoveIcon() }
    </div>
  )
};

const mapDispatchToProps = {
  createSocialAccount: profileActions.createSocialAccount,
  removeSocialAccount: profileActions.removeSocialAccount
};

Input.propTypes = {
  account: object,
  kind: string.isRequired,
  createSocialAccount: func.isRequired,
  removeSocialAccount: func.isRequired
};

Input.defaultProps = {
  account: {
    link: ''
  }
};

export default connect(null, mapDispatchToProps)(Input);
