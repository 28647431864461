import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from "react-router-dom";

import withAPI from '../../api/context';
import Tile from "../../media-items/tile";
import {queryActions} from "../../media-items/query/actions";
import css from "./styles.module.scss";
import {filesActions} from "../files/actions";
import last from "lodash/last";
import Empty from "../../shared/empty-content";

const WatchedContent = (props) => {
  const { api, isYourProfile, profile, setSourceLanguage, fetchData, currentUserId, currentFolder, setWatcher } = props;
  const { t } = useTranslation();

  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    fetchMediaItems();
  }, [profile.id]);

  const fetchMediaItems = () => {
    const params = { watcherId: profile.id, perPage: 10 }
    api.fetchMediaItems(params).then(setMediaItems)
  }

  const successAddMediaItemToFolder = (folder, data) => {
    handleSuccessActions();
  }

  const successRemoveMediaItemFromFolder = (fromFolder) => {
    handleSuccessActions();
  }

  const handleSuccessActions = () => {
    if (isYourProfile) {
      fetchData(currentUserId, currentFolder.id)
    }
  }

  const renderMediaItem = (mediaItem) => {
    return (
      <Tile
        successAddMediaItemToFolder={successAddMediaItemToFolder}
        successRemoveMediaItemFromFolder={successRemoveMediaItemFromFolder}
        key={mediaItem.id}
        mediaItem={mediaItem}
      />
    )
  }

  const seeMore = () => {
    setSourceLanguage({ name: t('sourceLanguage.all'), code: null })
    setWatcher({ id: profile.id, fullName: profile.full_name });
  }

  if (currentFolder.id) {
    return null;
  }

  const renderContent = () => {
    if (mediaItems.length === 0) {
      return (
        <Empty />
      )
    }

    return (
      <Fragment>
        <div className={css.tiles}>
          { mediaItems.map(renderMediaItem) }
        </div>

        <div className={css.seeMore}>
          <Button color="primary" component={RouterLink} to='/' underline="none" onClick={seeMore} variant="outlined">
            { t('profile.files.watchedContent.seeMore') }
          </Button>
        </div>
      </Fragment>
    )
  }

  return (
    <div className={css.watched_content}>
      <Typography
        variant="h6"
        color="textPrimary"
        className={css.header}
      >
        {t('profile.files.watchedContent.header')}
      </Typography>

      { renderContent() }
    </div>
  )
}

const mapStateToProps = ({ profile, currentUser }) => (
  {
    isYourProfile: profile.id === currentUser.id,
    profile,
    currentFolder: last(profile.files.breadCrumbs) || {},
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  setSourceLanguage: queryActions.setSourceLanguage,
  setWatcher: queryActions.setWatcher,
  fetchData: filesActions.fetchData
};

export default withAPI(connect(mapStateToProps, mapDispatchToProps)(WatchedContent));
