import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import de from './locales/de/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import it from './locales/it/translation.json';
import ko from './locales/ko/translation.json';
import pt from './locales/pt-PT/translation.json';
import ru from './locales/ru/translation.json';
import tr from './locales/tr/translation.json';
import zh from './locales/zh-CN/translation.json';
import ar from './locales/ar/translation.json';

import locales from 'alp-shared-components/dist/locales';

import { getDataFromLocalStorage } from './local-storage';
import { UI_LANGUAGE_KEYS } from './local-storage/constants';

const alpNameSpace = 'sharedComponents';

const lngStored = getDataFromLocalStorage(UI_LANGUAGE_KEYS.lng);

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: lngStored == undefined ? 'en' : lngStored,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['translation', alpNameSpace],
    defaultNS: 'translation',
    resources: {
      en: { translation: en, sharedComponents: locales.en },
      de: { translation: de, sharedComponents: locales.de },
      es: { translation: es, sharedComponents: locales.es },
      fr: { translation: fr, sharedComponents: locales.fr },
      it: { translation: it, sharedComponents: locales.it },
      ko: { translation: ko, sharedComponents: locales.ko },
      pt: { translation: pt, sharedComponents: locales.pt },
      'pt-PT': { translation: pt, sharedComponents: locales.pt  },
      ru: { translation: ru, sharedComponents: locales.ru },
      tr: { translation: tr, sharedComponents: locales.tr },
      zh: { translation: zh, sharedComponents: locales.zh },
      'zh-CN': { translation: zh, sharedComponents: locales.zh },
      ar: { translation: ar, sharedComponents: locales.ar }
    },
    parseMissingKeyHandler: (key) => '',

    react: {
      useSuspense: false
    }
  });

export { alpNameSpace }
