import React from 'react';
import { Link } from "react-router-dom";
import ReactCountdownClock from "react-countdown-clock";
import { Trans } from 'react-i18next';
import { Helmet } from "react-helmet";

import css from './styles.module.scss';
import cssVariable from '../css/variables.scss';

const NotFound = (props) => {
  const { history } = props;

  const makeRedirect = () => history.replace('/');

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - Page not found`;
    const description = "Oops, this page is not available";

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  }

  return (
    <div className={css.grid}>
      <Trans i18nKey='notFoundPage'>
        <div className={css.ears}>404</div>
        <div className={css.alerts}>Oops, this page is not available</div>
        <div className={css.description}>The link you followed may be broken, or this page</div>
        <div className={css.description}>may have been removed.</div>

        <div className={css.timer}>
          Redirecting to main page in
          <ReactCountdownClock
            seconds={5}
            size={42}
            color={cssVariable.orange}
            pausedText="0"
            onComplete={makeRedirect}
          />
          seconds
        </div>
        <Link to="/" className={css.link}>
          Go back to the homepage
        </Link>
      </Trans>

      { renderMetaTags() }
    </div>
  );
};

export default NotFound;
