import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MUTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useMediaQuery } from '@material-ui/core';

import css from '../styles.module.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'wtype', numeric: false, label: 'wtypes' },
  { id: 'known', numeric: true, label: 'knownWords' },
  { id: 'unknown', numeric: true, label: 'unknownWords' },
  { id: 'knownPercentage', numeric: true, label: 'knownPercentage' }
];

const MobileTableCell = withStyles(() => ({
  head: {
    '& span': {
      transform: 'rotate(180deg)',
      writingMode: 'vertical-rl',
      whiteSpace: 'nowrap'
    },
    padding: '6px 10px 6px 16px',
    '&:last-child': {
      paddingLeft: '4px'
    }
  }
}))(MUTableCell);

const TableCell = withStyles(() => ({
  root: {
    padding: '6px 24px 6px 0px',
    '&:last-child': {
      paddingRight: '12px'
    }
  }
}))(MUTableCell);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, totalRow, onRequestSort, currentNode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const renderTotalRow = () => {
    return (
      <TableRow
        hover
        tabIndex={-1}
      >
        <TableCell padding="checkbox">

        </TableCell>
        <TableCell component="th" scope="row">
          { t('profile.statistics.filter.totalCell') }
        </TableCell>
        <TableCell align="right">
          { totalRow.known }
        </TableCell>
        <TableCell align="right">
          { totalRow.unknown }
        </TableCell>
        <TableCell align="right">
          {isNaN(totalRow.percentage) ? '-' : `${totalRow.percentage}%`}
        </TableCell>
      </TableRow>
    );
  }

  const TableCellComponent = isMobileScreen ? MobileTableCell : TableCell

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={Boolean(currentNode.wtype)}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCellComponent
            key={headCell.id}
            align={isMobileScreen ? 'left' : headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(`profile.statistics.filter.headCells.${headCell.label}`)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCellComponent>
        ))}
      </TableRow>

      { renderTotalRow() }
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Wtypes = (props) => {
  const { rows, totalRow, selectedWtypes, setSelectedWtypes, onCellClick, currentNode } = props;
  const classes = useStyles();

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('known');

  const { t } = useTranslation();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.wtype);
      setSelectedWtypes(newSelecteds);
      return;
    }
    setSelectedWtypes([]);
  };

  const handleClick = (name) => () => {
    if (currentNode.wtype) {
      return;
    }

    const selectedIndex = selectedWtypes.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedWtypes, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedWtypes.slice(1));
    } else if (selectedIndex === selectedWtypes.length - 1) {
      newSelected = newSelected.concat(selectedWtypes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedWtypes.slice(0, selectedIndex),
        selectedWtypes.slice(selectedIndex + 1),
      );
    }

    setSelectedWtypes(newSelected);
  };

  const isSelected = (wtype) => selectedWtypes.indexOf(wtype) !== -1;

  const handleCellClick = (name, row) => (e) => {
    e.stopPropagation();
    onCellClick(name, row)
  }

  return (
    <div className={clsx(classes.root, css.wtypes)}>
      <TableContainer>
        <Table
          size='small'
          padding="normal"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selectedWtypes.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            currentNode={currentNode}
            totalRow={totalRow}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = isSelected(row.wtype);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    className={clsx({ [css.currentNode]: row.wtype === currentNode.wtype })}
                    hover
                    onClick={handleClick(row.wtype)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.wtype}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={Boolean(currentNode.wtype)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {t(`wtypes.${row.wtype}`)}
                    </TableCell>
                    <TableCell align="right">
                      { row.known > 0 ? <span className={css.clickableCell} onClick={handleCellClick('known', row)}>{row.known}</span> : row.known}
                    </TableCell>
                    <TableCell align="right">
                      { row.unknown > 0 ? <span className={css.clickableCell} onClick={handleCellClick('unknown', row)}>{row.unknown}</span> : row.unknown}
                    </TableCell>
                    <TableCell align="right">
                      {isNaN(row.knownPercentage) ? '-' : `${row.knownPercentage}%`}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Wtypes;
