import { createMuiTheme } from '@material-ui/core/styles';

const DEFAULT_OBJECT = {
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fff",
      "default": "#fafafa",
      "landing": "#a39168",
      "leftMenu": "#fff",
      "header": "#3578D0",
      "modal": "#fff"
    },
    "primary": {
      "light": "#82bcffff",
      "main": "#2077d0ff",
      "dark": "#155aa7ff",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#82bcffff",
      "main": "#2077d0ff",
      "dark": "#155aa7ff",
      "contrastText": "#fff"
    },
    "error": {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "warning": {
      "light": "#ffb74d",
      "main": "#ff9800",
      "dark": "#f57c00",
      "contrastText": "rgba(0,0,0,0.87)"
    },
    "info": {
      "light": "#64b5f6",
      "main": "#2196f3",
      "dark": "#1976d2",
      "contrastText": "#fff"
    },
    "success": {
      "light": "#81c784",
      "main": "#4caf50",
      "dark": "#388e3c",
      "contrastText": "rgba(0, 0, 0, 0.87)"
    },
    "grey": {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#eeeeee",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      "A100": "#d5d5d5",
      "A200": "#aaaaaa",
      "A400": "#303030",
      "A700": "#616161"
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)"
    },
    "divider": "rgba(0, 0, 0, 0.12)",
    "action": {
      "active": "rgba(0, 0, 0, 0.54)",
      "hover": "rgba(0, 0, 0, 0.04)",
      "hoverOpacity": 0.04,
      "selected": "rgba(0, 0, 0, 0.08)",
      "selectedOpacity": 0.08,
      "disabled": "rgba(0, 0, 0, 0.26)",
      "disabledBackground": "rgba(0, 0, 0, 0.12)",
      "disabledOpacity": 0.38,
      "focus": "rgba(0, 0, 0, 0.12)",
      "focusOpacity": 0.12,
      "activatedOpacity": 0.12
    },
    "mediaTag": {
      "background": "#1B42A9",
      "color": "#fff"
    }
  },
  "boxShadow": {
    "wordList": "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)"
  },
  "flashcards": {
    "borderColor": "rgba(0, 0, 0, 0)"
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1rem"
      }
    }
  },
  props: {
    MuiTooltip: {
      placement: 'top'
    }
  }
}

export { DEFAULT_OBJECT }

export default createMuiTheme(DEFAULT_OBJECT);
