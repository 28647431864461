import React from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { profileActions } from '../../../actions';

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import css from './styles.module.scss';
import FrequencyButton from "./frequency-button";

const SETTINGS = ['stream', 'mediaitem_published', 'follower_appeared'];

const Notifications = (props) => {
  const { profileEmailNotificationSetting, updateEmailNotificationSetting } = props;
  const { t } = useTranslation();
  const { frequency } = profileEmailNotificationSetting;

  const onChangeSetting = (setting) => (event) => {
    updateEmailNotificationSetting({ [setting]: event.target.checked });
  };

  const renderSettingCheckbox = (setting) =>
    <FormControlLabel
      className={css.checkbox}
      key={setting}
      control={
        <Checkbox
          checked={profileEmailNotificationSetting[setting]}
          onChange={onChangeSetting(setting)}
          color="primary"
        />
      }
      label={t(`profile.info.settings.notifications.email.${setting}`)}
    />;

  const onChangeFrequency = (frequency) => {
    updateEmailNotificationSetting({ frequency })
  };

  return (
    <div className={css.notifications}>
      <div className={css.block}>
        <Typography variant="h6" paragraph>
          { t('profile.info.settings.notifications.email.title') }
        </Typography>

        { SETTINGS.map((setting) => (renderSettingCheckbox(setting))) }
      </div>

      <div className={css.block}>
        <Typography variant="h6" paragraph>
          { t('profile.info.settings.notifications.frequency.title') }
        </Typography>

        <FrequencyButton frequency={frequency} onChangeFrequency={onChangeFrequency} />
      </div>
    </div>
  )
};

const mapStateToProps = ({ profile }) => (
  {
    profileEmailNotificationSetting: profile.profile_email_notification_setting
  }
);

const mapDispatchToProps = {
  updateEmailNotificationSetting: profileActions.updateEmailNotificationSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
