import React from 'react';

import css from './styles.module.scss';

import Folder from './folder';

const Folders = (props) => {
  const { folders } = props;

  const renderFolder = (folder) => <Folder key={folder.id} folder={folder} />;

  return (
    <div className={css.folders}>
      { folders.map((folder) => renderFolder(folder)) }
    </div>
  );
};

export default Folders;
