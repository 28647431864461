import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.scss';
import cssBox from '../../css/box.module.scss';

const Avatar = (props) => {

  let {css, size, img, dataSrc, ...rest} = props;

  const className = clsx(cssBox.circle, styles[size], css);

  return dataSrc ?
    <img className={className} data-src={dataSrc} alt=""{ ...rest } /> :
    <img className={className} src={img} alt=""{ ...rest } />
};

Avatar.propTypes = {
  img: PropTypes.string, // from props
  dataSrc: PropTypes.string, // from props
  css: PropTypes.string, // from props
  size: PropTypes.string // from props
};

Avatar.defaultProps = {
  css: null,
  size: 'small'
};

export default Avatar;
