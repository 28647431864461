import React, { useState, useEffect, useRef } from 'react';
import { object, array, number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tile from '../tile';
import { InView } from 'react-intersection-observer';

import css from './styles.module.scss';
import withAPI from "../../../api/context";
import { cloneDeep } from "lodash";
import ThemeHeader from "../theme-header";

const WithTheme = (props) => {
  const { withThemeGroups, queryParams, api, theme, perPage } = props;

  const page = useRef(1);

  const [groups, setGroups] = useState(withThemeGroups);
  const [fetching, setFetching] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setGroups(withThemeGroups);
    setHasMoreItems(withThemeGroups.length >= perPage);
    page.current = 1;
  }, [withThemeGroups]);

  const fetchGroups = () => {
    setFetching(true)
    const clonedParams = cloneDeep(queryParams);

    clonedParams.theme = theme;
    clonedParams.page = page.current;
    clonedParams.perPage = perPage;

    api.fetchGroups(clonedParams).then((newGroups) => {
      setHasMoreItems(newGroups.length >= perPage)
      setGroups([...groups, ...newGroups]);
      setFetching(false);
    });
  }

  const handleProgressVisibilityChange = (inView) => {
    if (inView && !fetching) {
      page.current = page.current + 1;
      fetchGroups()
    }
  };


  return (
    <div className={css.withTheme}>
      <ThemeHeader theme={theme} />
      <div className={css.groups}>
        { groups.map((group) =>
          <div key={group.id} className={css.group}>
            <Tile group={group} />
          </div>
        )}
      </div>

      { !fetching && hasMoreItems && (
        <InView
          key="spinner-0"
          as="div"
          className={css.loadingIndicatorWrapper}
          onChange={handleProgressVisibilityChange}
        >
          <span className={css.loader}>{t('mediaItems.loading')}</span>
        </InView>
      )}
    </div>
  )
}

WithTheme.propTypes = {
  withThemeGroups: array,
  queryParams: object,
  api: object,
  perPage: number,
  theme: string
}

export default withAPI(WithTheme);
