const actions = {
  OPEN_AUTHENTICATION_MODAL: 'OPEN_AUTHENTICATION_MODAL',
  CLOSE_AUTHENTICATION_MODAL: 'CLOSE_AUTHENTICATION_MODAL'
};

const openAuthenticationModal = (reason = null) => ({
  type: actions.OPEN_AUTHENTICATION_MODAL,
  payload: reason
});

const closeAuthenticationModal = () => ({
  type: actions.CLOSE_AUTHENTICATION_MODAL
});

const authenticationModalActions = {
  openAuthenticationModal,
  closeAuthenticationModal
};

export { actions, authenticationModalActions };
