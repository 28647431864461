import React from 'react';
import { string, node } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import css from './styles.module.scss';

const MenuItem = (props) => {
  const { iconName, text, iconCss, icon, ...otherProps } = props;

  return (
    <ListItem button className={css.item} {...otherProps}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
};

MenuItem.propTypes = {
  iconName: string, // from props
  text: string.isRequired, // from props
  iconCss: string, // from props
  icon: node // from props
};

MenuItem.defaultProps = {
  iconCss: null,
  icon: null
};

export default MenuItem;
