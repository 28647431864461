import React, { useState, Fragment } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";
import Modal from '../../../../ui/modals/modal';
import css from '../styles.module.scss';
import { Typography } from '../shared';

const SRC = process.env.REACT_APP_HOW_IT_WORKS_VIDEO_SRC;

const HowItWorksContent = (props) => {
  const { opened, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Modal open={opened} onClose={handleClose} maxWidth="md" className={css.howItWorks} fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('footer.howItWorks.header')}
      </DialogTitle>

      <video autoPlay="autoplay" controls="controls">
        <source src={SRC} type="video/mp4" />
      </video>

    </Modal>
  )
};

HowItWorksContent.propTypes = {
  opened: bool.isRequired,
  handleClose: func.isRequired
};

const HowItWorks = () => {
  const { t } = useTranslation();

  const [howItWorksOpened, setHowItWorksOpened] = useState(false);

  const openHowItWorks = () => setHowItWorksOpened(true);
  const closeHowItWorks = () => setHowItWorksOpened(false);

  return (
    <Fragment>
      <Typography variant="caption" color="textSecondary" onClick={openHowItWorks}>
        { t('footer.links.howItWorks') }
      </Typography>

      <HowItWorksContent opened={howItWorksOpened} handleClose={closeHowItWorks} />
    </Fragment>
  )
};

export default HowItWorks;
