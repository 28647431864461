import { actions } from './actions';

const initialState = {
  open: false,
  text: null,
  type: 'success',
  interpolations: {}
};

const alertify = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_ALERTIFY:
      return {
        open: true,
        type: action.payload.type,
        text: action.payload.text,
        interpolations: action.payload.interpolations
      };
    case actions.DISMISS_ALERTIFY:
      return { open: false, type: '', text: null, interpolations: {} };
    default:
      return state;
  }
};

export default alertify;
