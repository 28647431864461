import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import WordList from "alp-shared-components/dist/words/word-list";
import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";
import { ListFunctionsProvider } from 'alp-shared-components/dist/words/word-list/providers/list-functions/provider';

import settingsCss from "../../info/settings/styles.module.scss";
import css from './styles.module.scss';

import Slide from "@material-ui/core/Slide";
import withLearnerDataApi from '../../../api/context-learner-data';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WordLists = (props) => {
  const {
    open,
    onClose,
    queryParams,
    frequentDictionaryWordsStatistic,
    openBigdef,
    wordListsKey,
    learnerDataApi,
    setListActions
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      disableEnforceFocus
      className={clsx(settingsCss.settings, css.wordLists)}
      classes={{paper: css.root}}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle onClose={onClose}>
        { t(`wordList.keys.${wordListsKey}`) }
      </DialogTitle>

      <Divider />

      <ListFunctionsProvider
        openBigdef={openBigdef}
        fetchAdditionalItemInfo={learnerDataApi.fetchAdditionalInfoUserWord}
      >
        <WordList
          setListActions={setListActions}
          getData={frequentDictionaryWordsStatistic}
          queryParams={queryParams}
          className={css.content}
          useInfiniteLoader={true}
        />
      </ListFunctionsProvider>

    </Dialog>
  )
}

export default withLearnerDataApi(WordLists)
