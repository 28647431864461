import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { Helmet } from "react-helmet";

import DateToString from 'alp-shared-components/dist/shared/date-to-string';

import MainFeaturedPost from './main-featured-post';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router';

import withAPI from '../../api/context';

import Main from './main';
import Empty from "../../shared/empty-content";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  }
}));

const Post = (props) => {
  const { api } = props;

  const [post, setPost] = useState(null);

  const classes = useStyles();

  const { i18n } = useTranslation();

  useEffect(() => {
    fetchPost()
  }, []);

  const fetchPost = () => {
    const { match: { params: { id } } } = props;

    api.fetchBlogPost(id).then((data) => {
      setPost(data);
    })
  }

  const renderTitle = () => {
    return (
      <Fragment>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Avatar src={post.user.avatar.small} />
          <span style={{marginLeft: 10}}>
            { post.user.full_name }
          </span>
        </div>
        <DateToString format="MMMM DD, YYYY" languageCode={i18n.language}>
          { post.created_at }
        </DateToString>
      </Fragment>
    )
  }

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - Blog | ${post.title}`
    const description = `${process.env.REACT_APP_PROJECT_NAME} - Blog | ${post.description}`;
    const image = post.image_large_url || `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/blog/${post.slug}`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  if(!post) {
    return <Empty />;
  }

  return (
    <React.Fragment>
      { renderMetaTags() }
      <Container maxWidth="lg">
        <main>
          <MainFeaturedPost post={post} />
          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title={renderTitle()} post={post} />
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}

export default withAPI(
  withRouter(Post)
);
