import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withAPI from '../api/context';

import ReCaptcha from 'alp-shared-components/dist/shared/recaptcha';

const URL = process.env.REACT_APP_3EARS_PRODUCTION_URL;

const RecaptchaModal = (props) => {
  const { api } = props;
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const handleReCaptchaToken = (token) => {
    if (token) {
      api.trackEvent('confirmed_staging_visit');
    }

    setReCaptchaToken(token);
  };

  return(
    <Dialog
      open={!reCaptchaToken}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        Are you sure to use the test version of 3ears?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            THIS IS A TEST VERSION OF 3EARS, THERE MAY BE MORE BUGS THAN USUAL. FOR THE PRODUCTION 3EARS, <a href={URL}>GO HERE</a>
          </div>

          <ReCaptcha
            action="staging_access"
            token={reCaptchaToken}
            handleToken={handleReCaptchaToken}
          />

          <span>Check this checkbox if you want to continue using staging</span>

        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
};

export default withAPI(RecaptchaModal);
