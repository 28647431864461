import { actions } from './actions';

export const initialState = {
  comments: []
};

const feedback = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, ...action.payload };
    case actions.ADD_COMMENT:
      return { ...state, comments: [...state.comments, action.payload] };
    default:
      return state;
  }
};

export default feedback;
