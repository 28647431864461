import React from 'react';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import VanillaPicker from 'vanilla-picker';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import Modal from '../ui/modals/modal';

const ThemeModal = (props) => {
  const { theme, open, onClose, onChangeThemeObject } = props;

  const onColorPicker = (parent, color, onChange) => {
    new VanillaPicker({
      parent: parent,
      color: color,
      onDone: function (color) {
        onChange(color.hex)
      }
    }).show();
  }

  return (
    <Modal open={open} onClose={onClose} PaperProps={{style: { height: '100%' }}}>
      <DialogTitle onClose={onClose}>
        Dynamically change theme
      </DialogTitle>
      <Editor
        value={theme}
        onChange={onChangeThemeObject}
        onColorPicker={onColorPicker}
        allowedModes={['tree', 'text']}
        htmlElementProps={
          { style: {height: '100%', overflow: 'auto'} }
        }
      />
    </Modal>
  )
}

export default ThemeModal;
