import React, { Fragment } from 'react';

import Avatar from "./avatar";
import css from './styles.module.scss'
import Info from "./info";

const General = () => {
  return (
    <Fragment>
      <div className={css.avatar}>
        <Avatar />
      </div>

      <Info />
    </Fragment>
  )
};

export default General;
