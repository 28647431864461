import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import css from "./styles.module.scss";
import { getDataFromLocalStorage } from "../../local-storage";
import { PROFILE_STATISTIC_KEYS } from "../../local-storage/constants";

const MIN_LEFT_VALUE = 1;
const MAX_RIGHT_VALUE = 100;

const getLeftRange = () => {
  return getDataFromLocalStorage(PROFILE_STATISTIC_KEYS.leftRange) || MIN_LEFT_VALUE;
}

const getRightRange = () => {
  return getDataFromLocalStorage(PROFILE_STATISTIC_KEYS.rightRange) || MAX_RIGHT_VALUE;
}

function StatisticFilter({
  totalWordsNumber,
  handleRangeChange
}) {
  const [range, setRange] = useState([getLeftRange(), getRightRange()]);
  const [leftValue, rightValue] = range;

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    const [newLeft, newRight] = newValue;
    if (newLeft >= 0 && newRight >= 0) {
      handleRangeChange(newLeft, newRight);
    }

    setRange(newValue);
  };

  return (
    <div className={css.filter}>
      <h2 className={css.filterTitle}>
        { t('profile.statistics.filter.wordsFilter', { leftValue, rightValue }) }
      </h2>
      <div className={css.filterControls}>
        <TextField
          type="number"
          value={leftValue}
          onChange={(e) =>
            handleChange(e, [parseInt(e.target.value), rightValue])
          }
          inputProps={{style: { width: 100 }}}
        />

        <Slider
          value={range}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          min={MIN_LEFT_VALUE}
          max={totalWordsNumber}
        />

        <TextField
          type="number"
          value={rightValue}
          onChange={(e) =>
            handleChange(e, [leftValue, parseInt(e.target.value)])
          }
          inputProps={{style: { textAlign: 'right', width: 100 }}}
        />
      </div>
    </div>
  );
}

export default StatisticFilter;

export { MIN_LEFT_VALUE, MAX_RIGHT_VALUE, getLeftRange, getRightRange };
