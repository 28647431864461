import React, { Component } from "react";
import { func, string, object } from 'prop-types';

import { connect } from 'react-redux';

import { withRouter } from 'react-router';

import { withTranslation } from 'react-i18next';

import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { queryActions } from '../../../media-items/query/actions';

import css from './styles.module.scss';
import cssBox from '../../../css/box.module.scss';
import cssText from '../../../css/text.module.scss';
import cssVariables from '../../../css/variables.scss';

class SearchBox extends Component {
  static propTypes = {
    closeSearchBox: func.isRequired, // from props
    setSearchValue: func.isRequired, // from queryActions
    searchValue: string.isRequired, // from mediaItems.query
    history: object.isRequired, // from withRouter
    t: func.isRequired // from withTranslation
  };

  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidMount() {
    this.input.current.focus();
  }

  onSearch = () => {
    const { history, setSearchValue } = this.props;
    history.replace('/');
    setSearchValue(this.input.current.value)
  };

  onInputKeyDown = ({ key }) => {
    switch (key) {
      case 'Enter':
        return this.onSearch();
      case 'Escape':
        return this.onCloseSearchBox();
      default:
        break;
    }
  };

  onCloseSearchBox = () => {
    const { closeSearchBox } = this.props;
    closeSearchBox();
  };

  render() {
    const { searchValue, t } = this.props;

    return (
      <div className={css.search_box}>
        <div className={css.search_input_wrapper}>
          <div className={clsx(css.search_icon, cssBox.circle)} onClick={this.onSearch}>
            <SearchIcon style={{ color: cssVariables.white_white }} />
          </div>

          <input
            ref={this.input}
            tabIndex="0"
            type="text"
            defaultValue={searchValue}
            className={clsx(css.input, cssText.roboto_medium, cssText.paragraph_2, cssText.cl_white_white)}
            placeholder={t('header.searchContent')}
            // onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
          />
        </div>

        <div className={clsx(css.clear_icon, cssBox.circle)} onClick={this.onCloseSearchBox}>
          <ClearIcon style={{ color: cssVariables.white_white }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ mediaItems: { query } }) => (
  {
    searchValue: query.searchValue
  }
);

const mapDispatchToProps = {
  setSearchValue: queryActions.setSearchValue
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchBox))
);
