import React, { Fragment } from 'react';
import { string } from 'prop-types';

import { useTranslation } from 'react-i18next';

import DateToString from 'alp-shared-components/dist/shared/date-to-string';

const CreatedTime = (props) => {
  const { createdAt } = props;
  const { i18n } = useTranslation();

  return (
    <Fragment>
      <DateToString fromNow format='' languageCode={i18n.language}>
        { createdAt }
      </DateToString> (
      <DateToString languageCode={i18n.language}>
        { createdAt }
      </DateToString>)
    </Fragment>
  )
};

CreatedTime.propTypes = {
  createdAt: string // from props
};

export default CreatedTime;
