import { actions } from './actions';

const initialState = {
  page: 1,
  perPage: 10,
  order: 'created_at desc', // created_at desc - Most Recent
  kind: null,
  state: null
};

const query = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PAGE:
      return { ...state, page: action.payload };
    case actions.INCREMENT_PAGE:
      return { ...state, page: state.page + 1 };
    case actions.SET_ORDER:
      return { ...state, order: action.payload };
    case actions.SET_KIND:
      return { ...state, kind: action.payload };
    case actions.SET_STATE:
      return { ...state, state: action.payload };
    case actions.RESET_QUERY:
      return initialState;
    default:
      return state;
  }
};

export default query;
