import React from 'react';
import css from './styles.module.scss';

const NOTIFY_URL = `${process.env.REACT_APP_ITL_URL}/paypal/donation_notification`;

const PayPalButton = () => {

  return(
    <form
      action="https://www.paypal.com/cgi-bin/webscr"
      method="post"
      target="_top"
      className={css.pay_pal_donation_button}
    >
      <input type="hidden" name="cmd" defaultValue="_s-xclick" />
      <input type="hidden" name="notify_url" defaultValue={NOTIFY_URL} />
      <input
        type="hidden"
        name="hosted_button_id"
        defaultValue="Q7234H3NAH4G4"
      />
      <input
        type="image"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
        border={0}
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
      <img
        alt=""
        border={0}
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width={1}
        height={1}
      />
    </form>
  )
};

export default PayPalButton;
