import query from './query/reducer';
import { actions } from './actions';

const initialState = {
  data: [],
  hasMore: false
};

const feedbacks = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, data: action.payload };
    case actions.ADD_DATA:
      return { ...state, data: [...state.data, ...action.payload] };
    case actions.SET_HAS_MORE:
      return { ...state, hasMore: action.payload };
    default:
      return {
        ...state,
        query: query(state.query, action)
      };
  }
};

export default feedbacks;
