const mediaPath = (id) => `/media/${id}`;
const mediaUrl = (id) => `${window.location.origin}${mediaPath(id)}`;
const embedCode = (item) => {
  let src;

  if (item.group_id) {
    src = `${process.env.REACT_APP_ITL_URL}/mediaitems/${item.id}/embed?group_id=${item.group_id}`;
  } else {
    src = `${process.env.REACT_APP_ITL_URL}/mediaitems/${item.id}/embed`;
  }

  return (
    `<iframe src="${src}" width="100%" height="100%" frameBorder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`
  )
};

const goToMedia = (history, id) => history.push(mediaPath(id));

export { goToMedia, mediaUrl, mediaPath, embedCode };
