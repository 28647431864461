import React, { useState } from 'react';
import { object, number } from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { default as UILink } from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';

import clsx from 'clsx';

import { Link } from "react-router-dom";

import FoldersTree from "alp-shared-components/dist/folders-tree";
import ShareActions from "alp-shared-components/dist/item/share-actions";
import { TYPES } from "alp-shared-components/dist/item/share-actions/constants";
import { isMobile } from "alp-shared-components/dist/shared/utils";

import css from './styles.module.scss';
import cssText from '../../css/text.module.scss';
import Avatar from "../../ui/avatar";

import { profileUrl } from "../../profile/utils";
import { mediaPath } from '../../media-item/utils';
import withLearnerDataAPI from "../../api/context-learner-data";
import withApi from "../../api/context";
import { alertifyActions } from "../../shared/alertify/actions";

import KnownWordsProgress from './know-words-progress';
import ahoy from '../../setup_ahoy';
import SubscriptionLabel from "./subscription-label";
import LazyPicture from "../../shared/lazy-picture";

const mobile = isMobile();

const Tile = (props) => {
  const {
    mediaItem,
    currentUserId,
    api,
    openAlertify,
    successCreateFolder,
    successAddMediaItemToFolder,
    successRemoveMediaItemFromFolder,
    currentFolder,
    canRemoveFromCurrentFolder,
    openWordListsDialog
  } = props;

  const { producer } = mediaItem;

  const { t } = useTranslation();

  const [copiedMediaItem, setCopiedMediaItem] = useState(null);

  const link = mediaPath(mediaItem.id);

  const openFoldersTree = () => {
    setCopiedMediaItem(mediaItem);
  };

  const closeFoldersTree = () => {
    setCopiedMediaItem(null);
  };

  const renderPrice = () => {
    const price = mediaItem.group_format_price || mediaItem.format_price;

    if (price) {
      return (
        <Typography variant="h6" className={clsx(css.paidContent, { [css.paid]: mediaItem.is_paid })}>
          { price }
        </Typography>
      )
    }

    return null;
  };

  const addMediaItemToFolder = (successMessage, folder, data) => {
    openAlertify({ type: 'success', text: successMessage });
    successAddMediaItemToFolder && successAddMediaItemToFolder(folder, data);
  }

  const handleSuccessRemoveMediaItemFromFolder = (fromFolder) => {
    successRemoveMediaItemFromFolder && successRemoveMediaItemFromFolder(fromFolder);
  }

  const createFolder = (name, selectedNodeId, fields) => {
    return api.createFolder(name, selectedNodeId, fields).then((data) => {
      successCreateFolder && successCreateFolder(name, selectedNodeId, data);
      return data;
    })
  }

  const onClickShareButton = (socialProvider) => {
    ahoy().track('click_share_button', { social_provider: socialProvider, source: 'media_item', place: 'tile' });
  }

  return (
    <Paper elevation={0} square className={css.tile}>
      <div className={css.content}>
        <ShareActions
          currentFolder={currentFolder}
          canRemoveFromCurrentFolder={canRemoveFromCurrentFolder}
          removeItemFromCurrentFolder={api.removeMediaItemFromFolder}
          successRemoveItemFromCurrentFolder={handleSuccessRemoveMediaItemFromFolder}
          item={mediaItem}
          type={TYPES.mediaitem}
          openFoldersTree={openFoldersTree}
          className={clsx(css.actions, {[css.isMobile]: mobile })}
          onClickShareButton={onClickShareButton}
        />

        <div className={css.head}>
          <Tooltip
            disableFocusListener
            arrow
            title={mediaItem.title}
            placement="top"
            enterTouchDelay={0}
            enterDelay={0}
          >
            <Typography
              variant="subtitle2"
              noWrap
              color="textPrimary"
              component="div"
            >
              {mediaItem.title}
            </Typography>
          </Tooltip>
          <Typography
            noWrap
            color="textPrimary"
            component="div"
            variant="caption"
          >
            {t(`themesList.${mediaItem.theme}`)}
          </Typography>
        </div>

        <Link
          className={css.cover}
          to={{
            pathname: link,
            state: {
              title: mediaItem.title,
              translationMediaItemId: mediaItem.translation_mediaitem_id
            }
          }}
        >
          <LazyPicture webpSrc={mediaItem.webp_medium_poster} src={mediaItem.medium_poster} />
          <SubscriptionLabel mediaItem={mediaItem} />
          { renderPrice() }

          { openWordListsDialog && (
            <div className={css.known_words_progress}>
              <KnownWordsProgress mediaItem={mediaItem} openWordListsDialog={openWordListsDialog} />
            </div>
          )}

          <div
            className={clsx(css.duration, cssText.large_small, cssText.roboto_regular)}
          >
            { moment.duration(mediaItem.duration, 'seconds').format("hh:mm:ss", { stopTrim: "mm" }) }
          </div>
        </Link>

        <div className={css.footer}>
          <UILink component={Link} to={profileUrl(producer.slug)} underline="none">
            <Avatar src={producer.avatar.small} css={clsx(css.avatar)}/>

            <div className={css.producer}>
              <Typography component="div" variant="caption" noWrap color="textSecondary">
                {t('producer')}
              </Typography>

              <Typography component="div" variant="caption" noWrap color="textSecondary">
                {producer.full_name}
              </Typography>
            </div>
          </UILink>
        </div>
      </div>

      { copiedMediaItem && (
        <FoldersTree
          currentUser={{ id: currentUserId }}
          item={copiedMediaItem}
          type={TYPES.mediaitem}
          closeFoldersTree={closeFoldersTree}
          fetchFolders={api.fetchMediaItemFoldersTree}
          createFolder={createFolder}
          addItemToFolder={api.addMediaItemToFolder}
          successAddItemToFolder={addMediaItemToFolder}
          removeItemFromFolder={api.removeMediaItemFromFolder}
          successRemoveItemFromFolder={handleSuccessRemoveMediaItemFromFolder}
        />
      )}
    </Paper>
  );
};

Tile.propTypes = {
  mediaItem: object.isRequired // from props
};

Tile.propTypes = {
  currentUserId: number
};

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify
};

export default withLearnerDataAPI(
  withApi(
    connect(mapStateToProps, mapDispatchToProps)(Tile)
  )
)
