import React from 'react';
import { func } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Image from '../../ui/svg/icons/see-more.jpg';

const useStyles = makeStyles(() => ({
  seeMore: {
    backgroundColor: '#4B8BC8',
    backgroundImage: `url(${Image})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    cursor: 'pointer',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
}));

const SeeMore = (props) => {
  const { onClick } = props;

  const styles = useStyles();

  return (
    <div className={styles.seeMore} onClick={onClick} />
  )
}

SeeMore.propTypes = {
  onClick: func,
}

export default SeeMore;
