import React, {useEffect, useRef, useState} from 'react';
import { number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';

import Tooltip from '@material-ui/core/Tooltip';

import cssVariables from '../../../css/variables.scss';

import actionCableConsumer from "../../../action-cable";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: cssVariables.grey_primary,
    height: 6,
    '&:hover': {
      height: 10
    }
  },
  bar: {
    backgroundColor: (props) => props.color
  }
}));

const getColor = (knownWords, words_count) => {
  let value = knownWords / words_count

  if (Number.isNaN(value)) {
    value = 0;
  }

  const hue=((value)*120).toString(10);
  return ["hsl(",hue,",100%,50%)"].join("");
};

const KnownWordsProgress = (props) => {
  const { mediaItem, currentUserId, openWordListsDialog } = props;
  const { words_count, known_words_count, id } = mediaItem;

  const [knownWordsCount, setKnownWordsCount] = useState(known_words_count);

  const { t } = useTranslation();

  const knownWords = currentUserId ? knownWordsCount : 0;

  const classes = useStyles({ color: getColor(knownWords, words_count) });
  let progressValue = Math.floor(knownWords * 100 / words_count);

  if (Number.isNaN(progressValue)) {
    progressValue = 0;
  }

  let renderTip = '';

  if (currentUserId) {
    renderTip = t('mediaItems.tile.knownWordsProgress.toolTip', {
      knowWordsCount: knownWords, wordsCount: words_count, progressValue
    });
  } else {
    renderTip = t('mediaItems.tile.knownWordsProgress.unsignedToolTip', { wordsCount: words_count, progressValue });
  }

  const knownWordsChannelRef = useRef();

  useEffect(() => {
    if (id && currentUserId) {
      initKnownWordsChannel();
    } else {
      removeActionCableSubscriptions(knownWordsChannelRef.current);
    }

    return () => {
      removeActionCableSubscriptions(knownWordsChannelRef.current)
    };
  }, [id, currentUserId]);

  const initKnownWordsChannel = () => {
    knownWordsChannelRef.current = actionCableConsumer.subscriptions.create({
      channel: 'KnownWordsChannel',
      type: 'mediaitem_known_words',
      mediaitem_id: mediaItem.id
    }, {
      received: (data) => {
        receiveSocketData(data);
      }
    });
  };

  const removeActionCableSubscriptions = (subscription) => {
    if (subscription) {
      actionCableConsumer.subscriptions.remove(subscription);
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    openWordListsDialog(mediaItem);
  }

  const receiveSocketData = (data) => {
    setKnownWordsCount(data.known_words_count)
  }

  return (
    <div onClick={handleOnClick}>
      <Tooltip
        disableFocusListener
        arrow
        title={renderTip}
        placement="top"
        enterTouchDelay={0}
        enterDelay={0}
      >
        <LinearProgress
          classes={{root: classes.root, bar: classes.bar}}
          variant="determinate"
          color="primary"
          value={progressValue}
        />
      </Tooltip>

    </div>
  );
};

KnownWordsProgress.propTypes = {
  currentUserId: number,
  openWordListsDialog: func
}

const mapStateToProps = ({ currentUser }) => (
  { currentUserId: currentUser.id }
);

export default connect(mapStateToProps)(KnownWordsProgress);
