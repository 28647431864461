import React from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Typography from '@material-ui/core/Typography';
import Base from './base';

import css from './styles.module.scss';

const SRC = process.env.REACT_APP_HOW_IT_WORKS_VIDEO_SRC;

const About = () => {
  const { t } = useTranslation();

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - About`;
    const description = `${process.env.REACT_APP_PROJECT_NAME} has been developed based upon the beliefs that listening and reading comprehension are key skills and learners who are entertained while consuming (listening/reading) content in a foreign language are more likely to continue in their free time.`;

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/about`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };


  return (
    <Base>
      { renderMetaTags() }

      <div className={css.about}>
        <h1>{t('about.header', { projectName: process.env.REACT_APP_PROJECT_NAME })}</h1>

        <div className={css.content}>
          <Typography variant="body2" paragraph>
            {t('about.partOne', { projectName: process.env.REACT_APP_PROJECT_NAME })}
          </Typography>
          <Typography variant="body2">
            {t('about.partTwo')}
          </Typography>
          <div className={css.sign}>
            {t('about.sign', { projectName: process.env.REACT_APP_PROJECT_NAME })}
          </div>
        </div>

        <div className={css.source}>
          <video controls="controls">
            <source src={SRC} type="video/mp4" />
          </video>
        </div>
      </div>
    </Base>
  )
};

export default About;
