import React from 'react';
import { bool, object } from 'prop-types';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import css from './styles.module.scss';
import cssVariables from '../../css/variables.scss';
import cssIcon from '../../css/icon.module.scss';

import Svg from '../../ui/svg';
import withAPI from '../../api/context';

import { currentUserActions } from '../../current-user/actions';
import Animation from './animation';

const Banner = (props) => {
  const { api, currentUser, setProfileData } = props;

  const { t } = useTranslation();

  const closeBanner = () => {
    const params = { ...currentUser.settings, banner_closed: true };
    api.updateProfile({ settings: params }).then((data) => setProfileData(data));
  };

  const renderCloseButton = () => {
    if (currentUser.id && !currentUser.settings.banner_closed) {
      return (
        <IconButton size="small" onClick={closeBanner}>
          <HighlightOffIcon style={{ fontSize: 46, color: cssVariables.white_white }} />
        </IconButton>
      )
    }

    return null;
  };

  const visible = !currentUser.id || (!currentUser.settings.banner_closed);

  return (
    <Collapse in={visible} timeout={2000} mountOnEnter unmountOnExit>
      <div className={css.banner}>
        <div className={css.header}>
          <div className={css.logo_wrapper}>
            <Svg name="headerLogo" css={clsx(css.logo, cssIcon.fill_white_white)}/>
            <h1>&nbsp;{t('banner.headerText')}</h1>
          </div>
          <div>
            { renderCloseButton() }
          </div>
        </div>
        <Animation />
      </div>
    </Collapse>
  )
};

Banner.propTypes = {
  visible: bool,
  showCloseIcon: bool,
  currentUser: object
};

Banner.defaultProps = {
  visible: false,
  showCloseIcon: false
};

const mapStateToProps = ({ currentUser }) => (
  {
    currentUser
  }
);

const mapDispatchToProps = {
  setProfileData: currentUserActions.setData
};

export default connect(mapStateToProps, mapDispatchToProps)(withAPI(Banner));
