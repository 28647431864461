import React from 'react'
import Files from "./files";
import ProducedContent from "./produced_content";
import WatchedContent from "./watched_content";

const Content = () => {
  return (
    <div>
      <Files />
      <ProducedContent />
      <WatchedContent />
    </div>
  )
}

export default Content;
