import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import Editor from 'alp-shared-components/dist/shared/editor';

const Form = (props) => {
  const { comment, onSubmit } = props;

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={comment}
      onSubmit={onSubmit}
      render={( { submitForm, setFieldValue, errors, touched } ) => {
        return (
          <Fragment>
            <div>
              <Editor
                error={touched.body && errors.body}
                name="body"
                onChange={setFieldValue}
              />
            </div>

            <DialogActions>
              <Button onClick={submitForm} color="primary">
                { t('buttonTitles.submit') }
              </Button>
            </DialogActions>
          </Fragment>
        )
      }}
    />
  )
};

Form.propTypes = {
  comment: object,
  onSubmit: func.isRequired // from props
};

Form.defaultProps = {
  comment: {
    body: ''
  }
};

export default Form;
