import files from './files/reducer';
import { actions } from './actions';

const initialState = {
  id: null,
  profile_social_accounts: [],
  currentTab: 0
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, ...action.payload };
    case actions.ADD_SOCIAL_ACCOUNT:
      return { ...state, profile_social_accounts: [ ...state.profile_social_accounts, action.payload ]};
    case actions.DELETE_SOCIAL_ACCOUNT:
      return { ...state, profile_social_accounts: state.profile_social_accounts.filter((account) => account.id !== action.payload)};
    case actions.SET_CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    default:
      return {
        ...state,
        files: files(state.files, action)
      };
  }
};

export default profile;
