import { isEqual } from 'lodash';
import { entryActions } from '../entry/actions';
console.log('22')

const PREFIX = 'MEDIA_ITEMS_';

const actions = {
  FETCH_DATA: PREFIX + 'FETCH_DATA',
  SET_DATA: PREFIX + 'SET_DATA',
  ADD_DATA: PREFIX + 'ADD_DATA',
  SET_HAS_MORE_ITEMS: PREFIX + 'SET_HAS_MORE_ITEMS',
  SET_ERROR: PREFIX + 'SET_ERROR',
  SET_AVAILABLE_SOURCE_LANGUAGES: `${PREFIX}SET_AVAILABLE_SOURCE_LANGUAGES`
};

const getQueryFromState = (getState) => {
  const { query } = getState().mediaItems;

  const {
    levels,
    theme,
    order,
    searchValue,
    producer,
    watcher,
    payer,
    withPrice,
    sourceLanguage,
    translationLanguage
  } = query;

  return {
    levels,
    theme,
    order,
    searchValue,
    producerId: producer.id,
    payerId: payer.id,
    watcherId: watcher.id,
    withPrice,
    sourceLanguage: sourceLanguage.code,
    translationLanguage
  };
};

const fetchData = () => (
  (dispatch, getState, { ITL }) => {
    const query = getQueryFromState(getState);

    const { page, perPage } = getState().mediaItems.query;

    return ITL.api.fetchMediaItems({ ...query, page, perPage }).then((data) => {
      if (!isEqual(query, getQueryFromState(getState))) {
        return; // query has been changed while the request was being performed
      }

      dispatch(setError(null));

      dispatch(setHasMoreItems(data.length >= perPage));

      page === 1 ? dispatch(setData(data)) : dispatch(addData(data));

      return data;
    }).catch((error) => dispatch(setError(error)))
  }
);

const fetchAvailableSourceLanguages = () => (dispatch, getState, { ITL }) => (
  ITL.api.fetchMediaItemsLanguages()
    .then((mediaItemLanguages) => dispatch(setAvailableSourceLanguages(mediaItemLanguages)))
);

const setAvailableSourceLanguages = (mediaItemLanguages) => ({
  type: actions.SET_AVAILABLE_SOURCE_LANGUAGES,
  payload: mediaItemLanguages
});

const setData = (data) => (
  {
    type: actions.SET_DATA,
    payload: data
  }
);

const addData = (data) => (
  {
    type: actions.ADD_DATA,
    payload: data
  }
);

const setHasMoreItems = (value) => (
  {
    type: actions.SET_HAS_MORE_ITEMS,
    payload: value
  }
);

const setError = (error) => ({
  type: actions.SET_ERROR,
  error: true,
  payload: error
});

const mediaItemsActions = {
  fetchData,
  setData,
  fetchAvailableSourceLanguages
};

export { actions, mediaItemsActions };
