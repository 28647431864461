import React from 'react';
import { bool, func } from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';

import { useTranslation } from 'react-i18next';

import Item from '../../../../../ui/menu/item';

const Rename = (props) => {
  const { onClick, visible } = props;
  const { t } = useTranslation();

  if (visible) {
    return (
      <Item onClick={onClick} icon={<EditIcon color="primary" />} text={t('profile.files.folderActions.rename')} />
    )
  }

  return null;
};

Rename.propsTypes = {
  visible: bool.isRequired, // from props
  onClick: func.isRequired // from props
};

export default Rename;
