import React from 'react';
import { object, func } from 'prop-types';

import List from '@material-ui/core/List';

import css from './styles.module.scss';
import Item from "./item";

const MediaItems = (props) => {
  const { group, openWordListsDialog } = props;
  const { mediaitems } = group;

  return (
    <List className={css.mediaItems}>
      { mediaitems.map((mediaItem) => (
        <Item openWordListsDialog={openWordListsDialog} key={mediaItem.id} mediaItem={mediaItem} />
      ))}
    </List>
  )
}

MediaItems.propTypes = {
  group: object,
  openWordListsDialog: func
}

export default MediaItems;
