import React, { Fragment } from 'react';
import { object } from 'prop-types';
import clsx from 'clsx';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import css from './styles.module.scss';
import cssText from '../../../css/text.module.scss';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import CreatedTime from "../../../feedbacks/item/created-time";
import { profileUrl } from "../../../profile/utils";

const Item = (props) => {
  const {
    comment
  } = props;

  const { commenter } = comment;

  const avatar = () => {
    if (commenter.avatar) {
      return (
        <Link component={RouterLink} to={profileUrl(commenter.slug)} underline="none">
          <Tooltip title={commenter.full_name}>
            <Avatar
              src={commenter.avatar.small}
            />
          </Tooltip>
        </Link>
      )
    }

    return (
      <Tooltip title={commenter.full_name}>
        <Avatar className={css.admin_avatar}>
          <SupervisorAccountIcon />
        </Avatar>
      </Tooltip>
    )
  };

  return (
    <Fragment>
      <ListItem alignItems="flex-start" className={css.item}>
        <ListItemAvatar>
          { avatar() }
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <div dangerouslySetInnerHTML={{__html: comment.body}} />
          }
          secondary={
            <div className={clsx(css.created_at, cssText.small, cssText.roboto_regular, cssText.cl_bluegrey_cards)}>
              <CreatedTime createdAt={comment.created_at}/>
            </div>
          }
        />
      </ListItem>
    </Fragment>
  )
};

Item.propTypes = {
  comment: object.isRequired // from props
};

export default Item;
