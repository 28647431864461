import React, { Fragment } from 'react';
import { number, func, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShopIcon from '@material-ui/icons/Shop';
import Divider from '@material-ui/core/Divider';

import { queryActions } from "../../media-items/query/actions";

const PaidContentFilter = (props) => {
  const {
    currentUser,
    payerId,
    setPayer,
    withPrice,
    toggleWithPrice,
    history
  } = props;

  const { t } = useTranslation();
  const setUpPaidForContentFilter = () => {
    history.push('/');
    setPayer(payerId === currentUser.id ? {} : { id: currentUser.id, fullName: currentUser.full_name });
  };

  const handleToggleWithPrice = () => {
    history.push('/');
    toggleWithPrice();
  }

  return (
    <List dense disablePadding>
      {currentUser.id && (
        <Fragment>
          <ListItem button onClick={setUpPaidForContentFilter}>
            <ListItemIcon>
              <AttachMoneyIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('menu.paidContentFilter')}/>

            <ListItemSecondaryAction onClick={setUpPaidForContentFilter}>
              <Checkbox color="primary" checked={Boolean(payerId)}/>
            </ListItemSecondaryAction>
          </ListItem>

          <Divider/>
        </Fragment>
      )}

      <ListItem button onClick={handleToggleWithPrice}>
        <ListItemIcon>
          <ShopIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t('menu.withPrice')} />

        <ListItemSecondaryAction onClick={handleToggleWithPrice}>
          <Checkbox color="primary" checked={withPrice} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
};

PaidContentFilter.propTypes = {
  currentUser: object,
  payerId: number,
  setPayer: func.isRequired,
  withPrice: bool.isRequired,
  toggleWithPrice: func.isRequired
};

const mapStateToProps = ({ currentUser, mediaItems: { query } }) => (
  {
    payerId: query.payer.id,
    withPrice: query.withPrice,
    currentUser
  }
);

const mapDispatchToProps = {
  setPayer: queryActions.setPayer,
  toggleWithPrice: queryActions.toggleWithPrice
};

export default connect(mapStateToProps, mapDispatchToProps)(PaidContentFilter);
