import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";

import includes from 'lodash/includes';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { LEVELS_LIST } from '../../query/constants';

const DesktopLevels = (props) => {
  const {
    queryLevels,
    onChangeLevel
  } = props;

  const { t } = useTranslation();

  return(
    <FormGroup row>
      { LEVELS_LIST.map((level) => (
        <FormControlLabel
          key={level}
          control={
            <Checkbox
              checked={includes(queryLevels, level)}
              onChange={onChangeLevel(level)}
              value={level}
              color="primary"
            />
          }
          label={<Typography color="textPrimary">{ t(`levelsList.${level}`) }</Typography>}
        />))
      }
    </FormGroup>
  )
};

DesktopLevels.propTypes = {
  queryLevels: PropTypes.array.isRequired, // from mediaItems.query state
  onChangeLevel: PropTypes.func.isRequired // from props
};

const mapStateToProps = ({ mediaItems: { query } }) => (
  {
    queryLevels: query.levels
  }
);

export default connect(mapStateToProps)(DesktopLevels);
