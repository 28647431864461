import React from 'react';
import { func } from 'prop-types';

import { useTranslation } from 'react-i18next';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Item from '../../../../../ui/menu/item';

const Logout = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <Item onClick={onClick} icon={<ExitToAppIcon color="primary" />} text={t('header.userBlock.menu.items.logout')} />
  );
};

Logout.propsTypes = {
  onClick: func.isRequired // from props
};

export default Logout;

