import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from "alp-shared-components/dist/shared/svg-icon";
import { ReactComponent as headerLogo } from './icons/header_logo.svg';
import { ReactComponent as icFolderMenu } from './icons/ic_folder_menu.svg';
import { ReactComponent as icSunburst } from './icons/ic_sunburst.svg';

// THEMES
import { ReactComponent as advertisement } from './icons/themes/advertisement.svg';
import { ReactComponent as cartoons } from './icons/themes/cartoons.svg';
import { ReactComponent as films } from './icons/themes/films.svg';
import { ReactComponent as humor } from './icons/themes/humor.svg';
import { ReactComponent as lessons } from './icons/themes/lessons.svg';
import { ReactComponent as news } from './icons/themes/news.svg';
import { ReactComponent as poetry } from './icons/themes/poetry.svg';
import { ReactComponent as reading } from './icons/themes/reading.svg';
import { ReactComponent as songs } from './icons/themes/songs.svg';
import { ReactComponent as other } from './icons/themes/other.svg';
import { ReactComponent as comedy } from './icons/themes/comedy.svg';
import { ReactComponent as documentary } from './icons/themes/documentary.svg';
import { ReactComponent as short_movies } from './icons/themes/short_movies.svg';
import { ReactComponent as travel_and_food } from './icons/themes/travel_and_food.svg';
import { ReactComponent as horror_and_suspense } from './icons/themes/horror_and_suspense.svg';
import { ReactComponent as thrillers } from './icons/themes/thrillers.svg';
import { ReactComponent as adventure_and_action } from './icons/themes/adventure_and_action.svg';
import { ReactComponent as tv_series } from './icons/themes/tv_series.svg';
import { ReactComponent as drama } from './icons/themes/drama.svg';

//Social accounts
import { ReactComponent as facebook } from './icons/social-accounts/facebook.svg';
import { ReactComponent as vkontakte } from './icons/social-accounts/vkontakte.svg';
import { ReactComponent as youtube } from './icons/social-accounts/youtube.svg';
import { ReactComponent as linkedin } from './icons/social-accounts/linkedin.svg';
import { ReactComponent as twitter } from './icons/social-accounts/twitter.svg';
import { ReactComponent as instagram } from './icons/social-accounts/instagram.svg';
import { ReactComponent as odnoklassniki } from './icons/social-accounts/odnoklassniki.svg';
import { ReactComponent as pinterest } from './icons/social-accounts/pinterest.svg';
import { ReactComponent as vimeo } from './icons/social-accounts/vimeo.svg';

const mapIcons = {
  headerLogo,
  icFolderMenu,

  advertisement,
  cartoons,
  films,
  humor,
  lessons,
  news,
  poetry,
  reading,
  songs,
  other,
  comedy,
  documentary,
  short_movies,
  travel_and_food,
  horror_and_suspense,
  thrillers,
  adventure_and_action,
  tv_series,
  drama,

  facebook,
  vkontakte,
  youtube,
  linkedin,
  twitter,
  instagram,
  odnoklassniki,
  pinterest,
  vimeo,
  icSunburst
};

const Svg = (props) => {
  const { css, name } = props;
  const SvgComponent = mapIcons[name];

  return (
    <SvgIcon css={css}>
      <SvgComponent />
    </SvgIcon>
  );
};

Svg.propTypes = {
  name: PropTypes.string.isRequired,
  css: PropTypes.string
};

Svg.defaultProps = {
  css: null
};

export default Svg;
