const actions = {
  START_FETCH_DATA: 'START_FETCH_DATA',
  END_FETCH_DATA: 'END_FETCH_DATA'
};

const startFetchData = () => (
  { type: actions.START_FETCH_DATA }
);

const endFetchData = () => (
  { type: actions.END_FETCH_DATA }
);

const entryActions = {
  startFetchData, endFetchData
};

export { actions, entryActions };
