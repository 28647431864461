import React from 'react';
import PropTypes from 'prop-types';

import { default as MUDialog } from '@material-ui/core/Dialog';

import css from './styles.module.scss';

const Modal = (props) => {
  const { children, onClose, open, ...restProps } = props;

  return (
    <MUDialog
      { ...restProps }
      open={open}
      onClose={onClose}
      classes={{
        root: css.root,
        paper: css.paper,
        paperFullWidth: css.fullWidth
      }}
    >
      { children }
    </MUDialog>

  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired, // from props
  children: PropTypes.node, // from props
  onClose: PropTypes.func.isRequired // from props
};

export default Modal;
