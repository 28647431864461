import React, {Component} from "react";
import { func } from 'prop-types';

import Media from 'react-media';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { queryActions } from '../query/actions';

import css from './styles.module.scss'
import withAPI from '../../api/context';
import DesktopLevels from './desktop-levels';
import MobileLevels from './mobile-levels';
import { TABLET_LANDSCAPE_MAX } from '../../constants';

class LevelFilter extends Component {
  static propTypes = {
    t: func.isRequired, // from withTranslation
    addLevelToQuery: func.isRequired, // from queryActions
    removeLevelFromQuery: func.isRequired // from queryActions
  };

  constructor(props) {
    super(props);
  }

  onChangeLevel = (level) => event => {
    const {
      addLevelToQuery,
      removeLevelFromQuery
    } = this.props;

    if (event.target.checked) {
      addLevelToQuery(level);
    }
    else {
      removeLevelFromQuery(level);
    }
  };

  render() {
    return(
      <div className={css.level_filter}>
        <Media query={{ maxWidth: TABLET_LANDSCAPE_MAX }}>
          { (matches) => matches ?
            <MobileLevels onChangeLevel={this.onChangeLevel}/> :
            <DesktopLevels onChangeLevel={this.onChangeLevel}/>
          }
        </Media>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addLevelToQuery: queryActions.addLevel,
  removeLevelFromQuery: queryActions.removeLevel
};

export default withAPI(
  connect(null, mapDispatchToProps)(withTranslation()(LevelFilter))
);
