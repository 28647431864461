import React from 'react';
import { string } from 'prop-types';
import FeedbackIcon from '@material-ui/icons/Feedback';

import { FEEDBACK_KIND } from 'alp-shared-components/dist/feedback/constants';
import {
  BugReportIcon,
  NotificationImportantIcon,
  HelpIcon,
  NewReleasesIcon,
  CakeIcon
} from 'alp-shared-components/dist/feedback/feedback-dialog';

const icons = {
  [FEEDBACK_KIND.BUG]: <BugReportIcon fontSize="large" />,
  [FEEDBACK_KIND.QUESTION]: <HelpIcon fontSize="large" />,
  [FEEDBACK_KIND.SUGGESTION]:<NewReleasesIcon fontSize="large" />,
  [FEEDBACK_KIND.PRAISE]: <CakeIcon fontSize="large" />,
  [FEEDBACK_KIND.CONTENT_REQUEST]: <NotificationImportantIcon fontSize="large" />,
  [FEEDBACK_KIND.FLASHCARD]: <FeedbackIcon fontSize="large" color="primary" />
};

const Icon = (props) => {
  const { kind } = props;

  return icons[kind]
};

Icon.propTypes = {
  kind: string.isRequired // from props
};

export default Icon;
