import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import css from './styles.module.scss';

export default function Main(props) {
  const { post, title } = props;

  return (
    <Grid item xs={12} md={12} className={css.main}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />

      <div
        className={css.body}
        dangerouslySetInnerHTML={{ __html: post.body }}>
      </div>

    </Grid>
  );
}

Main.propTypes = {
  post: PropTypes.object,
  title: PropTypes.string,
};
