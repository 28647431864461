import React, { Fragment } from 'react';
import { object, number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

const Achievements = (props) => {
  const { history, producedContentCount, setProducer, profile } = props;
  const { t } = useTranslation();

  const goToProducedContent = () => {
    if (producedContentCount > 0) {
      history.push('/');
      setProducer({ id: profile.id, fullName: profile.full_name });
    }
  };

  const producedContent = () => {
    return (
      <IconButton
        disabled={producedContentCount === 0}
        onClick={goToProducedContent}
      >
        <Tooltip title={t('profile.info.achievements.producedContent')}>
          <Badge
            badgeContent={producedContentCount}
            showZero
            color={producedContentCount === 0 ? 'error' : 'primary'}
          >
            <OndemandVideoIcon color="primary"/>
          </Badge>
        </Tooltip>
      </IconButton>
    )
  }

  const renderContent = () => {
    return (
      <Fragment>
        { producedContent() }
      </Fragment>
    )
  }

  return(
    <ListItem>
      <ListItemText primary={t('profile.info.sections.achievements')} secondary={renderContent()} />
    </ListItem>
  )
}

Achievements.propTypes = {
  history: object,
  producedContentCount: number,
  setProducer: func,
  profileId: number
}

Achievements.defaultProps = {
  producedContentCount: 0
}

export default Achievements;
