import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import { queryActions } from '../query/actions';
import { mediaItemsActions } from '../actions';

const SourceLanguageSelect = (props) => {
  const { sourceLanguage, setSourceLanguage, availableSourceLanguages, fetchAvailableSourceLanguages } = props;
  const { t } = useTranslation();

  useEffect(() => {
    fetchAvailableSourceLanguages();
  }, [fetchAvailableSourceLanguages]);

  if (!availableSourceLanguages) {
    return null;
  }

  const handleChange = ({ target: { value } }) => {
    setSourceLanguage({ code: value.code, name: value.name })
  };

  return (
    <FormControl variant="outlined">
      <InputLabel>{t('mediaItems.sourceLanguageSelect.label')}</InputLabel>
      <Select
        value={sourceLanguage}
        onChange={handleChange}
        renderValue={(value) => t(`sourceLanguage.${value.code}`, value.name)}
        label={t('mediaItems.sourceLanguageSelect.label')}
      >
        {availableSourceLanguages.map(({ name, code }) => (
          <MenuItem key={code} value={{ code, name }}>
            {t(`sourceLanguage.${code}`, name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = ({ mediaItems: { query, availableSourceLanguages }}) => ({
  sourceLanguage: query.sourceLanguage,
  availableSourceLanguages
});

const mapDispatchToProps = {
  setSourceLanguage: queryActions.setSourceLanguage,
  fetchAvailableSourceLanguages: mediaItemsActions.fetchAvailableSourceLanguages
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceLanguageSelect);
