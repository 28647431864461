import { actions } from './actions';

const initialState = {
  themes: [],
  isVisible: true
};

const header = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_THEMES:
      return { ...state, themes: action.payload };
    case actions.SET_IS_VISIBLE_HEADER:
      return { ...state, isVisible: action.payload };
    default:
      return state;
  }
};

export default header;
