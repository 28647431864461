import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import last from 'lodash/last';

import Modal from '../../ui/modals/modal';

import Form from './folders/form';

import ShareModal from '../../shared/share-modal';
import { folderShareLink } from '../utils';

import { filesActions } from './actions';
import Dialog from '../../ui/modals/dialog';

const mapStateToProps = ({ profile: { files } }) => (
  {
    parentFolder: last(files.breadCrumbs) || {},
  }
);

const mapDispatchToProps = {
  createFolder: filesActions.createFolder,
  updateFolder: filesActions.updateFolder,
  saveFolder: filesActions.saveFolder,
  removeFolder: filesActions.removeFolder,
  deleteFolder: filesActions.deleteFolder
};

const withFolderActions = (Component) => connect(mapStateToProps, mapDispatchToProps)((props) => {
  const [newFolder, setNewFolder] = useState(null);
  const [editFolder, setEditFolder] = useState(null);
  const [shareFolder, setShareFolder] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const [dialogSubmitAttributes, setDialogSubmitAttributes] = React.useState(null);
  const [dialogContent, setDialogContent] = React.useState(null);
  const [dialogHeaderText, setDialogHeaderText] = React.useState(null);

  const {
    parentFolder,

    createFolder,
    updateFolder,
    saveFolder,
    removeFolder,
    deleteFolder
  } = props;

  const { t } = useTranslation();

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const openNewFolderModal = (newFolder = { name: '' }) => {
    closeMenu();
    setNewFolder(newFolder);
  };

  const closeNewFolderModal = () => {
    setNewFolder(null)
  };

  const openEditFolderModal = (folder) => {
    closeMenu();
    setEditFolder(folder);
  };

  const closeEditFolderModal = () => {
    setEditFolder(null)
  };

  const openShareModal = (folder) => {
    closeMenu();
    setShareFolder(folder);
  };

  const closeShareModal = () => {
    setShareFolder(null);
  };

  const onCreateSubmit = (values, actions) => {
    createFolder(values.name, parentFolder.id).then((data) => {
      closeNewFolderModal();
    }, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
    })
  };

  const onUpdateSubmit = (values, actions) => {
    updateFolder(editFolder.id, { name: values.name }, parentFolder.id).then((data) => {
      closeEditFolderModal();
    }, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
    })
  };

  const onSaveFolder = (folder) => {
    closeMenu();
    saveFolder(folder.id, parentFolder.id)
  };

  const onRemoveFolder = (folder, parent = parentFolder) => {
    closeMenu();

    setDialogHeaderText(t('profile.files.removeDialog.header', { folderName: folder.name }));
    setDialogContent(t('profile.files.removeDialog.text',
      { folderName: folder.name, ownerName: folder.owner.full_name }));
    setDialogSubmitAttributes({ functionName: removeFolder, folder, parent });
  };

  const onDeleteFolder = (folder, parent = parentFolder) => {
    closeMenu();

    setDialogHeaderText(t('profile.files.deleteDialog.header', { folderName: folder.name }));
    setDialogContent(t('profile.files.deleteDialog.text', { folderName: folder.name }));
    setDialogSubmitAttributes({ functionName: deleteFolder, folder, parent });
  };

  const closeDialog = () => {
    setDialogSubmitAttributes(null);
  };

  const submitDialog = () => {
    const { functionName, folder, parent } = dialogSubmitAttributes;
    functionName(folder.id, parent.id).then((data) => setDialogSubmitAttributes(null))
  };

  return (
    <Fragment>
      <Component
        openNewFolderModal={openNewFolderModal}
        openEditFolderModal={openEditFolderModal}
        openShareModal={openShareModal}

        onSaveFolder={onSaveFolder}
        onRemoveFolder={onRemoveFolder}
        onDeleteFolder={onDeleteFolder}

        parentFolder={parentFolder}

        openMenu={openMenu}
        closeMenu={closeMenu}
        menuAnchorEl={menuAnchorEl}

        {...props}
      />

      <Modal open={Boolean(newFolder)} onClose={closeNewFolderModal}>
        <DialogTitle onClose={closeNewFolderModal}>
          { t('profile.files.createModal.header') }
        </DialogTitle>

        <Form folder={newFolder} onSubmit={onCreateSubmit} onCancel={closeNewFolderModal} />
      </Modal>

      <Modal open={Boolean(editFolder)} onClose={closeEditFolderModal}>
        <DialogTitle onClose={closeEditFolderModal}>
          { t('profile.files.editModal.header', { folderName: editFolder && editFolder.name }) }
        </DialogTitle>

        <Form folder={editFolder} onSubmit={onUpdateSubmit} onCancel={closeEditFolderModal} />
      </Modal>

      <Dialog
        open={Boolean(dialogSubmitAttributes)}
        onClose={closeDialog}
        onCancel={closeDialog}
        onSubmit={submitDialog}
        headerText={dialogHeaderText}
      >
        <DialogContent>
          <DialogContentText>
            { dialogContent }
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <ShareModal
        onClose={closeShareModal}
        shareLink={folderShareLink(shareFolder)}
        headerText={t('profile.files.shareModal.header', { title: shareFolder && shareFolder.name })}
      />

    </Fragment>
  )
});

withFolderActions.propTypes = {
  parentFolder: PropTypes.object.isRequired, // from files.breadCrumbs

  createFolder: PropTypes.func.isRequired, // from filesActions
  updateFolder: PropTypes.func.isRequired, // from filesActions
  saveFolder: PropTypes.func.isRequired, // from filesActions
  removeFolder: PropTypes.func.isRequired, // from filesActions
  deleteFolder: PropTypes.func.isRequired // from filesActions
};

export default withFolderActions;
