import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Formik, Field, ErrorMessage } from 'formik';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import css from './styles.module.scss';

const Form = (props) => {
  const { folder, onSubmit, onCancel } = props;

  const { t } = useTranslation();

  const renderNameField = ({ field, form: { touched, errors }}) => {
    const isError = errors.name && touched.name;

    return (
      <FormControl error={isError} fullWidth>
        <InputLabel>{t('profile.files.form.namePlaceholder')}</InputLabel>
        <Input {...field} />
        <FormHelperText>
          <ErrorMessage name="name"/>
        </FormHelperText>
      </FormControl>
    )
  };

  return (
    <div className={css.form}>
      <Formik
        initialValues={folder}
        onSubmit={onSubmit}
        render={formProps => {
          return (
            <Fragment>
              <div className={css.content}>
                <Field
                  render={renderNameField}
                  name="name">
                </Field>
              </div>

              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  { t('buttonTitles.cancel') }
                </Button>
                <Button onClick={formProps.submitForm} color="primary">
                  { t('buttonTitles.submit') }
                </Button>
              </DialogActions>
            </Fragment>
          )
        }}
      />
    </div>
  );
};

Form.propTypes = {
  folder: PropTypes.object, // from props
  onSubmit: PropTypes.func.isRequired, // from props
  onCancel: PropTypes.func.isRequired // from props
};

Form.defaultProps = {
  folder: { name: '' }
};

export default Form;
