import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { connect } from "react-redux";

import Bigdef from "alp-shared-components/dist/bigdef";
import { AddPicturesProvider } from "alp-shared-components/dist/bigdef/pictures/add/AddPicturesProvider";
import { DeletePictureProvider } from "alp-shared-components/dist/bigdef/pictures/delete/DeletePictureProvider";
import { getTargetLanguageCode } from "alp-shared-components/dist/shared/utils";

import css from './styles.module.scss';
import withBigdefAPI from '../../api/context-bigdef';
import withLearnerDataAPI from "../../api/context-learner-data";
import PictureUpdater from './PictureUpdater';

const Container = (props) => {
  const {
    open,
    onClose,
    word,
    lookup,
    getTranslations,
    fetchSampleSentences,
    fetchAudioSamples,
    fetchPictures,
    upVote,
    downVote,
    removeVote,
    onSubmitSuggestion,
    learnerDataApi,
    bigdefApi,
    bigdefSettings,
    isSuperProducer
  } = props;

  const { i18n } = useTranslation();

  const [wordInfo, setWordInfo ] = useState(null);

  const lemma = word && (word.lemma || word.word);

  const getWordInfo = useCallback(
    () => {
      const targetLanguageCode = getTargetLanguageCode(i18n.language, word.language_code, word.language_code)
      lookup(lemma, word.language_code, true, null, targetLanguageCode).then(setWordInfo)
    },
    [word, lookup]
  );

  useEffect(() => {
    if (word) {
      getWordInfo()
    }
  }, [word, getWordInfo]);

  const closeBigdef = () => {
    onClose()
    setWordInfo(null)
  }

  const renderContent = () => {
    if (wordInfo) {
      return (
        <AddPicturesProvider
          word={lemma}
          savePictures={bigdefApi.customSavePictures}
          customSearchPictures={bigdefApi.customSearchPictures}
          canBeEdit={isSuperProducer}
        >
          <DeletePictureProvider deletePicture={bigdefApi.deletePicture}>
            <Bigdef
              tinymceApiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              wordInfo={wordInfo}
              word={lemma}
              getTranslations={getTranslations}
              fetchSampleSentences={fetchSampleSentences}
              fetchAudioSamples={fetchAudioSamples}
              fetchPictures={fetchPictures}
              sourceLanguageCode={word.language_code}
              targetLanguageCode={getTargetLanguageCode(i18n.language, word.language_code, word.language_code)}
              nativeLanguageCode={i18n.language}
              className={css.bigdefContainer}
              upVote={upVote}
              downVote={downVote}
              removeVote={removeVote}
              onSubmitSuggestion={onSubmitSuggestion}
              changeKnownWord={learnerDataApi.changeKnownWord}
              defaultDictionaryWordId={0}
              sectionHeadersVariant="withoutHeader"
              wordTabsProperties={{
                withWordListIcon: Boolean(word.lemma),
                withKnownListIcon: Boolean(word.lemma)
              }}
              setting={bigdefSettings}
              isSuperProducer={isSuperProducer}
            />
            <PictureUpdater />
          </DeletePictureProvider>
        </AddPicturesProvider>
      )
    }

    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={closeBigdef}
      maxWidth="md"
      fullWidth
      classes={{paper: css.dialogRoot}}
    >
      { renderContent() }
    </Dialog>
  )
}

Container.propTypes = {
  bigdefSettings: object
}

Container.defaultProps = {
}

const mapStateToProps = ({ currentUser }) => (
  {
    bigdefSettings: currentUser && currentUser.bigdef_settings,
    isSuperProducer: currentUser && currentUser.is_super_producer
  }
);

export default withLearnerDataAPI(
  withBigdefAPI(connect(mapStateToProps)(Container))
)
