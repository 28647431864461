/**
 * Gets share link to a folder
 */
const folderShareLink = (folder) => {
  if (!folder) {
    return '';
  }
  return `${window.location.origin}/profiles/${folder.owner.slug}?p=${btoa(folder.id)}`;
};

const profileUrl = (id) => `/profiles/${id}`;

const goToProfile = (history, id) => history.push(profileUrl(id));

const removeFirstFoundElemFromArray = (array, ...elements) => {
  elements.forEach((elem) => {
    const index = array.indexOf(elem);

    if (index > -1) {
      array.splice(index, 1);
    }
  })

  return array;
}

export { folderShareLink, goToProfile, profileUrl, removeFirstFoundElemFromArray };
