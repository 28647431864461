import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(90deg, ${theme.palette.mediaTag.background} 65%, transparent)`,
    color: `${theme.palette.mediaTag.color}`,
    padding: '3px 35px 3px 5px',
    textTransform: 'uppercase',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0
    }
  }
}))

const Tag = (props) => {
  const { tag } = props;
  const { t } = useTranslation();

  const styles = useStyles();

  return (
    <div className={styles.root}>
      { t(`group.mediaTag.names.${tag.name}`) || tag.name }
    </div>
  )
}

Tag.propTypes = {
  tag: object
}

export default Tag;
