import React, { useRef, useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { profileActions } from "../../../../actions";
import { alertifyActions } from "../../../../../shared/alertify/actions";
import { currentUserActions } from "../../../../../current-user/actions";

import clsx from 'clsx';

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Delete from '@material-ui/icons/Delete';

import { default as UIAvatar } from '../../../../../ui/avatar';

import Dialog from '../../../../../ui/modals/dialog';

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import css from './styles.module.scss';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

const fileTypesList = {
  'image/jpeg': '*.jpeg',
  'image/jpg': '*.jpg',
  'image/png': '*.png'
};

const url = process.env.REACT_APP_ITL_URL;
const apiUploadAvatarUrl = '/api/client/users/upload_avatar';

const Avatar = (props) => {
  const {
    avatar,
    setProfileAvatar,
    setCurrentUserAvatar,
    removeAvatar,
    openAlertify
  } = props;

  const uploader = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [removeDialogOpened, setRemoveDialogOpened] = useState(false);

  const { t } = useTranslation();

  const translationsForLabels = (key) => t(`filePondUploader.labels.${key}`);

  const onLoad = (response) => {
    const avatar = JSON.parse(response);
    setProfileAvatar(avatar);
    setCurrentUserAvatar(avatar);

    setTimeout(() => {
      uploader.current.removeFiles();
    }, 2000);

    openAlertify({
      type: 'success',
      text: 'profile.info.settings.general.avatar.uploadSuccessMessage'
    });
  };

  const onError = (response) => {
    const errorMessages = JSON.parse(response);

    openAlertify({
      type: 'error',
      text: `validations.errorMessages.${errorMessages.errors.avatar}`
    })
  };

  const onaddfilestart = () => {
    setProcessing(true)
  };

  const onremovefile = () => {
    setProcessing(false)
  };

  const openBrowserWindow = () => {
    uploader.current.browse();
  };

  const onRemoveAvatar = () => {
    setRemoveDialogOpened(true)
  };

  const closeRemoveDialog = () => {
    setRemoveDialogOpened(false)
  };

  const handleRemoveAvatar = () => {
    closeRemoveDialog();
    removeAvatar().then()
  };

  const labelFileProcessingError = (error) => {
    if (error.code === 422) {
      return t('validations.globalMessage');
    }

    return error.body;
  };

  return (
    <div className={clsx(css.avatar, { [css.processing]: processing })}>
      <UIAvatar img={avatar.large} size="large"/>

      <FilePond
        ref={uploader}
        name="avatar"
        allowMultiple={false}
        maxFiles={1}
        server={{
          url,
          process: {
            url: apiUploadAvatarUrl,
            method: 'PUT',
            withCredentials: true,
            onload: onLoad,
            onerror: onError
          }
        }}

        labelIdle=""
        labelTapToCancel=""
        labelTapToUndo=""
        labelTapToRetry=""
        labelFileProcessing={translationsForLabels('labelFileProcessing')}
        labelFileProcessingComplete={translationsForLabels('labelFileProcessingComplete')}
        labelFileTypeNotAllowed={translationsForLabels('labelFileTypeNotAllowed')}
        fileValidateTypeLabelExpectedTypes={translationsForLabels('fileValidateTypeLabelExpectedTypes')}
        fileValidateTypeLabelExpectedTypesMap={fileTypesList}
        labelMaxFileSizeExceeded={translationsForLabels('labelMaxFileSizeExceeded')}
        labelMaxFileSize={translationsForLabels('labelMaxFileSize')}
        labelFileProcessingError={labelFileProcessingError}


        onaddfilestart={onaddfilestart}
        onremovefile={onremovefile}

        allowFileSizeValidation
        maxFileSize="10MB"
        allowFileTypeValidation
        acceptedFileTypes={Object.keys(fileTypesList)}

        stylePanelLayout="integrated"
        allowRevert={false}
        allowRetry={false}
      />

      { avatar.is_uploaded &&
        <Fab className={clsx(css.remove_photo_button, {[css.hidden]: processing})} onClick={onRemoveAvatar} color="primary">
          <Delete />
        </Fab>
      }

      <Fab className={clsx(css.upload_photo_button, {[css.hidden]: processing})} onClick={openBrowserWindow} color="primary">
        <PhotoCamera />
      </Fab>

      <Dialog
        open={removeDialogOpened}
        onClose={closeRemoveDialog}
        onCancel={closeRemoveDialog}
        onSubmit={handleRemoveAvatar}
        headerText={t('profile.info.settings.general.avatar.removingDialog.header')}
      >
        <DialogContent>
          <DialogContentText>
            { t('profile.info.settings.general.avatar.removingDialog.content') }
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </div>
  )
};

Avatar.propTypes = {
  avatar: object,
  setProfileAvatar: func,
  setCurrentUserAvatar: func,
  removeAvatar: func,
  openAlertify: func
};

const mapStateToProps = ({ profile: { avatar } }) => (
  {
    avatar: avatar
  }
);

const mapDispatchToProps = {
  setProfileAvatar: profileActions.setData,
  setCurrentUserAvatar: currentUserActions.setData,
  removeAvatar: profileActions.removeAvatar,
  openAlertify: alertifyActions.openAlertify
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
