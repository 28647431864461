import React, { forwardRef, useState, useEffect } from 'react';
import { bool, func, number } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Media from 'react-media';

import Slide from '@material-ui/core/Slide';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Divider from '@material-ui/core/Divider';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import css from './styles.module.scss';
import cssVariables from '../../../css/variables.scss';
import General from "./general";
import SocialAccounts from './social-accounts';
import Notifications from './notifications';
import { TABLET_LANDSCAPE_MAX } from '../../../constants';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TABS = [<General />, <SocialAccounts />, <Notifications />];

const Settings = (props) => {
  const { open, onClose, defaultTab } = props;
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(defaultTab);
  }, [defaultTab]);

  const handleChange = (event, index) => {
    setTabIndex(index);
  };

  const renderTabs = (orientation) => {
    if (orientation === 'vertical') {
      return(
        [
          <Tab key={0} label={t('profile.info.settings.tabs.0')} />,
          <Tab key={1} label={t('profile.info.settings.tabs.1')} />,
          <Tab key={2} label={t('profile.info.settings.tabs.2')} />
        ]
      )
    }

    const iconStyle = { color: cssVariables.grey_primary };

    return(
      [
        <Tab key={0} icon={<AccountCircleIcon style={iconStyle} />} />,
        <Tab key={1} icon={<SupervisorAccountIcon style={iconStyle} />} />,
        <Tab key={2} icon={<NotificationsIcon style={iconStyle} />} />
      ]
    )
  };

  return (
    <Dialog
      disableEnforceFocus
      className={css.settings}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >

      <DialogTitle onClose={onClose}>
        { t(`profile.info.settings.tabs.${tabIndex}`) }
      </DialogTitle>

      <Divider />

      <Media query={{ maxWidth: TABLET_LANDSCAPE_MAX }}>
        { (matches) => {
          const orientation = matches ? 'horizontal' : 'vertical';

          return (
            <div className={css.content}>
              <Tabs
                orientation={orientation}
                variant={matches ? 'fullWidth' : 'scrollable'}
                value={tabIndex}
                onChange={handleChange}
                className={css.tabs}
                indicatorColor="primary"
                textColor="primary"
              >
                {renderTabs(orientation)}
              </Tabs>

              <div className={css.tab_content}>
                { TABS[tabIndex] }
              </div>
            </div>
          );
        }}
      </Media>
    </Dialog>
  )
};

Settings.propTypes = {
  open: bool,
  onClose: func,
  defaultTab: number.isRequired
};

export default Settings;
