import React, { Fragment, useState, useEffect } from 'react';
import { bool, number, func, string, array, object } from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import queryString from 'querystring';
import { Helmet } from "react-helmet";

import DateToString from 'alp-shared-components/dist/shared/date-to-string';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Settings from "./settings";
import Main from './main';
import css from './styles.module.scss';
import { profileActions } from "../actions";
import Connections from "./connections";

import Section from "./section";
import { menuActions } from '../../shared/menu/actions';
import { sections } from './constants';
import { queryActions } from '../../media-items/query/actions';
import Achievements from "./achievements";
import ProfileSocialAccounts from "./profile-social-accounts";
import CurrentSubscriptionInfo from 'alp-shared-components/dist/monetization/current-subscription-info';
import DeleteAccount from './delete-account';

const Info = (props) =>  {
  const {
    isYourProfile,
    toggleFollow,
    fetchSubscriptions,
    history,
    setProducer,
    profile,
    monetizationSubscription
  } = props;

  const {
    id,
    is_followed,
    full_name,
    avatar,
    slug,
    about,
    created_at,
    profile_social_accounts,
    profile_email_notification_setting
  } = profile;

  const { t, i18n } = useTranslation();
  const [openedSettings, setOpenedSettings] = useState(false);
  const [defaultSettingsTab, setDefaultSettingsTab] = useState(0);

  useEffect(() => {
    if (profile_email_notification_setting) {
      const { location: { search } } = history;
      const section = queryString.parse(search)['?section'];
      const tabIndex = sections[section];

      if (tabIndex) {
        openSettingsWithTab(tabIndex);
      }
    }
  }, [profile_email_notification_setting, history]);

  const openSettings = () => {
    setOpenedSettings(true);
  };

  const openSettingsWithTab = (tab) => {
    setDefaultSettingsTab(tab);
    setOpenedSettings(true);
  };

  const closeSettings = () => {
    const { location: { pathname } } = history;

    setOpenedSettings(false);
    setDefaultSettingsTab(0);
    history.replace(pathname);
  };

  const handleToggleFollow = () => {
    toggleFollow(id).then(() => fetchSubscriptions())
  };

  const renderActionButton = () => {
    if (isYourProfile) {
      return(
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={openSettings}
        >
          { t('profile.info.settingsButtonTile') }
        </Button>
      )
    }

    return (
      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleToggleFollow}
      >
        { is_followed ? t('profile.info.unFollowButtonTitle') : t('profile.info.followButtonTitle') }
      </Button>
    )
  };

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - ${profile.full_name}`;

    const aboutRegex = /(<([^>]+)>)/ig;
    const description = (about && about.replace(aboutRegex, '')) || full_name;

    const image = avatar && avatar.large;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/profiles/${slug}`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  };

  const renderMonetizationSubscription = () => {
    if(isYourProfile && monetizationSubscription) {
      return (
        <Fragment>
          <ListItem>
            <ListItemText
              primary={t('profile.info.sections.subscription')}
              secondary={(
                <CurrentSubscriptionInfo
                  info={monetizationSubscription}
                />
              )}
            />
          </ListItem>
          <Divider variant="middle" />
        </Fragment>
      )
    }

    return null;
  }

  return (
    <div className={css.info}>
      <div className={css.main}>
        <Main />
      </div>

      { renderActionButton() }

      <Connections />

      <Divider />

      <List disablePadding>
        <Achievements
          history={history}
          setProducer={setProducer}
          profile={profile}
          producedContentCount={profile.published_mediaitems_count}
        />

        <Divider variant="middle" />

        { renderMonetizationSubscription() }

        <ProfileSocialAccounts
          openSettingsWithTab={openSettingsWithTab}
          profile_social_accounts={profile_social_accounts}
        />

        <Divider variant="middle" />

        <Section onClick={openSettingsWithTab} tab={0} header={t('profile.info.sections.about')}>
          <span dangerouslySetInnerHTML={{ __html: about }} />
        </Section>

        <Divider variant="middle" />

        <ListItem>
          <ListItemText
            secondary={
              <span>
                {t('profile.info.sections.joined', { projectName: process.env.REACT_APP_PROJECT_NAME })}:
                {' '}
                <DateToString
                  languageCode={i18n.language}
                  format="MMMM DD, YYYY"
                >
                  { created_at }
                </DateToString>
              </span>
            }
          />
        </ListItem>

        { isYourProfile && (
          <Fragment>
            <Divider />
            <DeleteAccount history={history} />
          </Fragment>
        ) }
      </List>

      { isYourProfile && <Settings open={openedSettings} onClose={closeSettings} defaultTab={defaultSettingsTab} /> }

      { renderMetaTags() }
    </div>
  );

};

Info.propTypes = {
  isYourProfile: bool,
  isFollowed: bool,
  profileId: number,
  toggleFollow: func.isRequired,
  about: string,
  createdAt: string,
  profileSocialAccounts: array,
  fetchSubscriptions: func.isRequired,
  profileEmailNotificationSetting: object,
  history: object.isRequired
};

Info.defaultProps = {
  profileSocialAccounts: [],
  profileEmailNotificationSetting: null
};

const mapStateToProps = ({ profile, currentUser }) => (
  {
    isYourProfile: profile.id === currentUser.id,
    profile: profile,
    monetizationSubscription: currentUser.monetization_subscription
  }
);

const mapDispatchToProps = {
  toggleFollow: profileActions.toggleFollow,
  fetchSubscriptions: menuActions.fetchSubscriptions,
  setProducer: queryActions.setProducer
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Info)
);
