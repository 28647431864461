const ACTION_TYPE_PREFIX = 'LANDING';

const ACTION_TYPES = {
  HIDE_LANDING: `${ACTION_TYPE_PREFIX}_HIDE_LANDING`
};

const hideLanding = () => ({
  type: ACTION_TYPES.HIDE_LANDING
});

const landingActions = {
  hideLanding
};

export { ACTION_TYPES, landingActions };
