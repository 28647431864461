import React from 'react';
import { string, func, array } from 'prop-types';

import { useTranslation } from 'react-i18next';

import Popover from '../../../src/ui/modals/popover';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';

import clsx from 'clsx';

import css from './styles.module.scss';

const orders = [
  'created_at desc', // Most Recent
  'created_at asc' // Most Old
];

const Order = (props) => {
  const {
    queryOrder,
    onChange,
    ordersList
  } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const isOrderInQuery = (order) => {
    return order === queryOrder
  };

  const clickOnOrder = (order) => () => {
    setAnchorEl(null);
    onChange(order)
  };

  const renderOrders = () => {
    return (
      <List component="div" disablePadding className={css.list}>
        { (ordersList || orders).map((order) => (
          <ListItem
            key={order}
            button
            onClick={clickOnOrder(order)}
          >
            <ListItemText
              primary={t(`orderList.${order}`)}
            />
            { isOrderInQuery(order) && <CheckIcon color="primary" /> }
          </ListItem>
        ))}
      </List>
    )
  };

  return(
    <div className={css.order}>
      <div onClick={openList} className={css.anchor}>
        <FilterListIcon color="primary" />
        <Typography color="textPrimary" className={clsx(css.title)}>
          {t(`orderList.${queryOrder}`)}
        </Typography>
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disablePortal={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { renderOrders() }
      </Popover>
    </div>
  )
};

Order.propTypes = {
  queryOrder: string.isRequired, // from props
  onChange: func.isRequired, // from props
  ordersList: array
};

export default Order;
