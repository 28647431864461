import React, { Fragment } from 'react';
import { string, object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { PasswordField } from '../inputs';

import Form from '../form';
import { currentUserActions } from '../../../current-user/actions';

const ResetPasswordForm = (props) => {
  const {
    user,
    token,
    changePassword,
    onSuccess
  } = props;

  const { t } = useTranslation();

  const handleResetPassword = ({ password }, actions) => {
    const user = {
      password,
      reset_password_token: token
    };

    changePassword(user).then(() => {
      onSuccess();
    }, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
    })
  };

  return (
    <Fragment>
      <Form
        initialValues={user}
        onSubmit={handleResetPassword}
        submitButtonText={t('authentication.resetPassword.submitButtonText')}
      >
        <Field component={PasswordField} name="password" type="password" />
      </Form>
    </Fragment>
  );
};

ResetPasswordForm.propTypes = {
  user: object,
  token: string, // from props
  changePassword: func.isRequired, // from currentUserActions
  onSuccess: func.isRequired // from props
};

ResetPasswordForm.defaultProps = {
  user: {
    password: ''
  },
  token: ''
};

const mapDispatchToProps = {
  changePassword: currentUserActions.changePassword
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm)
