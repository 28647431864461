import React, { useEffect, useState, Fragment } from 'react';
import { bool, func, object, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Subscription from 'alp-shared-components/dist/monetization/subscription';
import Coupon from 'alp-shared-components/dist/monetization/coupon';
import CurrentSubscriptionInfo from 'alp-shared-components/dist/monetization/current-subscription-info';

import { connect } from 'react-redux';
import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import withMonetizationApi from '../../../api/context-monetization';
import { withMonetization } from '../provider';
import { currentUserActions } from "../../../current-user/actions";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const SubscriptionModal = (props) => {
  const {
    currentUserId,
    fetchCurrentUser,
    openSubscriptionDialog,
    setOpenSubscriptionDialog,
    monetizationApi,
    monetizationSubscription
  } = props;

  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState([]);
  const [checkedCoupon, setCheckedCoupon] = useState();

  useEffect(() => {
    if (currentUserId) {
      monetizationApi.fetchSubscriptions().then(setSubscriptions);
    }
  }, [currentUserId]);

  const checkCoupon = (couponName) => {

    return monetizationApi.checkCoupon(couponName).then((data) => {
      setCheckedCoupon(data.coupon)
      setSubscriptions(data.coupon.subscriptions)
    });
  }

  const onClose = () => {
    setOpenSubscriptionDialog(false);
  };

  const onApprove = (selectedSubscription, data) => (
    monetizationApi.createPayPalSubscription(selectedSubscription, data, checkedCoupon).then(() => {
      fetchCurrentUser();
      onClose();
    })
  );

  const onFreeApprove = (selectedSubscription) => (
    monetizationApi.createFreeSubscription(selectedSubscription, checkedCoupon).then(() => {
      fetchCurrentUser();
      onClose();
    })
  );

  const renderContent = () => {
    if (currentUserId) {
      if (monetizationSubscription) {
        return (
          <CurrentSubscriptionInfo
            info={monetizationSubscription}
          />
        )
      }

      return subscriptions.length > 0 && (
        <Fragment>
          <Coupon checkCoupon={checkCoupon} checkedCoupon={checkedCoupon} />
          <Subscription
            subscriptions={subscriptions}
            onApprove={onApprove}
            onFreeApprove={onFreeApprove}
          />
        </Fragment>
      );
    }

    return t('landing.monetization.subscriptionModal.pleaseLogin');
  };

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openSubscriptionDialog}
    >
      <DialogTitle onClose={onClose}>
        { t('landing.monetization.subscriptionModal.title') }
      </DialogTitle>
      <DialogContent dividers>
        { renderContent() }
      </DialogContent>
    </Dialog>
  );
};

SubscriptionModal.propTypes = {
  openSubscriptionDialog: bool,
  setOpenSubscriptionDialog: func,
  monetizationApi: object,
  currentUserId: number,
  fetchCurrentUser: func
};

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  fetchCurrentUser: currentUserActions.fetch
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withMonetizationApi(
    withMonetization(SubscriptionModal)
  )
);
