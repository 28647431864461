import React from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Link as RouterLink } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { mediaPath } from '../../../media-item/utils';
import css from './styles.module.scss';
import Tile from "./tile";
import MetaInfo from "./meta-info";

const TextGrid = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16
  }
}))(Grid);

const Item = (props) => {
  const { mediaItem, openWordListsDialog } = props;
  const { t } = useTranslation();

  return (
    <ListItem key={mediaItem.id} alignItems="flex-start" divider disableGutters>
      <Grid container justify={'center'}>
        <Grid item xs={12} sm={4}>
          <Tile mediaItem={mediaItem} openWordListsDialog={openWordListsDialog} />
        </Grid>
        <TextGrid item xs={12} sm={8}>
          <ListItemText
            primary={mediaItem.title}
            primaryTypographyProps={{
              variant: 'h6',
              component: "h3",
              color: "textPrimary"
            }}
            secondaryTypographyProps={{
              variant: 'body1',
              component: "h4"
            }}
            secondary={
              <React.Fragment>
                <Typography paragraph variant="subtitle2">
                  { t(`levelsList.${mediaItem.level}`) }
                </Typography>
                {mediaItem.short_description}
              </React.Fragment>
            }
          />
          { mediaItem.format_price &&  (
            <Button disabled={mediaItem.is_paid} color="primary" component={RouterLink} to={mediaPath(mediaItem.id)}>
              { t('group.item.buyGroup.buttonText', { formatPrice: mediaItem.format_price }) }
            </Button>
          )}
          <div className={css.mediaItemMetaInfo}>
            <MetaInfo mediaItem={mediaItem} />
          </div>
        </TextGrid>
      </Grid>
    </ListItem>
  )
}

Item.propTypes = {
  mediaItem: object,
  openWordListsDialog: func
}

export default Item;
