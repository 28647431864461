const chartDataModel = [
  { id: "0.0", parent: "", name: "Words" },

  { id: "1.0", parent: "0.0", name: "Verbs", color: "#85bff2", wtype: 'verb' },
  { id: "1.1", parent: "0.0", name: "Adjectives", color: "#f2d750", wtype: 'adj' },
  { id: "1.2", parent: "0.0", name: "Pronouns", color: "#3066BE", wtype: 'pron' },
  { id: "1.3", parent: "0.0", name: "Adverbs", color: "#022859", wtype: 'adv' },
  { id: "1.4", parent: "0.0", name: "Prepositions", color: "#84B082", wtype: 'prep' },
  { id: "1.5", parent: "0.0", name: "Nouns", color: "#f2ae2e", wtype: 'noun' },
  { id: "1.6", parent: "0.0", name: "Adjective Pronouns", color: "#C32F27", wtype: 'adjpron' },
  { id: "1.7", parent: "0.0", name: "Ordinals", color: "#82AC9F", wtype: 'ord' },
  { id: "1.8", parent: "0.0", name: "Cardinals", color: "#94778B", wtype: 'card' },
  { id: "1.9", parent: "0.0", name: "Сonjunctions", color: "#3b0e39", wtype: 'conj' },
  { id: "1.10", parent: "0.0", name: "Particles", color: "#ac8f3b", wtype: 'part' },
  { id: "1.11", parent: "0.0", name: "Pronominal Adverbs", color: "#3a5e1d", wtype: 'advpron' },
  { id: "1.12", parent: "0.0", name: "Proper Names", color: "#e2f3ed", wtype: 'sprop' },
  { id: "1.13", parent: "0.0", name: "Interjections", color: "#c27fc2", wtype: 'intj' },
  // { id: "1.10", parent: "0.0", name: "Numerals" },
  // { id: "1.11", parent: "0.0", name: "Conjunctions" },

  { id: "2.1", parent: "1.0", name: "Known", key: "verb", value: 0 },
  { id: "2.2", parent: "1.0", name: "Unknown", key: "uverb", value: 0 },
  { id: "2.3", parent: "1.1", name: "Known", key: "adj", value: 0 },
  { id: "2.4", parent: "1.1", name: "Unknown", key: "uadj", value: 0 },
  { id: "2.5", parent: "1.2", name: "Known", key: "pron", value: 0 },
  { id: "2.6", parent: "1.2", name: "Unknown", key: "upron", value: 0 },
  { id: "2.7", parent: "1.3", name: "Known", key: "adv", value: 0 },
  { id: "2.8", parent: "1.3", name: "Unknown", key: "uadv", value: 0 },
  { id: "2.9", parent: "1.4", name: "Known", key: "prep", value: 0 },
  { id: "2.10", parent: "1.4", name: "Unknown", key: "uprep", value: 0 },
  { id: "2.11", parent: "1.5", name: "Known", key: "noun", value: 0 },
  { id: "2.12", parent: "1.5", name: "Unknown", key: "unoun", value: 0 },
  { id: "2.13", parent: "1.6", name: "Known", key: "adjpron", value: 0 },
  { id: "2.14", parent: "1.6", name: "Unknown", key: "uadjpron", value: 0 },
  { id: "2.15", parent: "1.7", name: "Known", key: "ord", value: 0 },
  { id: "2.16", parent: "1.7", name: "Unknown", key: "uord", value: 0 },
  { id: "2.17", parent: "1.8", name: "Known", key: "card", value: 0 },
  { id: "2.18", parent: "1.8", name: "Unknown", key: "ucard", value: 0 },

  { id: "2.19", parent: "1.9", name: "Known", key: "conj", value: 0 },
  { id: "2.20", parent: "1.9", name: "Unknown", key: "uconj", value: 0 },
  { id: "2.21", parent: "1.10", name: "Known", key: "part", value: 0 },
  { id: "2.22", parent: "1.10", name: "Unknown", key: "upart", value: 0 },
  { id: "2.23", parent: "1.11", name: "Known", key: "advpron", value: 0 },
  { id: "2.24", parent: "1.11", name: "Unknown", key: "uadvpron", value: 0 },
  { id: "2.25", parent: "1.12", name: "Known", key: "sprop", value: 0 },
  { id: "2.26", parent: "1.12", name: "Unknown", key: "usprop", value: 0 },
  { id: "2.27", parent: "1.13", name: "Known", key: "intj", value: 0 },
  { id: "2.28", parent: "1.13", name: "Unknown", key: "uintj", value: 0 },

  // { id: "2.21", parent: "1.10", name: "Know", value: 0 },
  // { id: "2.22", parent: "1.10", name: "UnKnow", value: 0 },
  // { id: "2.23", parent: "1.11", name: "Know", value: 0 },
  // { id: "2.24", parent: "1.11", name: "UnKnow", value: 0 },
];

const WTYPES = ['verb', 'noun', 'adj', 'prep', 'conj', 'part', 'pron', 'adv', 'adjpron', 'advpron', 'ord', 'card', 'sprop', 'intj'];
const SELECTED_WTYPES = ['verb', 'noun']

export { WTYPES, SELECTED_WTYPES }

export default chartDataModel;
