import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';

import { withRouter } from 'react-router';

import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import Tooltip from '@material-ui/core/Tooltip';
import MUAvatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from'@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { compose } from 'redux';

import clsx from 'clsx';

import { connect } from 'react-redux';
import { filesActions } from '../../actions';

import css from './styles.module.scss';
import Svg from '../../../../ui/svg';
import Menu from '../../../../ui/menu';

import { folderShareLink } from '../../../utils';
import CopyLink from '../menu/items/copy-link';
import Share from '../menu/items/share';
import Rename from '../menu/items/rename';
import Delete from '../menu/items/delete';
import Save from '../menu/items/save';
import Remove from '../menu/items/remove';
import withFolderActions from '../../with-folder-actions';

import { goToProfile } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  folder: {
    backgroundColor: theme.palette.background.default
  }
}));

const Avatar = withStyles((theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}))(MUAvatar);

const Folder = (props) => {
  const {
    folder,
    setFolders,
    addBreadCrumb,

    closeMenu,
    openMenu,
    menuAnchorEl,

    openEditFolderModal,
    openShareModal,
    onSaveFolder,
    onRemoveFolder,
    onDeleteFolder,
    history,

    isYourProfile
  } = props;

  const { t } = useTranslation();
  const styles = useStyles();

  const onClickFolder = () => {
    setFolders(folder.children);
    addBreadCrumb(folder);
  };

  const onOpenMenu = (event) => {
    event.stopPropagation();
    openMenu(event);
  };

  const onOpenEditFolderModal = () => {
    openEditFolderModal(folder)
  };

  const handleOnDeleteFolder = () => {
    onDeleteFolder(folder)
  };

  const handleOnSaveFolder = () => {
    onSaveFolder(folder)
  };

  const handleOnRemoveFolder = () => {
    onRemoveFolder(folder)
  };

  const handleOpenShareModal = () => {
    openShareModal(folder);
  };

  const menuItems = () => {
    const items = [];

    items.push(
      <CopyLink
        key="copy-link"
        copiedText={folderShareLink(folder)}
        onCopy={closeMenu}
      />
    );
    items.push(
      <Share
        key="share"
        onClick={handleOpenShareModal}
      />
    );
    items.push(
      <Rename
        key="rename"
        visible={folder.owned && isYourProfile}
        onClick={onOpenEditFolderModal}
      />
    );
    items.push(
      <Delete
        key="delete"
        visible={folder.owned && isYourProfile}
        onClick={handleOnDeleteFolder}
      />
    );
    items.push(
      <Save
        key="save"
        visible={!folder.saved && !folder.owned}
        onClick={handleOnSaveFolder}
      />
    );
    items.push(
      <Remove
        key="remove"
        visible={folder.saved && !folder.owned}
        onClick={handleOnRemoveFolder}
      />
    );

    return items;
  };

  const clickOnAvatar = (profileId) => (e) => {
    e.stopPropagation();

    goToProfile(history, profileId);
  };

  const renderAvatar = () => {
    return (
      <Tooltip title={folder.owner.full_name}>
        <div className={css.avatar}>
          <Avatar
            src={folder.owner.avatar.small}
            onClick={clickOnAvatar(folder.owner.slug)}
          />
        </div>
      </Tooltip>
    )
  };

  const legend = () => {
    const { folder } = props;

    return t('profile.files.folder.legend',
      {
        foldersCount: folder.children.length,
        itemsCount: folder.media_items_count + folder.groups_count
      }
    )
  };

  const renderActions = () => {
    return (
      <Fragment>
        <div className={css.icon}>
          <FolderIcon style={{ fontSize: 48 }} color="primary"/>
        </div>

        <div className={clsx(css.icon, css.icon_with_menu)} onClick={onOpenMenu}>
          <Svg name="icFolderMenu" css={css.svg} />
        </div>
      </Fragment>
    )
  };

  return (
    <div className={clsx('folder', css.folder, styles.folder)}>
      <div
        className={css.wrapper}
        onClick={onClickFolder}
      >
        { renderAvatar() }
        { renderActions() }

        <div className={css.legend}>
          <Typography noWrap color="textPrimary">
            { folder.name }
          </Typography>
          <Typography noWrap color="textSecondary">
            { legend() }
          </Typography>
        </div>

      </div>

      <Menu anchorEl={menuAnchorEl} onClose={closeMenu}>
        { menuItems() }
      </Menu>
    </div>
  );
};

Folder.propTypes = {
  folder: object.isRequired, // from props
  setFolders: func.isRequired, // from filesActions
  addBreadCrumb: func.isRequired, // from filesActions

  openEditFolderModal: func.isRequired, // from withFolderActions
  openShareModal: func.isRequired, // from withFolderActions
  onSaveFolder: func.isRequired, // from withFolderActions
  onRemoveFolder: func.isRequired, // from withFolderActions
  onDeleteFolder: func.isRequired, // from withFolderActions

  openMenu: func.isRequired, // from withFolderActions
  closeMenu: func.isRequired, // from withFolderActions
  menuAnchorEl: object, // from withFolderActions
  history: object.isRequired, // from withRouter

  isYourProfile: bool.isRequired // from mapStateToProps
};

Folder.defaultProps = {
  menuAnchorEl: null
};

const mapStateToProps = ({ profile, currentUser }) => (
  {
    isYourProfile: profile.id === currentUser.id
  }
);

const mapDispatchToProps = {
  setFolders: filesActions.setFolders,
  addBreadCrumb: filesActions.addBreadCrumb
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFolderActions
)(Folder);

