import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Popover from '../../../../src/ui/modals/popover';

import clsx from 'clsx';

import includes from 'lodash/includes';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import css from './styles.module.scss';
import cssText from '../../../css/text.module.scss';

import { LEVELS_LIST } from '../../query/constants';

const MobileLevels = (props) => {
  const {
    queryLevels,
    onChangeLevel
  } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const openList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderLevels = () => {
    return (
      <List component="div" disablePadding className={css.list}>
        { LEVELS_LIST.map((level) => (
          <ListItem
            key={level}
            button
            className={css.list_item}
          >
            <FormControlLabel
              key={level}
              className={css.label}
              control={
                <Checkbox
                  checked={includes(queryLevels, level)}
                  onChange={onChangeLevel(level)}
                  value={level}
                  color="primary"
                />
              }
              label={t(`levelsList.${level}`)}
            />
          </ListItem>
        ))}
      </List>
    )
  };

  return(
    <div className={css.mobile_levels}>
      <div onClick={openList} className={css.anchor}>
        <div className={clsx(css.title, cssText.lato_bold, cssText.paragraph_1, cssText.cl_black_dark)}>
          {t('level')}
        </div>
        <ExpandMoreIcon color="primary" />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        { renderLevels() }
      </Popover>
    </div>
  );
};

MobileLevels.propTypes = {
  queryLevels: PropTypes.array.isRequired, // from mediaItems.query state
  onChangeLevel: PropTypes.func.isRequired // from props
};

const mapStateToProps = ({ mediaItems: { query } }) => (
  {
    queryLevels: query.levels
  }
);

export default connect(mapStateToProps)(MobileLevels);
