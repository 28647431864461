import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { authenticationModalActions } from '../shared/authenticationModal/actions';
import { currentUserActions } from "../current-user/actions";
import withIframeActions from '../media-item/iframe-provider';
import { headerActions } from "../shared/header/actions";
import { DIRECTIONS } from 'alp-shared-components/dist/shared/hooks/useRefSwipes';

const clientVersion = process.env.REACT_APP_CLIENT_VERSION

class ITLFrame extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    onMessage: PropTypes.func
  };

  static defaultProps = {
    onMessage: () => {}
  };

  componentDidMount() {
    const { setSendMessageToIframe } = this.props;
    setSendMessageToIframe(this.sendMessage)

    this.initUILanguageProvider();
  }

  componentWillUnmount() {
    const { setSendMessageToIframe } = this.props;
    setSendMessageToIframe(null);

    window.removeEventListener('message', this.onMessage);
  }

  initUILanguageProvider() {
    const { i18n } = this.props;

    i18n.on('languageChanged', (languageCode) => {
      this.sendMessage({
        type: 'changeUILanguage',
        payload: languageCode
      });
    });
  }

  sendMessage = (message) => {
    this.itlWindow.postMessage(message, process.env.REACT_APP_ITL_ORIGIN);
  };

  setup = (frameElement) => {
    if (!frameElement) {
      return;
    }
    this.itlWindow = frameElement.contentWindow;
    window.addEventListener('message', this.onMessage);
  };

  login(user) {
    const { setCurrentUser } = this.props;
    setCurrentUser(user);
  }

  logout() {
    const { clearCurrentUser } = this.props;
    clearCurrentUser();
  }

  fetchCurrentUser() {
    const { fetchCurrentUser } = this.props;
    fetchCurrentUser();
  }

  requestUser() {
    const { currentUser, login, history } = this.props;
    if (currentUser.id) {
      history.push(`/profiles/${currentUser.slug}`);
    } else {
      login();
    }
  }

  navigate(page) {
    const { history } = this.props;
    history.push(page);
  }

  changeUILanguage(languageCode) {
    const { i18n } = this.props;
    i18n.changeLanguage(languageCode)
  }

  toggleHeader(direction) {
    const { setIsVisibleHeader } = this.props;

    if (direction === DIRECTIONS.top) {
      setIsVisibleHeader(false)
    }
    if (direction === DIRECTIONS.bottom) {
      setIsVisibleHeader(true)
    }
  }

  initIFrame() {
    const { i18n } = this.props;

    this.sendMessage({
      type: 'changeUILanguage',
      payload: i18n.language
    });

    this.sendMessage({
      type: 'clientVersion',
      payload: clientVersion
    });
  }

  onMessage = (event) => {
    const { origin, data } = event;
    if (origin !== process.env.REACT_APP_ITL_ORIGIN) {
      return;
    }
    switch (data.type) {
      case 'fetchCurrentUser': return this.fetchCurrentUser()
      case 'login': return this.login(data.payload);
      case 'logout': return this.logout();
      case 'requestUser': return this.requestUser();
      case 'navigate': return this.navigate(data.payload);
      case 'initIFrame': return this.initIFrame();
      case 'changeUILanguage': return this.changeUILanguage(data.payload);
      case 'swipeOnPlayer': return this.toggleHeader(data.payload)
      default: {
        const { onMessage } = this.props;
        const { type, payload } = data;
        const { sendMessage } = this;
        return onMessage(type, payload, { sendMessage });
      }
    }
  };

  render() {
    const {
      src,
      currentUser, login, staticContext, // omitting props that are used in other methods
      history, location, match, // omitting props that are added by router
      ...iframeElementProps
    } = this.props;

    if (!src.startsWith(process.env.REACT_APP_ITL_ORIGIN)) {
      return null;
    }

    return (
      <iframe
        ref={this.setup}
        src={src}
        allow="fullscreen"
        allowFullScreen
        title={src}
        data-hj-allow-iframe=""
        {...iframeElementProps}
      />
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

const mapDispatchToProps = {
  login: authenticationModalActions.openAuthenticationModal,
  fetchCurrentUser: currentUserActions.fetch,
  setCurrentUser: currentUserActions.setData,
  clearCurrentUser: currentUserActions.clear,
  setIsVisibleHeader: headerActions.setIsVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withTranslation()(withIframeActions(ITLFrame)))
);
