import { actions } from './actions';

const initialState = {
  open: false,
  reason: null
};

const authenticationModal = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_AUTHENTICATION_MODAL:
      return {
        ...state,
        open: true,
        reason: action.payload
      };
    case actions.CLOSE_AUTHENTICATION_MODAL:
      return {
        ...state,
        open: false,
        reason: null
      };
    default:
      return state;
  }
};

export default authenticationModal;
