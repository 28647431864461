import React, { useState } from 'react';
import { object, number, func } from 'prop-types';

import { connect } from 'react-redux';

import ShareActions from "alp-shared-components/dist/item/share-actions";
import { TYPES } from "alp-shared-components/dist/item/share-actions/constants";
import FoldersTree from "alp-shared-components/dist/folders-tree";

import css from './styles.module.scss';
import {alertifyActions} from "../shared/alertify/actions";
import withLearnerDataAPI from "../api/context-learner-data";
import withApi from '../api/context';
import ahoy from "../setup_ahoy";
import MediaTags from "./media-tags";
import LazyPicture from "../shared/lazy-picture";

const Tile = (props) => {
  const { group, learnerDataApi, api, currentUserId, openAlertify } = props;

  const [copiedGroup, setCopiedGroup] = useState(null);

  const openFoldersTree = () => {
    setCopiedGroup(group);
  };

  const closeFoldersTree = () => {
    setCopiedGroup(null);
  };

  const onClickShareButton = (socialProvider) => {
    ahoy().track('click_share_button', { social_provider: socialProvider, source: 'group', place: 'group' });
  }

  const successAddGroupToFolder = (successMessage, folder, data) => {
    openAlertify({ type: 'success', text: successMessage });
  }

  return (
    <div className={css.tile}>
      <LazyPicture alt={group.title} src={group.image_large_url} webpSrc={group.webp_image_large_url} />

      <div className={css.mediaTags}>
        <MediaTags group={group} />
      </div>

      <ShareActions
        item={group}
        type={TYPES.group}
        openFoldersTree={openFoldersTree}
        className={css.actions}
        onClickShareButton={onClickShareButton}
      />

      { copiedGroup && (
        <FoldersTree
          currentUser={{ id: currentUserId }}
          item={group}
          type={TYPES.group}
          closeFoldersTree={closeFoldersTree}
          fetchFolders={api.fetchGroupFoldersTree}
          createFolder={api.createFolder}
          addItemToFolder={api.addGroupToFolder}
          successAddItemToFolder={successAddGroupToFolder}
          removeItemFromFolder={api.removeGroupFromFolder}
        />
      )}
    </div>
  )
}

Tile.propTypes = {
  group: object,
  learnerDataApi: object,
  currentUserId: number,
  openAlertify: func
}

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify
};

export default connect(mapStateToProps, mapDispatchToProps)(withApi(withLearnerDataAPI(Tile)))
