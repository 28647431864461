import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import queryString from 'querystring';

import { number } from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Media from 'react-media';
import { Helmet } from "react-helmet";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';

import { menuActions } from "../shared/menu/actions";

import css from './styles.module.scss';

import withAPI from '../api/context';

import Empty from '../shared/empty-content';
import Tile from './tile';

const Posts = (props) => {
  const { setMenuVariant, closeMenu, api, per_page, location: { search } } = props;

  const category = queryString.parse(search.replace(/^\?/, '')).category;

  const { t } = useTranslation();

  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    fetchPosts()
  }, [page, category]);

  const fetchPosts = () => {
    setPosts([]);

    const params = {
      page,
      per_page
    }

    if (category) {
      params['q'] = { blog_category_slug_eq: category }
    }

    api.fetchBlogPosts(params).then((data) => {
      setHasMore(data.length >= per_page)
      page === 1 ? setPosts(data) : setPosts([...data, ...posts]);
    });
  }

  const renderTitle = () => {
    if (!category) {
      return null;
    }

    const categoryName = posts[0].category_name;

    return (
      <Typography color="textPrimary" component="h1" variant="h3" gutterBottom>{categoryName}</Typography>
    )
  }

  const renderPosts = () => {
    if (isEmpty(posts)) {
      return <Empty />;
    }

    return (
      <Fragment>
        { renderTitle() }
        <Grid container spacing={3}>
          { posts.map((post) =>
            <Grid key={post.id} item xs={12} sm={6} md={6}>
              <Tile post={post} />
            </Grid>
          )}
        </Grid>
      </Fragment>
    )
  }

  const onChangeMedia = (matchMedia) => {
    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  const incrementPage = () => {
    setPage((page) => page + 1)
  }

  const renderMetaTags = () => {
    const categotyName = posts[0] && posts[0].category_name;

    let title;
    let description;

    if (category && categotyName) {
      title = `${process.env.REACT_APP_PROJECT_NAME} | ${categotyName}`
      description = `User's Blog | ${categotyName}`;
    } else {
      title = `${process.env.REACT_APP_PROJECT_NAME} - Blog`
      description = `User's Blog`;
    }

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/blog`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  return (
    <Media
      query={{ minWidth: 1400 }}
      onChange={onChangeMedia}
    >
      {() =>
        <div className={css.posts}>
          { renderMetaTags() }
          <div className={css.items}>
              {renderPosts()}
          </div>

          { hasMore && (
            <div className={css.nextButton}>
              <Button tabIndex="disabled" component="div" onClick={incrementPage} variant="outlined" color="primary">
                { t('blog.posts.showMore') }
              </Button>
            </div>
          )}

        </div>
      }
    </Media>
  )
}

Posts.propTypes = {
  per_page: number
}

Posts.defaultProps = {
  per_page: 60
}

const mapStateToProps = ({ feedbacks, feedbacks: { query } }) => (
  {
    queryOrder: query.order,
    feedbacks: feedbacks.data,
    hasMore: feedbacks.hasMore,
  }
);

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu
};

export default withAPI(
  connect(mapStateToProps, mapDispatchToProps)(Posts)
)
