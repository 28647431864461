import React from 'react';
import { object } from 'prop-types';

import css from './styles.module.scss';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';

import Title from './title';
import CreatedTime from './created-time';
import Body from "./body";
import Creator from "./creator";
import CommentsCount from './comments_count';
import Icon from "./icon";

const Item = (props) => {
  const { feedback } = props;

  return (
    <Card className={css.item}>
      <CardHeader
        avatar={<Icon kind={feedback.kind} />}
        title={<Title feedback={feedback} />}
        subheader={<CreatedTime createdAt={feedback.created_at} />}
      />
      <CardContent>
        <Body feedback={feedback} />
      </CardContent>

      <CardActions disableSpacing>
        <Grid container alignItems="center">
          <Creator feedback={feedback} />
          <CommentsCount feedback={feedback} />
        </Grid>
      </CardActions>
    </Card>
  )
};

Item.propTypes = {
  feedback: object // from props
};

export default Item;
