import React, { Fragment } from 'react';
import { object } from 'prop-types';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import DateToString from 'alp-shared-components/dist/shared/date-to-string';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > div, & > time': {
      lineHeight: 'initial',
      marginLeft: '2px'
    }
  }
}));

const MetaInfo = (props) => {
  const { mediaItem } = props;
  const { i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.root} component="div" color="textSecondary" variant="caption">
        <PublishIcon fontSize="small" />
        <DateToString fromNow format="MMMM DD, YYYY" languageCode={i18n.language}>
          { mediaItem.published_at }
        </DateToString>
      </Typography>

      <Typography className={classes.root} component="div" color="textSecondary" variant="caption">
        <AccessTimeIcon fontSize="small" />
        <div>
          { moment.duration(mediaItem.duration, 'seconds').format("hh:mm:ss", { stopTrim: "mm" }) }
        </div>
      </Typography>
    </Fragment>
  )
}

MetaInfo.propTypes = {
  mediaItem: object
}

export default MetaInfo;
