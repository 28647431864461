import { actions } from './actions';

const initialState = {
  fetchingData: false
};

const entry = (state = initialState, action) => {
  switch (action.type) {
    case actions.START_FETCH_DATA:
      return { ...state, fetchingData: true };
    case actions.END_FETCH_DATA:
      return { ...state, fetchingData: false };
    default:
      return state;
  }
};

export default entry;
