import React, { Fragment, useEffect, useState } from 'react';
import { number, bool, func } from 'prop-types';

import { connect } from 'react-redux';

import { cloneDeep } from 'lodash';

import withAPI from '../../api/context';

import WithTheme from './with-theme';
import WithoutTheme from './without-theme';
import { entryActions } from '../../entry/actions';
import ContinueWatching from './continue-watching';

const Groups = (props) => {
  const {
    api,
    queryOrder,
    queryLevels,
    queryTheme,
    querySourceLanguage,
    queryProducer,
    querySearchValue,
    queryWithPrice,
    queryPayer,
    queryWatcher,
    perPageWithTheme,
    perPageWithoutTheme,
    fetchingData,
    startFetchData,
    endFetchData
  } = props;

  const [groups, setGroups] = useState(null);
  const [withThemeGroups, setWithThemeGroups] = useState([]);

  useEffect(() => {
    if (fetchingData) {
      return;
    }

    fetchGroups();
  }, [queryOrder, queryLevels, queryTheme, querySourceLanguage, queryProducer, querySearchValue, queryWithPrice, queryPayer, queryWatcher]);

  const params = {
    order: queryOrder,
    levels: queryLevels,
    sourceLanguage: querySourceLanguage.code,
    producerId: queryProducer.id,
    searchValue: querySearchValue,
    withPrice: queryWithPrice,
    payerId: queryPayer.id,
    watcherId: queryWatcher.id,
  }

  const fetchGroups = () => {
    const clonedParams = cloneDeep(params)

    startFetchData()

    if (queryTheme) {
      clonedParams.theme = queryTheme;
      clonedParams.page = 1;
      clonedParams.perPage = perPageWithTheme;

      api.fetchGroups(clonedParams).then((newGroups) => {
        setWithThemeGroups(newGroups);
        endFetchData();
      }, (_error) => endFetchData())
    } else {
      clonedParams.perPage = perPageWithoutTheme;
      api.fetchGroupsGroupedByTheme(clonedParams).then((groupedGroups) => {
        setGroups(groupedGroups);
        endFetchData()
      }, (_error) => endFetchData())
    }
  }

  const renderContent = () => {
    if (queryTheme) {
      return (
        <WithTheme perPage={perPageWithTheme} theme={queryTheme} withThemeGroups={withThemeGroups} queryParams={params} />
      )
    }

    return <WithoutTheme groups={groups} perPage={perPageWithoutTheme} />
  }

  return (
    <Fragment>
      <ContinueWatching params={{ theme: queryTheme, ...params }} />
      { renderContent() }
    </Fragment>
  )
}

const mapStateToProps = ({ mediaItems: { query }, entry }) => (
  {
    queryOrder: query.order,
    queryLevels: query.levels,
    queryTheme: query.theme,
    querySourceLanguage: query.sourceLanguage,
    queryProducer: query.producer,
    querySearchValue: query.searchValue,
    queryWithPrice: query.withPrice,
    queryPayer: query.payer,
    queryWatcher: query.watcher,
    fetchingData: entry.fetchingData
  }
);

const mapDispatchToProps = {
  startFetchData: entryActions.startFetchData,
  endFetchData: entryActions.endFetchData
};

Groups.propTypes = {
  perPageWithTheme: number,
  perPageWithoutTheme: number,
  fetching: bool,
  setFetching: func
}

Groups.defaultProps = {
  perPageWithTheme: 60,
  perPageWithoutTheme: 10
}

export default withAPI(connect(mapStateToProps, mapDispatchToProps)(Groups));
