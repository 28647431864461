const kinds = [
  'facebook',
  'vkontakte',
  'youtube',
  'linkedin',
  'twitter',
  'instagram',
  'odnoklassniki',
  'pinterest',
  'vimeo'
];

export { kinds };
