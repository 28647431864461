import { alertifyActions } from "../shared/alertify/actions";
import { entryActions } from "../entry/actions";

const nameSpace = 'FEEDBACK_';

const actions = {
  SET_DATA: nameSpace + 'SET_DATA',
  ADD_COMMENT: nameSpace + 'ADD_COMMENT'
};

const fetchData = (id) => (
  (dispatch, getState, { ITL }) => {
    dispatch(entryActions.startFetchData());

    return ITL.api.fetchFeedback(id).then((data) => {
      dispatch(setData(data));

      dispatch(entryActions.endFetchData());
      return data;
    }).catch(error => {
      dispatch(entryActions.endFetchData());
    });
  }
);

const createComment = (comment) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.createComment(comment).then((data) => {
      dispatch(addComment(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'feedback.comments.successMessage'
      }));
      return data;
    })
  )
);

const toggleSubscription = (id) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.toggleFeedbackSubscription(id).then((data) => {
      dispatch(setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'subscription was toggled'
      }));
      return data;
    })
  )
);

const checkSubscription = (id, email) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.checkFeedbackSubscription(id).then((data) => {
      dispatch(setData(data));

      return data;
    })
  )
);

const setData = (data) => (
  {
    type: actions.SET_DATA,
    payload: data
  }
);

const addComment = (comment) => (
  {
    type: actions.ADD_COMMENT,
    payload: comment
  }
);

const feedbackActions = {
  fetchData, createComment, toggleSubscription, checkSubscription
};

export { actions, feedbackActions };
