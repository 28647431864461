import React, { useRef, useEffect } from 'react';
import { bool, string, func } from 'prop-types';
import { connect } from 'react-redux';

import css from './styles.module.scss';
import SourceLanguageSelect from './source-language-select';
import LevelFilter from './level-filter';
import Order from '../shared/order';
import { queryActions } from './query/actions';

const orders = [
  'created_at desc', // Most Recent
  'created_at asc', // Most Old
  'watched_mediaitems_count desc' // Most Popular
];

const Filter = (props) => {
  const { queryOrder, setOrderToQuery, fetchingData } = props;

  const filterRef = useRef(null);
  const previousFetchingData = useRef(fetchingData)

  useEffect(() => {
    if (previousFetchingData.current && !fetchingData) {
      filterRef.current.scrollIntoView(
        { block: "start", inline: "nearest", behavior: 'smooth' }
      )
    }

    previousFetchingData.current = fetchingData;
  }, [fetchingData]);

  const changeOrder = (order) => {
    setOrderToQuery(order);
  };

  return (
    <div ref={filterRef} className={css.filter_order}>
      <SourceLanguageSelect />

      <div className={css.level_filter}>
        <LevelFilter/>
      </div>

      <Order
        queryOrder={queryOrder}
        onChange={changeOrder}
        ordersList={orders}
      />
    </div>
  )
}

Filter.propTypes = {
  queryOrder: string,
  fetchingData: bool,
  setOrderToQuery: func
}

const mapStateToProps = ({ mediaItems: { query }, entry }) => (
  {
    queryOrder: query.order,
    fetchingData: entry.fetchingData
  }
);

const mapDispatchToProps = {
  setOrderToQuery: queryActions.setOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
