import React from 'react';
import { object } from 'prop-types';
import { Link as RouterLink } from "react-router-dom";

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';

const CommentsCount = (props) => {
  const { feedback } = props;
  const link = `feedback/${feedback.id}`;

  return (
    <RouterLink to={link}>
      <IconButton aria-label="cart">
        <Badge showZero badgeContent={feedback.comments_count} color="primary">
          <CommentIcon color="primary" />
        </Badge>
      </IconButton>
    </RouterLink>
  )
};

CommentsCount.propTypes = {
  feedback: object // from props
};

export default CommentsCount;
