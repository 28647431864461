import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import withAPI from "../../../api/context";
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';

import css from './styles.module.scss';

const BlogLinks = (props) => {
  const { api } = props;

  const [categories, setCategories] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    fetchCategories()
  }, []);

  const fetchCategories = () => {
    api.fetchBlogCategories().then((data) => {
      setCategories(data)
    })
  }

  const renderTitle = () => {
    if (categories.length === 0) {
      return null;
    }

    return (
      <Typography variant="h6">
        { t('menu.footer.blog.title') }
      </Typography>
    )
  }

  const renderCategory = (category) => {
    return (
      <Typography color="textPrimary" key={category.id}>
        <Link color="inherit" component={RouterLink} to={`/blog?category=${category.slug}`}>
          { category.name }
        </Link>
      </Typography>
    )
  }

  const renderCategories = () => {
    return categories.map(renderCategory)
  }

  return (
    <div className={css.blog_links}>
      { renderTitle() }
      { renderCategories() }
    </div>
  )
}

export default withAPI(BlogLinks);
