import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import { find, uniq } from 'lodash';

import { connect } from 'react-redux';

import Media from 'react-media';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ForwardIcon from '@material-ui/icons/Forward';

import { FloatingMediaSettingsProvider } from 'alp-shared-components/dist/shared/floating-media/settings-provider';
import { isMobile } from "alp-shared-components/dist/shared/utils";

import withAPI from '../api/context';
import MediaItems from "./media-items";

import css from './styles.module.scss';
import MetaInfo from "../group/meta-info";

import { menuActions } from "../shared/menu/actions";
import WordLists from "../media-items/word-lists";
import WatchAsPlaylist from "./watch-as-playlist";
import { mediaPath } from "../media-item/utils";
import Subscribe from "./subscribe";
import { SubscriptionProvider } from './providers/subscription';
import Tile from "./tile";

const mobile = isMobile();

const useCardHeaderStyles = makeStyles((theme) => ({
  root: { paddingBottom: 0 },
  title: {
    fontSize: '1.25rem'
  },
  text: {
    paddingLeft: 16,
    paddingRight: 16
  }
}));

const useStyles = makeStyles((theme) => ({
  actions: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  metaInfoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  metaInfo: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 15
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 10,
      justifyContent: 'center'
    }
  },
  buyGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  buyGroupDescriptionIcon: {
    display: 'inherit',
    transform: 'rotate(90deg)'
  },
}));

const CardHeader = (props) => {
  const { group } = props;

  const styles = useCardHeaderStyles();
  const { t } = useTranslation();

  const levels = uniq(group.mediaitems.map((mediaItem) => mediaItem.level).map((level) => t(`levelsList.${level}`) ))

  return (
    <div className={styles.text}>
      <Typography paragraph variant="h4" component="h1" color="textPrimary">
        {group.title}
      </Typography>
      <Typography paragraph variant="h6" color="textSecondary">
        { t(`themesList.${group.theme}`) } - { levels.join(', ') }
      </Typography>
      <Typography component="h2" color="textSecondary">
        <div dangerouslySetInnerHTML={{__html: group.description}} />
      </Typography>
    </div>
  );
};

const Group = (props) => {
  const { api, setMenuVariant, closeMenu, currentUserId, match: { params: { id } } } = props;

  const styles = useStyles();
  const [wordListsMediaItem, setWordListsMediaItem] = useState(null)

  const [group, setGroup] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchInfo = () => {
      api.fetchMediaItemsGroup(id).then((data) => {
        setGroup(data);
      })
    }

    fetchInfo();

  }, [api, id, currentUserId]);

  const onChangeMedia = (matchMedia) => {
    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  const openWordListsDialog = (mediaItem) => {
    if (!mobile) {
      setWordListsMediaItem(mediaItem);
    }
  }

  const closeWordListsDialog = () => {
    setWordListsMediaItem(null);
  }

  const renderActions = () => {
    return (
      <div className={styles.actions}>
        <WatchAsPlaylist group={group} />
        <SubscriptionProvider group={group}>
          <Subscribe group={group} />
        </SubscriptionProvider>
      </div>
    )
  }

  const renderBuyGroupButton = () => {
    if (!group.price) {
      return null;
    }

    let linkTo = '';
    const firstPaidMediaItem = find(group.mediaitems, (mediaItem) => mediaItem.format_price && !mediaItem.is_paid)
    if (firstPaidMediaItem) {
      linkTo = `${mediaPath(firstPaidMediaItem.id)}?group_id=${group.slug}`
    }

    return (
      <div className={styles.buyGroup}>
        <Button disabled={group.is_paid} size="large" color="primary" component={RouterLink} to={linkTo}>
          { t('group.buyGroup.buttonText', { formatPrice: group.format_price }) }
        </Button>
        <Typography color="textSecondary">
          { t('group.buyGroup.description') }
        </Typography>
        <Typography className={styles.buyGroupDescriptionIcon} component="div" color="textSecondary">
          <ForwardIcon />
        </Typography>
      </div>
    );
  }

  const renderGroup = () => {
    return (
      <Grid container justify={'center'}>
        <Grid item xs={12} md={7}>
          <Tile group={group} />
        </Grid>
        <Grid item xs={12} md={5}>
          <CardHeader group={group} />
        </Grid>
        <div className={styles.metaInfoWrapper}>
          <div className={clsx(css.metaInfo, styles.metaInfo)}>
            <MetaInfo group={group} />
          </div>
          { renderActions() }
        </div>

        { renderBuyGroupButton() }

        <MediaItems group={group} openWordListsDialog={openWordListsDialog} />
      </Grid>
    );
  }

  const renderLoader = () => {
    return (
      <div className={css.loader}>
        <CircularProgress size={120} />
      </div>
    )
  }

  const renderContent = () => {
    return (
      <Media
        query={{minWidth: 1680}}
        onChange={onChangeMedia}
      >
        {() =>
          <div className={css.group}>
            {group ? renderGroup() : renderLoader()}
          </div>
        }
      </Media>
    )
  }

  const renderMetaTags = () => {
    if (!group) {
      return null;
    }

    const { slug, title, description, image_medium_url } = group;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/g/${slug}`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={ description } />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={ description } />
        <meta property="og:image" content={image_medium_url} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" />
      </Helmet>
    )
  }

  return (
    <FloatingMediaSettingsProvider>
      { renderMetaTags() }
      { renderContent() }
      { !mobile && <WordLists mediaItem={wordListsMediaItem} onClose={closeWordListsDialog} /> }
    </FloatingMediaSettingsProvider>
  )
}

const mapStateToProps = ({ currentUser }) => (
  { currentUserId: currentUser.id }
);

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu
};

export default withAPI(connect(mapStateToProps, mapDispatchToProps)(Group))
