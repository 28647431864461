import React, { useState } from 'react';
import { object, func } from 'prop-types';

import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import Avatar from '../../../ui/avatar';

import css from './styles.module.scss';
import Menu from '../../../ui/menu';
import Logout from './menu/items/logout';
import Profile from './menu/items/profile';

import { currentUserActions } from '../../../current-user/actions';
import withIframeActions from '../../../media-item/iframe-provider';

const UserBlock = (props) => {
  const { currentUser, logout, sendMessageToIframe } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    closeMenu();
    logout().then(() => sendMessageToIframe && sendMessageToIframe({ type: 'logout' }));
  };

  const handleProfile = () => {
    closeMenu();
  };

  return(
    <div className={css.user_block}>
      <Avatar img={currentUser.avatar.small} css={css.avatar} onClick={openMenu}/>

      <Menu onClose={closeMenu} anchorEl={menuAnchorEl}>
        <Profile onClick={handleProfile} />
        {/*Temporary disabled this link to avoid every one create new media*/}
        {/*<NewMedia component={NewMediaLink} />*/}
        <Divider className={css.menu_divider} />
        <Logout onClick={handleLogout} />
      </Menu>
    </div>
  );
};

UserBlock.propTypes = {
  currentUser: object.isRequired, // from store
  logout: func.isRequired // from currentUserActions
};

const mapStateToProps = ({ currentUser }) => (
  { currentUser }
);

const mapDispatchToProps = {
  logout: currentUserActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(withIframeActions(UserBlock))
