import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { Helmet } from "react-helmet";

import { connect } from 'react-redux';

import css from './styles.module.scss';

import ITLFrame from '../itl-frame';
import { menuActions } from '../shared/menu/actions';
import withAPI from '../api/context';
import { headerActions } from '../shared/header/actions';

export class MediaItem extends Component {
  static propTypes = {
    match: PropTypes.shape({ // from Router
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    location: PropTypes.shape({ // from Router
      search: PropTypes.string
    }).isRequired,
    setMenuVariant: PropTypes.func.isRequired, // from menuActions
    closeMenu: PropTypes.func.isRequired // from menuActions
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      description_from_sync: '',
      small_poster: '',
      id: null
    }
  }

  componentDidMount() {
    this.fetchMediaItemInfo();
    this.initMenu();
  }

  componentWillUnmount() {
    const { setIsVisibleHeader } = this.props;
    setIsVisibleHeader(true);
  }

  fetchMediaItemInfo() {
    const { api, match: { params: { id } } } = this.props;

    api.fetchMediaItem(id, { fields: ['id', 'title', 'description', 'description_from_sync', 'small_poster'] }).then((data) => {
      this.setState(data);
    })
  }

  initMenu() {
    const { setMenuVariant, closeMenu } = this.props;
    setMenuVariant('temporary');
    closeMenu();
  }

  handleMessage = (type, payload, { sendMessage }) => {
    if (type !== 'playerReady') {
      return;
    }

    const {
      location: { search }
    } = this.props;

    const { time } = queryString.parse(search);

    if (time) {
      sendMessage({
        type: 'seek',
        payload: time
      });
    }
  };

  renderMetaTags() {
    const { id, title, description, description_from_sync, small_poster } = this.state;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/media/${id}`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={ description || description_from_sync } />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={ description || description_from_sync } />
        <meta property="og:image" content={small_poster} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="video" />

        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
    )
  }

  render() {
    const {
      match: { params: { id } },
      location: { state: { translationMediaItemId } = {} },
      location
    } = this.props;

    const { group_id, with_google_ads } = queryString.parse(location.search);
    const query = queryString.stringify({ translation: translationMediaItemId, group_id, with_google_ads }, { skipNull: true });

    let src = `${process.env.REACT_APP_ITL_URL}/mediaitems/${id}/embed`;

    src += `?${query}`;

    return (
      <Fragment>
        { this.renderMetaTags() }

        <ITLFrame
          src={src}
          className={css.mediaItem}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Preview"
          onMessage={this.handleMessage}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu,
  setIsVisibleHeader: headerActions.setIsVisible
};

export default withAPI(
  connect(null, mapDispatchToProps)(MediaItem)
)
