import React from 'react'

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { map, isEqual } from 'lodash';

import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';
import BarChartIcon from '@material-ui/icons/BarChart';
import ToysIcon from '@material-ui/icons/Toys';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FaceIcon from '@material-ui/icons/Face';
import ShopIcon from '@material-ui/icons/Shop';
import SortIcon from '@material-ui/icons/Sort';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';

import { initialState } from '../query/reducer';
import { queryActions } from '../query/actions';

import css from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.6)
    }
  }
}));

const filters = {
  sourceLanguage: {
    type: 'object',
    icon: <LanguageIcon />,
    removeAction: 'setSourceLanguage',
    propertyValue: 'name',
    propertyKey: 'code'
  },
  searchValue: {
    type: 'string',
    icon: <SearchIcon />,
    removeAction: 'setSearchValue'
  },
  levels: {
    type: 'array',
    icon: <BarChartIcon />,
    removeAction: 'setLevels',
    value: 'levelsList'
  },
  theme: {
    type: 'string',
    icon: <ToysIcon />,
    removeAction: 'setTheme',
    value: 'themesList'
  },
  payer: {
    type: 'object',
    icon: <AttachMoneyIcon />,
    removeAction: 'setPayer'
  },
  producer: {
    type: 'object',
    icon: <FaceIcon />,
    removeAction: 'setProducer',
    propertyValue: 'fullName'
  },
  watcher: {
    type: 'object',
    icon: <VisibilityIcon />,
    removeAction: 'setWatcher',
    propertyValue: 'fullName'
  },
  withPrice: {
    type: 'boolean',
    icon: <ShopIcon />,
    removeAction: 'setWithPrice'
  },
  order: {
    type: 'string',
    icon: <SortIcon />,
    removeAction: 'setOrder',
    value: 'orderList'
  }
}

const getFilterValue = (filterKey, query, t) => {
  const filter = filters[filterKey];
  const valueType = filter.type;
  let value = query[filterKey];

  switch (valueType) {
    case 'array':
      value = value.map((item) => t(`${filter.value}.${item}`)).join(', ');
      break;
    case 'boolean':
      value = null;
      break;
    case 'object':
      value = t(`${filterKey}.${value[filter.propertyKey]}`, value[filter.propertyValue]);
      break
    default: {
      if (filter.value && filter.value !== 'value') {
        value = t(`${filter.value}.${value}`)
      }
    }
  }

  if (value) {
    return t(`mediaItems.currentFilter.keys.${filterKey}`) + ': ' + value
  }

  return t(`mediaItems.currentFilter.keys.${filterKey}`);
}

const CurrentFilter = (props) => {
  const { query, clearAll } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const handleRemove = (filterKey) => () => {
    const actionName = filters[filterKey].removeAction;
    const value = initialState[filterKey];
    props[actionName](value)
  }

  const renderItem = (filterKey) => {
    if (isEqual(initialState[filterKey], query[filterKey])) {
      return null;
    }

    const filterValue = getFilterValue(filterKey, query, t);
    const icon = filters[filterKey].icon;

    return (
      <Chip
        key={filterKey}
        icon={icon}
        label={filterValue}
        onDelete={handleRemove(filterKey)}
      />
    )
  }

  const renderItems = () => {
    return map(filters, (value, key) => {
      return renderItem(key);
    } );
  }

  return (
    <div className={clsx(classes.root, css.current_filter)}>
      { renderItems() }
      <Button size="small" variant="contained" color="primary" disableElevation onClick={clearAll}>
        { t('mediaItems.currentFilter.clearAll') }
      </Button>
    </div>
  )
}

const mapStateToProps = ({ mediaItems: { query } }) => (
  { query }
);

const mapDispatchToProps = {
  ...queryActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentFilter);
