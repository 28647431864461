import React, { useState, useEffect, useCallback, useRef } from 'react';
import { object, func, number, string, bool } from 'prop-types';
import { cloneDeep, isNumber } from "lodash";
import { connect } from "react-redux";

import { KnownWordIdsProvider } from 'alp-shared-components/dist/shared/providers/known-word-ids';
import { WordListWordIdsProvider } from 'alp-shared-components/dist/shared/providers/word-list-word-ids';
import { WordListsDialogProvider } from 'alp-shared-components/dist/shared/word-lists-dialog/provider';
import { KnownWordActionsProvider } from "alp-shared-components/dist/shared/providers/known-word-actions";
import { ItemSettingsProvider } from "alp-shared-components/dist/words/word-list/providers/item-settings/provider";

import WordLists from './WordLists';
import BigdefContainer from '../../../shared/bigdef/container';
import withAPI from '../../../api/context';
import withBigdefAPI from '../../../api/context-bigdef';
import withLearnerDataAPI from '../../../api/context-learner-data';

const ManageWords = (props) => {
  const {
    api,
    bigdefApi,
    learnerDataApi,
    closeWordListsDialog,
    wordListAttrs,
    profileId,
    isYourProfile,
    currentUserId,
    languageCode,
    ranges
  } = props;

  const [knownWords, setKnownWords] = useState([]);
  const [wordListWords, setWordListWords] = useState([]);
  const [bigdefWord, setBigdefWord] = useState(null);

  const listActions = useRef();

  const closeBigdefContainer = () => {
    setBigdefWord(null);
  }

  const fetchUsersWords = useCallback(
    () => {
      Promise.all([bigdefApi.knownDictionaryWordIds(), bigdefApi.wordListDictionaryWordIds()]).then((words) => {
        setKnownWords(words[0]);
        setWordListWords(words[1]);
      });
    },
    [bigdefApi]
  );

  useEffect(() => {
    if (currentUserId) {
      fetchUsersWords();
    } else {
      setKnownWords([]);
      setWordListWords([])
    }
  }, [currentUserId, fetchUsersWords]);

  const checkDictionaryWordInWordList = (attrs) => {
    const { wordListId, dictionaryWordItem, checked } = attrs

    if (isNumber(dictionaryWordItem)) {
      return learnerDataApi.checkDictionaryWordInWordList(wordListId, dictionaryWordItem, checked);
    }

    if (checked) {
      return learnerDataApi.addWordToList(dictionaryWordItem, wordListId).then((data) => data);
    }

    const removedItem = cloneDeep(dictionaryWordItem);
    removedItem.is_known_word_list = false;
    removedItem.word_list_id = wordListId;

    return learnerDataApi.removeUserWord(removedItem).then((data) => data)
  }

  const changeKnownWord = (item, checked) => {
    if (isNumber(item)) {
      return learnerDataApi.changeKnownWord(item, checked).then((data) => data)
    }

    const dictionaryWordId = item.dictionary_word_id

    if (checked) {
      return learnerDataApi.addKnownWord({ dictionary_word_id: dictionaryWordId }).then((data) => data);
    }

    return learnerDataApi.removeKnownWord(dictionaryWordId).then((data) => data);
  }

  const successChangeKnownWord = (data, checked, item) => {
    handleSuccessChangeKnownWord(data, checked, item)
    bigdefApi.knownDictionaryWordIds().then(setKnownWords);
  }

  const handleSuccessChangeKnownWord = (data, checked, item) => {
    const { key } = wordListAttrs;
    const isUnknownList = key.startsWith('u');

    if (isUnknownList) {
      if (checked) {
        listActions.current.removeFromInfiniteLoaderList(data)
      } else {
        listActions.current.addToInfiniteLoaderList(data)
      }
    } else {
      if (checked) {
        listActions.current.addToInfiniteLoaderList(data)
      } else {
        listActions.current.removeFromInfiniteLoaderList(data)
      }
    }
  }

  const successCheckDictionaryWord = (data, checked, dictionaryWordId) => {
    bigdefApi.wordListDictionaryWordIds().then(setWordListWords)
  }

  const setListActions = (wordListId, actions) => {
    listActions.current = actions;
  }

  const renderWordLists = () => {
    const { wType, name, key } = wordListAttrs;

    if (name && wType && key) {
      return (
        <ItemSettingsProvider withActions={isYourProfile}>
          <WordLists
            open
            onClose={closeWordListsDialog}
            queryParams={{
              userId: profileId,
              languageCode: languageCode,
              leftRange: ranges.left,
              rightRange: ranges.right,
              wtypes: [wType],
              type: name
            }}
            frequentDictionaryWordsStatistic={bigdefApi.frequentDictionaryWordsStatistic}
            openBigdef={setBigdefWord}
            wordListsKey={key}
            setListActions={setListActions}
          />
        </ItemSettingsProvider>
      )
    }

    return null;
  }

  const renderBigdefContainer = () => {
    return (
      <BigdefContainer
        open={Boolean(bigdefWord)}
        onClose={closeBigdefContainer}
        word={bigdefWord}
        getTranslations={bigdefApi.fetchDictionaryWordTranslations}
        fetchSampleSentences={api.fetchSampleSentences}
        fetchAudioSamples={bigdefApi.fetchAudioSamples}
        fetchPictures={bigdefApi.fetchPictures}
        lookup={bigdefApi.lookup}
        upVote={api.upVote}
        downVote={api.downVote}
        removeVote={api.removeVote}
        onSubmitSuggestion={bigdefApi.submitDictionaryWordSuggestion}
      />
    )
  }

  return (
    <WordListWordIdsProvider wordListWordIds={wordListWords}>
      <KnownWordIdsProvider knownWordIds={knownWords}>
        <KnownWordActionsProvider
          changeKnownWord={changeKnownWord}
          successChangeKnownWord={successChangeKnownWord}
        >
          <WordListsDialogProvider
            fetchWordLists={learnerDataApi.fetchWordLists}
            checkDictionaryWord={checkDictionaryWordInWordList}
            successCheckDictionaryWord={successCheckDictionaryWord}
            createWordList={learnerDataApi.createWordList}
            saveWordList={learnerDataApi.updateWordList}
          >
            { renderWordLists() }
            { renderBigdefContainer() }
          </WordListsDialogProvider>
        </KnownWordActionsProvider>
      </KnownWordIdsProvider>
    </WordListWordIdsProvider>
  )
}

ManageWords.propTypes = {
  api: object,
  bigdefApi: object,
  learnerDataApi: object,
  closeWordListsDialog: func,
  wordListAttrs: object,
  profileId: number,
  currentUserId: number,
  languageCode: string,
  ranges: object,
  isYourProfile: bool
}

const mapStateToProps = ({ currentUser }) => ({
  currentUserId: currentUser.id
});

export default withAPI(
  withBigdefAPI(
    withLearnerDataAPI(connect(mapStateToProps)(ManageWords))
  )
);
