import React from 'react';
import { func, string } from 'prop-types';

import { useTranslation } from 'react-i18next';
import LinkIcon from '@material-ui/icons/Link';

import { connect } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Item from '../../../../../ui/menu/item';
import {alertifyActions} from "../../../../../shared/alertify/actions";

const CopyLink = (props) => {
  const { onClick, copiedText, openAlertify, onCopy } = props;

  const { t } = useTranslation();

  const copy = () => {
    openAlertify({ type: 'success', text: 'profile.files.successCopyLinkMessage' });
    onCopy();
  };

  return (
    <CopyToClipboard
      text={copiedText}
      onCopy={copy}
    >
      <Item onClick={onClick} icon={<LinkIcon color="primary" />} text={t('profile.files.folderActions.copy')} />
    </CopyToClipboard>
  )
};

CopyLink.propsTypes = {
  onClick: func.isRequired, // from props
  openAlertify: func.isRequired, // from alertifyActions
  onCopy: func.isRequired, // from props
  copiedText: string.isRequired // from props
};

CopyLink.defaultProps = {
  onClick: () => {}
};

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify
};

export default connect(null, mapDispatchToProps)(CopyLink);
