import { actions } from './actions';

import { getDataFromLocalStorage } from '../../local-storage';
import { QUERY_FILTER_KEYS } from '../../local-storage/constants';

const DEFAULT_SOURCE_LANGUAGE = { code: 'ru', name: 'Russian' };

const localStorageData = getDataFromLocalStorage(QUERY_FILTER_KEYS.params)

const initialState = {
  levels: [],
  theme: null,
  order: null, // created_at desc - Most Recent
  searchValue: '',
  page: 1,
  perPage: 20,
  producer: {},
  payer: {},
  watcher: {},
  withPrice: false,
  multiLanguage: false,
  sourceLanguage: DEFAULT_SOURCE_LANGUAGE,
  translationLanguage: null
};

const storedState = localStorageData == undefined ? initialState : localStorageData

const query = (state = storedState, action) => {
  switch (action.type) {
    case actions.SET_LEVELS:
      return { ...state, levels: action.payload };
    case actions.ADD_LEVEL:
      return { ...state, levels: [...state.levels, action.payload] };
    case actions.REMOVE_LEVEL:
      return { ...state, levels: state.levels.filter((level) => level !== action.payload) };
    case actions.SET_THEME:
      return { ...state, theme: action.payload };
    case actions.SET_ORDER:
      return { ...state, order: action.payload };
    case actions.SET_PRODUCER:
      return { ...state, producer: action.payload };
    case actions.SET_WATCHER:
      return { ...state, watcher: action.payload };
    case actions.SET_PAYER:
      if (action.payload) {
        return { ...state, payer: action.payload };
      } else {
        return { ...state, payer: {} };
      }
    case actions.SET_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case actions.SET_PAGE:
      return { ...state, page: action.payload };
    case actions.INCREMENT_PAGE:
      return { ...state, page: state.page + 1 };
    case actions.TOGGLE_WITH_PRICE:
      return { ...state, withPrice: !state.withPrice };
    case actions.SET_WITH_PRICE:
      return { ...state, withPrice: action.payload };
    case actions.SET_MULTI_LANGUAGE: {
      const multiLanguage = action.payload;
      let { sourceLanguage, translationLanguage } = state;
      if (!multiLanguage) {
        sourceLanguage = null;
        translationLanguage = null;
      }
      return {
        ...state,
        multiLanguage,
        sourceLanguage,
        translationLanguage
      };
    }
    case actions.SET_SOURCE_LANGUAGE:
      return { ...state, sourceLanguage: action.payload };
    case actions.SET_TRANSLATION_LANGUAGE:
      return { ...state, translationLanguage: action.payload };
    case actions.CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

export { initialState };

export default query;
