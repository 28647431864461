import React, { Component, Fragment } from 'react';
import { func, bool, string, number } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { parse as parseQueryString } from 'qs';
import { has } from 'lodash';
import { default as MUDrawer } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { withRouter, matchPath } from 'react-router';

import { menuActions } from './actions';
import PayPalButton from '../donation/pay-pal-button';
import Themes from './themes';
import Subscriptions from './subscriptions';
import PaidContentFilter from './paid-content-filter';
import FilterMultiLanguage from './filter-multi-language';

import css from './styles.module.scss';
import About from './footer/content/about';
import HowItWorks from './footer/content/how-it-works';
import ContactUs from './footer/content/contact-us';
import Faq from './footer/content/faq';
import Feedbacks from './footer/content/feedbacks';
import Terms from './footer/content/terms';
import Facebook from './footer/content/facebook';
import Instagram from './footer/content/instagram';
import ThankYou from './footer/content/thank-you';

import { profileActions } from '../../profile/actions';
import { authenticationModalActions } from '../authentication-modal/actions';
import { tabs } from '../../profile/constants';
import { profileUrl } from '../../profile/utils';
import BlogLinks from "./footer/blog-links";
import Version from './version';

import { LEFT_MENU_KEYS } from '../../local-storage/constants';
import { setDataToLocalStorage } from '../../local-storage';
import { queryActions } from "../../media-items/query/actions";

const queryStringParams = parseQueryString(window.location.search.replace(/^\?/, ''), '');
const IS_MULTI_LANGUAGE_MEDIA_ITEMS_FEATURE_ENABLED = has(queryStringParams, 'multi_language');

const Drawer = withStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.leftMenu
  }
}))(MUDrawer);

class Menu extends Component {
  static propTypes = {
    t: func.isRequired, // from withTranslation
    opened: bool.isRequired, // from menu store
    variant: string.isRequired, // from menu store
    closeMenu: func.isRequired, // from menuActions
    fetchSubscriptions: func.isRequired, // from menuActions
    currentUserId: number, // from store currentUser store
    setCurrentProfileTab: func.isRequired, // from profileActions
    login: func.isRequired // from authenticationModalActions
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  componentDidUpdate(prevProps) {
    const { currentUserId } = this.props;

    if (currentUserId && currentUserId !== prevProps.currentUserId) {
      this.fetchSubscriptions();
    }

    this.storeMenuSettings()
  }

  storeMenuSettings() {
    const { opened, location } = this.props;

    const match = matchPath(location.pathname, { path: '/media/:id' });

    if(!match) {
      setDataToLocalStorage(LEFT_MENU_KEYS.open, opened)
    }
  }

  fetchSubscriptions() {
    const { fetchSubscriptions, currentUserId } = this.props;
    currentUserId && fetchSubscriptions();
  }

  renderWordsItem() {
    const { t, currentUserSlug, setCurrentProfileTab, login } = this.props;

    const onClick = () => {
      if (currentUserSlug) {
        setCurrentProfileTab(tabs.words)
      } else {
        login('wordsTab');
      }
    }

    const url = `${profileUrl(currentUserSlug)}`

    return (
      <ListItem button component={currentUserSlug ? Link : 'div'} to={url} onClick={onClick}>
        <ListItemText className={css.menuItemText} primary={t('menu.words')} />
      </ListItem>
    )
  }

  clickOnHome = () => {
    const { clearAllFilters } = this.props;
    clearAllFilters();
  }

  render() {
    const { opened, variant, closeMenu, t, history, currentUserId } = this.props;

    return (
      <Drawer
        className={css.drawer}
        variant={variant}
        anchor="left"
        open={opened}
        classes={{paper: css.drawerPaper}}
        onClose={closeMenu}
      >

        <List component="div" disablePadding>
          <ListItem button component={Link} to="/" onClick={this.clickOnHome}>
            <ListItemText className={css.menuItemText} primary={t('menu.home')} />
          </ListItem>

          { this.renderWordsItem() }


          {/*<ListItem button component={Link} to="/blog">*/}
          {/*  <ListItemText className={css.menuItemText} primary="Blog" />*/}
          {/*</ListItem>*/}

          {/*{ this.renderFlashcardsItem() }*/}

        </List>

        <Divider/>

        <Themes history={history} />

        <Divider/>

        <PaidContentFilter history={history} />
        <Divider/>

        { currentUserId && (
          <Fragment>
            <Subscriptions history={history} />
            <Divider/>
          </Fragment>
        ) }

        {IS_MULTI_LANGUAGE_MEDIA_ITEMS_FEATURE_ENABLED && <FilterMultiLanguage />}

        <div className={css.footer}>
          <div className={css.links}>
            <About />
            <HowItWorks />
            <ContactUs />
            <Faq />
            <Feedbacks />
            <Terms />
            <ThankYou />
          </div>
          <div className={css.socials}>
            <Facebook />
            <Instagram />
            <PayPalButton />
          </div>

          <BlogLinks />

          <div>{ t('menu.copyright', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>

          <div className={css.version}>
            <Version />
          </div>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = ({ currentUser, menu }) => (
  {
    currentUserId: currentUser.id,
    currentUserSlug: currentUser.slug,
    opened: menu.opened,
    variant: menu.variant
  }
);

const mapDispatchToProps = {
  closeMenu: menuActions.closeMenu,
  fetchSubscriptions: menuActions.fetchSubscriptions,
  setCurrentProfileTab: profileActions.setCurrentTab,
  login: authenticationModalActions.openAuthenticationModal,
  clearAllFilters: queryActions.clearAll
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menu))
)
