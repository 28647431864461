import React, { Fragment, useEffect } from "react";
import { func, object } from 'prop-types';

import { useTranslation } from 'react-i18next';

import Media from 'react-media';

import { Link as RouterLink } from "react-router-dom";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from "@material-ui/core";

import clsx from 'clsx';

import { connect } from 'react-redux';
import { menuActions } from '../menu/actions';
import { authenticationModalActions } from '../authentication-modal/actions';

import withAPI from '../../api/context';

import Logo from '../../ui/svg/icons/header_logo.svg';

import css from './styles.module.scss';
import cssVariables from '../../css/variables.scss';

import LanguageSwitch from './language-switch';
import Notifications from './notifications';
import SearchBox from './search-box';

import UserBlock from './user-block';
import { TABLET_LANDSCAPE_MAX } from "../../constants";
import WordsIcon from "./words-icon";

import Menu from '../menu'
import { headerActions } from './actions';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.background.header,
    color: theme.palette.primary.contrastText
  }
}))

export const Header = (props) => {
  const {
    toggleMenu,
    currentUser,
    handleClickOnLogin,
    api,
    setThemes,
    isVisible
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [searchBoxOpened, setSearchBoxOpened] = React.useState(false);

  const onToggleMenu = () => {
    toggleMenu();
  };

  useEffect(() => {
    fetchThemes()
  }, []);

  const fetchThemes = () => {
    api.fetchThemes().then(setThemes);
  }

  const openSearchBox = () => {
    setSearchBoxOpened(true);
  };

  const closeSearchBox = () => {
    setSearchBoxOpened(false);
  };

  const renderSearchBox = () => {
    if (searchBoxOpened) {
      return(
        <div className={css.search_box}>
          <SearchBox closeSearchBox={closeSearchBox}/>
        </div>
      )
    }

    return null;
  };

  const renderMenuButton = (isSmallScreen) => {
    if (isSmallScreen) {
      return (
        <IconButton size="small" color="inherit" onClick={onToggleMenu} className={clsx(css.menuButton, css.icon)}>
          <MenuIcon />
        </IconButton>
      )
    }

    return (
      <div className={css.menuButton}>
        <Button
          onClick={onToggleMenu}
          className={css.text}
          startIcon={<MenuIcon />}
          color="inherit"
        >
          <span>{t('header.menuButtonTitle')}</span>
        </Button>
      </div>
    )
  };

  const renderLogo = () => {
    return (
      <RouterLink to="/" className={css.logo}>
        <Icon>
          <img src={Logo} />
        </Icon>
      </RouterLink>
    )
  };

  const renderLanguageSwitch = () => {
    return (
      <div className={clsx(css.block, css.language_block)}>
        <LanguageSwitch />
      </div>
    )
  };

  const renderNotifications = () => {
    if (currentUser.id) {
      return (
        <div className={css.block}>
          <Notifications />
        </div>
      )
    }

    return null;
  };

  const renderUserBlock = (isSmallScreen) => {
    return (
      <div className={css.block}>
        {
          currentUser.id ?
            <UserBlock currentUser={currentUser}/> :
            isSmallScreen ? <IconButton size="small" edge="end" color="inherit" onClick={handleClickOnLogin}>
                <AccountCircleIcon style={{ color: cssVariables.white_white }} />
              </IconButton> :
            <Button
              onClick={handleClickOnLogin}
              className={clsx(css.text, css.loginButton)}
              startIcon={<AccountCircleIcon />}
              color="inherit"
            >
              <span>{t('header.logInButtonTitle')}</span>
            </Button>
        }
      </div>
    )
  };

  return (
    <Fragment>
      <Media query={{ maxWidth: TABLET_LANDSCAPE_MAX }}>
        { (matches) =>
          <div className={clsx(css.header, classes.header, { [css.hidden]: !isVisible })}>
            { renderSearchBox() }

            <div className={css.left}>

              { renderMenuButton(matches) }

              { matches && renderLogo() }

            </div>

            { !matches && (
              <div className={css.center}>
                { renderLogo() }
              </div>
            )}

            <div className={css.right}>
              <div className={css.block}>
                <IconButton size="small" color="inherit" onClick={openSearchBox}>
                  <SearchIcon fontSize="large" />
                </IconButton>
              </div>

              <WordsIcon />

              { renderNotifications(matches) }

              { renderLanguageSwitch() }

              { renderUserBlock(matches) }

            </div>
          </div>
        }
      </Media>
      <Menu />
    </Fragment>
  );
};

Header.propTypes = {
  toggleMenu: func.isRequired, // from menuActions
  currentUser: object, // from store
  handleClickOnLogin: func.isRequired, // from authenticationModalActions
  api: object,
  setThemes: func
};

const mapStateToProps = ({ currentUser, menu, header }) => (
  {
    currentUser: currentUser,
    isVisible: header.isVisible
  }
);

const mapDispatchToProps = {
  toggleMenu: menuActions.toggleMenu,
  handleClickOnLogin: () => {
    window.fbq && window.fbq("trackCustom", "OpenLoginDialog");

    return authenticationModalActions.openAuthenticationModal()
  },
  setThemes: headerActions.setThemes
};

export default connect(mapStateToProps, mapDispatchToProps)(withAPI(Header));
