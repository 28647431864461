import React from 'react';
import { func } from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';

import { useTranslation } from 'react-i18next';

import Item from '../../../../../ui/menu/item';

const Share = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <Item onClick={onClick} icon={<ShareIcon color="primary" />} text={t('profile.files.folderActions.share')} />
  )
};

Share.propsTypes = {
  onClick: func.isRequired // from props
};

export default Share;
