import React from 'react';
import PropTypes from 'prop-types';

import { default as MUPopover } from '@material-ui/core/Popover';

const Popover = (props) => {
  const { children, onClose, ...restProps } = props;

  return (
    <MUPopover
      {...restProps}
      onClose={onClose}
    >
      { children }
    </MUPopover>
  )
};

Popover.propTypes = {
  children: PropTypes.node, // from props
  onClose: PropTypes.func.isRequired // from props
};

export default Popover;
