// import './wdyr';
import dotenv from 'dotenv';

import 'intersection-observer';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from "react-dnd";

import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { isTouchDevice, isMobile } from "alp-shared-components/dist/shared/utils";

import setupStore from './store';

import { Provider as APIProvider } from './api/context';
import { Provider as LearnerDataAPIProvider } from './api/context-learner-data';
import { Provider as BigdefAPIProvider } from './api/context-bigdef';
import { Provider as AuthenticationAPIProvider } from './api/context-authentication';
import { Provider as MonetizationAPIProvider } from './api/context-monetization';

import Entry from './entry';

import './i18n';

import DynamicTheme from "./dynamic-theme";
import AppUpdateDialog from "./app-update-dialog";

dotenv.config();

console.log('React.version', React.version);

const reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY;

const mobile = isMobile();

const container = document.getElementById('root');
const root = createRoot(container);

const startApp = (ITL) => {
  const store = setupStore({ ITL });

  const renderContent = () => {
    if (isTouchDevice) {
      const options = mobile ? { delay: 150 } : { delayTouchStart: 150, delayMouseStart: 0, enableMouseEvents: true };
      return (
        <DndProvider backend={TouchBackend} options={options}>
          <Entry />
        </DndProvider>
      )
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <Entry />
      </DndProvider>
    )
  }

  const App = (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <APIProvider value={ITL.api}>
          <LearnerDataAPIProvider value={ITL.api.learnerData}>
            <BigdefAPIProvider value={ITL.api.bigdef}>
              <AuthenticationAPIProvider value={ITL.api.authentication}>
                <MonetizationAPIProvider value={ITL.api.monetization}>
                  <DynamicTheme>
                    <AppUpdateDialog />
                    { renderContent() }
                  </DynamicTheme>
                </MonetizationAPIProvider>
              </AuthenticationAPIProvider>
            </BigdefAPIProvider>
          </LearnerDataAPIProvider>
        </APIProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  );

  root.render(App);
};

const itlSdkOnReadyCallbackName = 'onItlSdkReady';
const itlSdkScript = document.createElement('script');
itlSdkScript.src = `${process.env.REACT_APP_ITL_URL}/sdk/sdk.js`;
itlSdkScript.setAttribute('onready', itlSdkOnReadyCallbackName);
window[itlSdkOnReadyCallbackName] = startApp;
document.head.appendChild(itlSdkScript);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register(swConfig);
