import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { connect } from "react-redux";

import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import DialogActions from '@material-ui/core/DialogActions';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import css from './styles.module.scss';

import { alertifyActions } from '../alertify/actions';

import Modal from '../../ui/modals/modal';


const ShareModal = (props) => {
  const { onClose, shareLink, openAlertify, headerText, submitButtonText } = props;
  const { t } = useTranslation();

  const open = Boolean(shareLink);

  const onCopy = () => {
    openAlertify({ type: 'success', text: 'profile.files.shareModal.successMessage' });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className={css.share_modal}>
      <DialogTitle onClose={onClose}>
        { headerText || t('profile.files.shareModal.header') }
      </DialogTitle>

      <TextField
        className={css.field}
        disabled
        value={shareLink}
        margin="normal"
      />

      <DialogActions>
        <CopyToClipboard
          text={shareLink}
          onCopy={onCopy}
        >
          <Fab
            variant="extended"
            size="small"
            color="primary"
          >
            { submitButtonText || t('profile.files.shareModal.buttonText') }
          </Fab>
        </CopyToClipboard>
      </DialogActions>
    </Modal>
  );
};

ShareModal.propTypes = {
  shareLink: PropTypes.string, // from props
  onClose: PropTypes.func.isRequired, // from props
  openAlertify: PropTypes.func.isRequired, // from alertifyActions
  headerText: PropTypes.string,
  submitButtonText: PropTypes.string
};

ShareModal.defaultProps = {
  shareLink: ''
};

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify
};

export default connect(null, mapDispatchToProps)(ShareModal);
