const actions = {
  SET_THEMES: 'SET_THEMES',
  SET_IS_VISIBLE_HEADER: 'SET_IS_VISIBLE_HEADER'
};

const setThemes = (themes) => ({
  type: actions.SET_THEMES,
  payload: themes
});

const setIsVisible = (isVisible) => ({
  type: actions.SET_IS_VISIBLE_HEADER,
  payload: isVisible
});

const headerActions = {
  setThemes, setIsVisible
};

export { actions, headerActions };
