import { ACTION_TYPES } from './actions';

const INITIAL_STATE = {
  hidden: false
};

const landing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.HIDE_LANDING: {
      return {
        ...state, hidden: true
      };
    }
    default:
      return state;
  }
};

export default landing;
