import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import Creator from "../../feedbacks/item/creator";
import Title from "../../feedbacks/item/title";
import CreatedTime from "../../feedbacks/item/created-time";
import Icon from "../../feedbacks/item/icon";
import { feedbackActions } from "../actions";

const Info = (props) => {
  const {
    feedback,
    toggleSubscription,
    currentUser,
    checkSubscription
  } = props;

  const handleToggleSubscription = () => {
    toggleSubscription(feedback.id)
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (feedback.id) {
      checkSubscription(feedback.id)
    }
  }, [currentUser.id, feedback.id, checkSubscription]);

  return (
    <Card>
      <div>
        <CardHeader
          avatar={feedback.kind && <Icon kind={feedback.kind} />}
          title={<Title feedback={feedback} />}
          subheader={<CreatedTime createdAt={feedback.created_at} />}
          action={
            <Button onClick={handleToggleSubscription} color="primary">
              { feedback.subscribed ? t('feedback.info.unsubscribeButtonTitle') : t('feedback.info.subscribeButtonTitle') }
            </Button>
          }
        />
      </div>

      <CardContent>
        <div dangerouslySetInnerHTML={{ __html: feedback.body }} />
      </CardContent>

      <CardActions disableSpacing>
        <Creator feedback={feedback} />
      </CardActions>
    </Card>
  )
};

Info.propTypes = {
  feedback: object.isRequired, // from props
  toggleSubscription: func.isRequired, // from feedbackActions
  currentUser: object.isRequired, // from store
  checkSubscription: func.isRequired, // from feedbackActions
};

const mapStateToProps = ({ currentUser }) => (
  { currentUser: currentUser }
);

const mapDispatchToProps = {
  toggleSubscription: feedbackActions.toggleSubscription,
  checkSubscription: feedbackActions.checkSubscription
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
