import React, { useState } from 'react';
import { object, func, number } from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Link as RouterLink } from "react-router-dom";
import MULink from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import ShareActions from "alp-shared-components/dist/item/share-actions";
import { TYPES } from "alp-shared-components/dist/item/share-actions/constants";
import FoldersTree from "alp-shared-components/dist/folders-tree";
import { isMobile } from "alp-shared-components/dist/shared/utils";

import css from "./styles.module.scss";
import WatchedProgressBar from "../watched-progress-bar";
import KnownWordsProgress from "../know-words-progress";
import ahoy from '../../../setup_ahoy';
import withLearnerDataAPI from '../../../api/context-learner-data';
import withApi from '../../../api/context';
import { alertifyActions } from "../../../shared/alertify/actions";
import { mediaPath } from "../../../media-item/utils";
import SubscriptionLabel from "../../../media-items/tile/subscription-label";
import LazyPicture from "../../../shared/lazy-picture";

const mobile = isMobile();

const useStyles = makeStyles((theme) => ({
  coverWrapper: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white
  }
}));

const Link = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: '0px'
  },
  bar: {
    backgroundColor: theme.palette.error.main
  }
}))(MULink);

const Tile = (props) => {
  const {
    mediaItem,
    openWordListsDialog,
    api,
    currentUserId,
    openAlertify
  } = props;
  const classes = useStyles();

  const [copiedMediaItem, setCopiedMediaItem] = useState(null);
  const { id, watched_progress_duration  } = mediaItem;

  const mediaItemPath = watched_progress_duration ?
    `${mediaPath(id)}?time=${watched_progress_duration / 1000}` :
    mediaPath(id)

  const openFoldersTree = (event) => {
    setCopiedMediaItem(mediaItem);
  };

  const closeFoldersTree = () => {
    setCopiedMediaItem(null);
  };

  const onClickShareButton = (socialProvider) => {
    ahoy().track('click_share_button', { social_provider: socialProvider, source: 'media_item', place: 'group' });
  }

  const successAddMediaItemToFolder = (successMessage, folder, data) => {
    openAlertify({ type: 'success', text: successMessage });
  }

  const renderCover = () => {
    return (
      <Link component={RouterLink} to={mediaItemPath}>
        <LazyPicture alt={mediaItem.title} src={mediaItem.medium_poster} webpSrc={mediaItem.webp_medium_poster} />
        <div className={clsx(classes.coverWrapper, css.coverWrapper)}>
          <WatchedProgressBar mediaItem={mediaItem} />

          <div className={css.playOverlayWrapper}>
            <div className={css.playOverlay}></div>
            <PlayCircleOutlineIcon style={{ fontSize: 120 }} />
          </div>
        </div>
      </Link>
    )
  }

  return (
    <div className={css.tile}>
      { renderCover() }

      <div className={clsx(css.actions, { [css.isMobile]: mobile })}>
        <ShareActions
          item={mediaItem}
          type={TYPES.mediaitem}
          openFoldersTree={openFoldersTree}
          onClickShareButton={onClickShareButton}
          className={css.share_action}
        />

        { openWordListsDialog && (
          <KnownWordsProgress mediaItem={mediaItem} openWordListsDialog={openWordListsDialog} />
        )}

        { copiedMediaItem && (
          <FoldersTree
            currentUser={{ id: currentUserId }}
            item={copiedMediaItem}
            type={TYPES.mediaitem}
            closeFoldersTree={closeFoldersTree}
            fetchFolders={api.fetchMediaItemFoldersTree}
            createFolder={api.createFolder}
            addItemToFolder={api.addMediaItemToFolder}
            successAddItemToFolder={successAddMediaItemToFolder}
            removeItemFromFolder={api.removeMediaItemFromFolder}
          />
        )}
      </div>

      <SubscriptionLabel mediaItem={mediaItem} />
    </div>
  )
}

Tile.propTypes = {
  mediaItem: object,
  openWordListsDialog: func,
  learnerDataApi: object,
  currentUserId: number,
  openAlertify: func
}

const mapStateToProps = ({ currentUser }) => (
  {
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify
};

export default connect(mapStateToProps, mapDispatchToProps)(withApi(withLearnerDataAPI(Tile)))
