// TODO remove
import React, { Component }  from 'react';
import { func } from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import queryString from 'querystring';
import startsWith from 'lodash/startsWith';
import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";
import { currentUserActions } from '../../current-user/actions';
import Modal from '../../ui/modals/modal';
import ResetPasswordForm from './reset_password_form';

import css from './styles.module.scss';
import cssText from '../../css/text.module.scss';

class AuthenticationModal extends Component {
  componentDidMount() {
    this.checkConfirmationToken();
  }

  checkConfirmationToken() {
    const token = this.confirmationToken();

    if (token) {
      const { confirmAccount } = this.props;
      confirmAccount(token);
    }
  }

  resetPasswordToken() {
    const { search } = this.props.location;
    if (startsWith(search, '?')) {
      return queryString.parse(search.substr(1)).reset_password_token
    }

    return null;
  }

  confirmationToken() {
    const { search } = this.props.location;
    if (startsWith(search, '?')) {
      return queryString.parse(search.substr(1)).confirmation_token
    }

    return null;
  }

  closeModal = () => {
    const { history } = this.props;
    history.replace('/');
  };

  render() {
    const { t } = this.props;
    const isOpen = Boolean(this.resetPasswordToken());

    if (isOpen) {
      return (
        <Modal open onClose={this.closeModal} maxWidth="xs">
          <DialogTitle onClose={this.closeModal}>
            {t('authentication.resetPassword.header')}
          </DialogTitle>
          <div className={clsx(css.content, cssText.roboto_regular, cssText.cl_bluegrey_dark)}>
            <ResetPasswordForm token={this.resetPasswordToken()} onSuccess={this.closeModal}/>
          </div>
        </Modal>
      )
    }

    return null;
  }
};

AuthenticationModal.propTypes = {
  t: func.isRequired, // from withTranslation
  confirmAccount: func.isRequired // from currentUserActions
};

AuthenticationModal.defaultProps = {
  user: {
    password: ''
  },
  token: ''
};

const mapDispatchToProps = {
  confirmAccount: currentUserActions.confirmAccount
};

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(AuthenticationModal))
);
