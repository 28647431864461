import React, { useState, Children, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';

import Base from './base';
import css from './styles.module.scss';
import { feedbackCreatorActions } from '../shared/feedback-creator/actions';
import { FEEDBACK_KIND } from "alp-shared-components/dist/feedback/constants";

const COMMON_STORE_PATH = 'https://d2jnl03xhpho34.cloudfront.net/faq';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const Sections = (props) => {
  const { children } = props;

  const [activePanel, setActivePanel] = useState(0);

  const handleOnExpand = (panel) => () => {
    setActivePanel(activePanel === panel ? -1 : panel);
  };

  return (
    Children.map(children, (child, index) => (
      cloneElement(child, {
        handleOnExpand : handleOnExpand(index),
        panelIndex: index,
        expanded: index === activePanel,
      })
    ))
  )
};

const Section = (props) => {
  const {
    panel,
    header,
    children,
    expanded,
    handleOnExpand
  } = props;

  const onExpand = () => {
    handleOnExpand(panel)
  };

  return (
    <ExpansionPanel square expanded={expanded} onChange={onExpand}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{header}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography component='div' variant="body1">{children}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

const Faq = (props) => {
  const { setKind } = props;
  const { t } = useTranslation();

  const openFeedback = () => {
    setKind(FEEDBACK_KIND.BUG);
  };

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - FAQ`;
    const description = `${process.env.REACT_APP_PROJECT_NAME} is a Russian language learning website dedicated to supplementing language acquisition with interactive transcripts`;

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/faq`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  return (
    <Base>
      { renderMetaTags() }

      <h1>{t('faq.header')}</h1>
      <Sections>
        <Section header={t('faq.whatIs.header', { projectName: process.env.REACT_APP_PROJECT_NAME })}>
          <Trans i18nKey="faq.whatIs.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              ProjectName is a Russian language learning website dedicated to supplementing
              language acquisition with interactive transcripts.
              ProjectName has been developed based upon the beliefs that
              <ul>
                <li>listening and reading comprehension are key skills.</li>
                <li>
                  learners who are entertained while consuming content
                  in a foreign language (listening/reading) are more likely to continue in their free time.
                </li>
              </ul>
              The paradigm of a child reading together with an adult inspired us.
              The adult moves her finger along under the words as she speaks.
              The child can stop her at any time to ask what a word means or to repeat a word or phrase.
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.whatIsOrigin.header', { projectName: process.env.REACT_APP_PROJECT_NAME })}>
          <Trans i18nKey="faq.whatIsOrigin.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              ProjectName started under a different name in 2004.
              It is developed and maintained by a dedicated team which is distributed around the world.
            </div>
            <div>
              We welcome
              <Link href="#" onClick={openFeedback} underline="none">
                input
              </Link>
              from professional language instructors (or anyone else with an opinion, for that matter).
            </div>
            <div>
              ProjectName still has a long way to go to recognize our vision.
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howToUse.header')}>
          <Trans i18nKey="faq.howToUse.content">
            <ul className={css.decimalList}>
              <li>Find content that looks appealing.</li>
              <li>
                Click on a word to play from that point
                <ul className={css.lowerAlphaList}>
                  <li>Pause with the spacebar or a click/tap on the video.</li>
                  <li>Easily repeat sections you don’t understand.</li>
                  <li>Use the settings to control the speed or mouse-over features.</li>
                </ul>
              </li>
              <li>
                Drag and drop words
                <ul className={css.lowerAlphaList}>
                  <li>To bookmark them for later study.</li>
                  <li>To mark them as known (to track your progress).</li>
                </ul>
              </li>
              <li>
                Use flashcards for repetition of words you wish to learn or export the list for use in another program.
                (Note: This functionality will be undergoing significant improvements.)
              </li>
            </ul>
          </Trans>
        </Section>

        <Section header={t('faq.howToSaveWords.header')}>
          <Trans i18nKey="faq.howToSaveWords.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              Please keep in mind that users must have a free account with ProjectName and be signed in to save words to word lists.
            </div>
            <ul className={css.decimalList}>
              <li>
                Left-click/tap on the word you want to save
                (but don’t release the mouse button or lift your finger, if on a touchscreen device).
                This will open a panel of drop zones.
              </li>
              <li>
                Drag the word onto the drop zone to “Add to word list,”
                and this will bring the “Word List” tab in the right pane into focus.
              </li>
              <li>
                From here you can either
                <ul className={css.lowerAlphaList}>
                  <li>drop the word on the "Add to Word List" drop zone (this will add the word to your "Default" word list)</li>
                  <div>OR</div>
                  <li>you can drag the word into one of your current word lists shown in the "Word List" tab (right pane).</li>
                </ul>
              </li>
              <li>
                Note that if the Word List tab is already in focus in the right pane,
                you can drag and drop the word from the transcript directly into one of your current word lists.
              </li>
              <li>
                In the Word lists section, organize words into groups that make sense for your needs.
              </li>
            </ul>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howToSaveWords/picture-1.png`}
                alt="How To Save Words 1"
              />
            </div>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howToSaveWords/picture-2.png`}
                alt="How To Save Words 2"
              />
              <p>OR</p>
            </div>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howToSaveWords/picture-3.png`}
                alt="How To Save Words 3"
              />
            </div>
            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howToSaveWords/Add+Words+to+List.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.whatDo.header')}>
          <Trans i18nKey="faq.whatDo.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              Take a deep breath. Know that we are thankful for your patience as we improve ProjectName.
              We love all feedback.
            </div>
            <div>
              Use the feedback icon in the lower right corner of our site.
              Using this will allow you to take a screenshot as well as
              to share info about what device you are on (machine, OS, browser).
            </div>
            <div>
              Perhaps a function is not working well on a certain device.
              Perhaps we have not explained how something works.
              Maybe our servers are down. Any of these are possible.
            </div>
            <div>
              After we’re in touch with you,
              we also welcome your future feedback over skype if that is more convenient for you.
            </div>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatDo/picture-1.png`}
                alt="What Do 1"
              />
            </div>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatDo/picture-2.png`}
                alt="What Do 2"
              />
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howDoTurnAutoScrolling.header')}>
          <Trans i18nKey="faq.howDoTurnAutoScrolling.content">
            <div>
              By default, the transcript will automatically scroll along with the video,
              and the words in the transcript will be highlighted in blue as they are spoken.
              Auto-scroll can be <b>disabled</b> by:
            </div>

            <ul className={css.decimalList}>
              <li>
                Manually scrolling in the transcript by using a mouse scroll wheel,
              </li>
              <li>
                Scrolling with the scroll bar on the right side of the transcript,
              </li>
              <li>
                Clicking the <b>"Turn on Free-Scroll"</b> button at the top-right corner of the transcript.
              </li>
            </ul>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoTurnAutoScrolling/picture-1.png`}
                alt="How Do Turn Auto Scrolling 1"
              />
            </div>

            <div>
              To <b>resume auto-scroll</b> and re-center the active word in the transcript,
              click the <b>"Turn on Auto-Scroll"</b> button, also at the top-right corner of the transcript,
              or use the <b>keyboard shortcut A.</b>
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoTurnAutoScrolling/picture-2.png`}
                alt="How Do Turn Auto Scrolling 2"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoTurnAutoScrolling/Center+Transcript+New1.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>

          </Trans>
        </Section>

        <Section header={t('faq.howDoMarkAsKnown.header')}>
          <Trans i18nKey="faq.howDoMarkAsKnown.content">
            <ul className={css.decimalList}>
              <li>
                Click/tap on the word you want to save (but don’t release the mouse button or lift your finger, if on a touchscreen device).
              </li>
              <li>
                Drag the word onto the drop zone called “I know it”.
              </li>
              <li>
                This action will add the dictionary form of the word to your "Known Words" list
                and turn the word green in the current transcript as well as in any other transcript where the word is found.
              </li>
            </ul>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoMarkAsKnown/picture-1.png`}
                alt="How Do Mark As Known 1"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoMarkAsKnown/picture-2.png`}
                alt="How Do Mark As Known 2"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoMarkAsKnown/Make+Word+Known+1.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.whatAreShortcuts.header')}>
          <Trans i18nKey="faq.whatAreShortcuts.content">
            <div>
              You can view a list of all the keyboard shortcuts by clicking the "gear" wheel on the video player.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreShortcuts/picture-1.png`}
                alt="What Are Shortcuts 1"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreShortcuts/picture-2.png`}
                alt="What Are Shortcuts 2"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/whatAreShortcuts/Shortcut+List.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howDoAdjustSettings.header')}>
          <Trans i18nKey="faq.howDoAdjustSettings.content">
            <div>
              All video player and transcript settings can be found by clicking the “gear” wheel on the video player.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoAdjustSettings/picture-1.png`}
                alt="How Do Adjust Settings 1"
              />
            </div>

            <div>
              In the Settings pop-up, you will find all the Video Player and Transcript settings, as well as a list of shortcuts.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoAdjustSettings/picture-2.png`}
                alt="How Do Adjust Settings 2"
              />
            </div>

            <div>
              You can also adjust the video playback rate directly from the button shown below.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoAdjustSettings/picture-3.png`}
                alt="How Do Adjust Settings 3"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoAdjustSettings/Settings.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howDoViewWordInfo.header')}>
          <Trans i18nKey="faq.howDoViewWordInfo.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              While watching a video on ProjectName,
              you can view detailed word information on the Word Info tab in the right pane.
              There are two ways to view the information available for any word:
            </div>

            <ul className={css.decimalList}>
              <li>
                Simply click on the word in the transcript you’d like to view in the Word Info section.
              </li>
              <p>OR</p>
              <li>
                Alternatively, you can click and drag any word into the "Word Info" drop zone.
              </li>
            </ul>

            <div>
              Either of these options will open the word in the Word Info Section where you can view translations,
              audio recordings, classification information, sample sentences,
              and word conjugations and declensions.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoViewWordInfo/picture-1.png`}
                alt="How Do View Word Info 1"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoViewWordInfo/Word+Lookup.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howDoDisableWordTranslations.header')}>
          <Trans i18nKey="faq.howDoDisableWordTranslations.content">
            <div>
              By default, you can view a pop-up translation for any word in the transcript
              that you hover the mouse over. You can disable this feature in settings.
            </div>

            <ul className={css.decimalList}>
              <li>
                Click the "gear" wheel either on the word pop-up or in the video player.
              </li>
              <li>
                Click the toggle switch in settings for "Word Translation" to turn off or on.
              </li>
            </ul>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoDisableWordTranslations/picture-1.png`}
                alt="How Do Disable Word Translations 1"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howDoDisableWordTranslations/picture-2.png`}
                alt="How Do Disable Word Translations 2"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoDisableWordTranslations/Disable+pop+up+translations.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howDoFindTeacherContent.header')}>
          <Trans i18nKey="faq.howDoFindTeacherContent.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <div>
              The best way to both find all the videos by a specific teacher
              and be notified when they publish new content is to "Follow" them on ProjectName.
              Please keep in mind that users must have a free account with ProjectName and be signed in to follow a teacher.
            </div>

            <ul className={css.decimalList}>
              <li>
                For a producer you want to follow, go to a thumbnail of one of their videos
                and click on their name or picture at the bottom of the thumbnail.
                This will take you to their profile page.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-1.png`}
                    alt="How Do Find Teacher Content 1"
                  />
                </div>
              </li>
              <li>
                On their profile page, click the "Follow" button.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-2.png`}
                    alt="How Do Find Teacher Content 2"
                  />
                </div>
              </li>
              <li>
                Once you follow someone, you will see them on the left sidebar of the main page under your "Subscriptions".
                To see all content made by this producer, click on them in your "Subscriptions" list,
                and you'll see their videos on the right.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-3.png`}
                    alt="How Do Find Teacher Content 3"
                  />
                </div>
              </li>
              <li>
                You will also see a notification in ProjectName when they publish new content.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-4.png`}
                    alt="How Do Find Teacher Content 4"
                  />
                </div>
              </li>
              <li>
                Lastly, you can also get an email notification for new content
                by going to your own profile page, then Settings.
                In Settings, click "Notifications" and select the option to receive emails
                for "new content from those you follow".
                You can also choose with what frequency you'd like to receive email notifications.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-5.png`}
                    alt="How Do Find Teacher Content 5"
                  />
                </div>
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/picture-6.png`}
                    alt="How Do Find Teacher Content 6"
                  />
                </div>
              </li>
            </ul>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howDoFindTeacherContent/Follow+producers.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.whatAreSymbolsOnWordsHovering.header')}>
          <Trans i18nKey="faq.whatAreSymbolsOnWordsHovering.content">
            <p>
              This feature is turned on by default and allows you to play (green triangle)
              and pause (red square) the video player when you click on a word in the transcript.
            </p>
            <div>
              This feature can be disabled in the “Settings” on the video player.
              When disabled, anytime you click on a word in the transcript,
              the video will skip to play from the word that you click.
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreSymbolsOnWordsHovering/picture-1.png`}
                alt="What Are Symbols On Words Hovering 1"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreSymbolsOnWordsHovering/picture-2.png`}
                alt="What Are Symbols On Words Hovering 2"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreSymbolsOnWordsHovering/picture-3.png`}
                alt="What Are Symbols On Words Hovering 3"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/whatAreSymbolsOnWordsHovering/picture-4.png`}
                alt="What Are Symbols On Words Hovering 4"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/whatAreSymbolsOnWordsHovering/Random+Access.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howAddSentenceTranslationSuggestion.header')}>
          <Trans i18nKey="faq.howAddSentenceTranslationSuggestion.content">
            <p>
              If you’d like to suggest an alternate translation to any sentence,
              first click the pencil icon to the right of the translation.
            </p>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/picture-1.png`}
                alt="How can I add a sentence translation suggestion? 1"
              />
            </div>
            <p>
              When the dialog box opens, type your suggestion on the blank line and click “Submit”.
            </p>
            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/picture-2.png`}
                alt="How can I add a sentence translation suggestion? 2"
              />
            </div>

            <p>
              This will save your suggestion in our system.
              You can also “upvote” your suggestion or any other translation.
              Click the pencil icon again to open the suggestion dialog and click the “thumbs up” button on the suggestion you’d like to upvote.
              The translation with the most thumbs up votes will move to the top and display as the translation in the video.
            </p>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/picture-3.png`}
                alt="How can I add a sentence translation suggestion? 3"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/picture-4.png`}
                alt="How can I add a sentence translation suggestion? 4"
              />
            </div>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/picture-5.png`}
                alt="How can I add a sentence translation suggestion? 5"
              />
            </div>

            <div className={css.source}>
              <video controls="controls">
                <source
                  src={`${COMMON_STORE_PATH}/howAddSentenceTranslationSuggestion/Fragment+Suggestions.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.howSaveFavoriteVideos.header')}>
          <Trans i18nKey="faq.howSaveFavoriteVideos.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME}}>
            <p>
              If you have a free account with ProjectName, you can easily save favorite videos to your own personal folders. Here are the steps:
            </p>
            <p>
              1. On the main page, where you see video thumbnails, if you hover your mouse over a tile, a blue folder icon will appear.
            </p>
            <p>
              2. Left-click on that folder icon and chose "Copy to folder" from the context menu.
            </p>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howSaveFavoriteVideos/picture-1.png`}
                alt="How can I save my favorite videos? 1"
              />
            </div>

            <p>
              3. The dialog box that opens will be your "Favorites". You can create subfolders, too, or choose a folder you've created before. Then click "Submit"
            </p>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howSaveFavoriteVideos/picture-2.png`}
                alt="How can I save my favorite videos? 2"
              />
            </div>

            <p>
              4. Then, to access your Favorites, first go to your profile by clicking on your avatar picture in the top right corner of the screen and choosing "Profile".
            </p>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howSaveFavoriteVideos/picture-3.png`}
                alt="How can I save my favorite videos? 3"
              />
            </div>

            <p>
              5. This will take you to your profile page where you can see and access all your folders and saved videos.
            </p>

            <div className={css.source}>
              <img
                src={`${COMMON_STORE_PATH}/howSaveFavoriteVideos/picture-4.png`}
                alt="How can I save my favorite videos? 4"
              />
            </div>
          </Trans>
        </Section>

        <Section header={t('faq.deleteMyAccount.header')}>
          <Trans i18nKey="faq.deleteMyAccount.content" values={{projectName: process.env.REACT_APP_PROJECT_NAME, email: process.env.REACT_APP_PROJECT_EMAIL}}>
            <p>
              You can delete your ProjectName account at any time. However, keep in mind that once you delete it, you will not be able to recover it.
            </p>
            Learn what deleting your account means.
            <ul>
              <li>
                You’ll lose all the data and content in that account.
              </li>
              <li>
                You’ll lose access to content you bought with that account.
              </li>
            </ul>
            There are two ways to delete your account:
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                You can delete your account yourself via your profile page.
                <div className={css.source}>
                  <img
                    src={`${COMMON_STORE_PATH}/deleteMyAccount/picture-2.png`}
                    alt="How do I delete my account?"
                  />
                </div>
              </li>
              <li>You can send us your request using this (<a href={`mailto:${process.env.REACT_APP_PROJECT_EMAIL}`}>Email</a>) email.</li>
            </ul>
          </Trans>
        </Section>
      </Sections>
    </Base>
  )
};

const mapDispatchToProps = {
  setKind: feedbackCreatorActions.setKind
};

export default connect(null, mapDispatchToProps)(Faq);
