import { ACTION_TYPES } from './actions';

const INITIAL_STATE = {
  openSubscriptionDialog: false
};

const monetization = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_OPEN_SUBSCRIPTION_DIALOG: {
      return {
        ...state, openSubscriptionDialog: action.payload
      };
    }
    default:
      return state;
  }
};

export default monetization;
