import React from 'react';
import { array, object, func, bool } from 'prop-types';

import css from './styles.module.scss';
import Tile from "../../../media-items/tile";

const MediaItems = (props) => {
  const {
    mediaItems,
    currentFolder,
    successCreateFolder,
    canRemoveFromCurrentFolder,
    successAction
  } = props;

  return(
    <div className={css.tiles}>
      {
        mediaItems.map((mediaItem) => (
          <Tile
            successCreateFolder={successCreateFolder}
            successAddMediaItemToFolder={successAction}
            successRemoveMediaItemFromFolder={successAction}
            key={mediaItem.id}
            mediaItem={mediaItem}
            currentFolder={currentFolder}
            canRemoveFromCurrentFolder={canRemoveFromCurrentFolder}
          />
        ))
      }
    </div>
  )
}

MediaItems.propTypes = {
  mediaItems: array,
  currentFolder: object,
  successCreateFolder: func,
  canRemoveFromCurrentFolder: bool,
  successAction: func
}

export default MediaItems;
