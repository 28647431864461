import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { isFunction } from 'lodash';

function ScrollToTop(props) {
  const { scrollableElement, location, children = null } = props;

  useEffect(() => {
    if (!scrollableElement) {
      return;
    }

    if (isFunction(scrollableElement.scrollTo)) {
      scrollableElement.scrollTo(0, 0);
    } else {
      scrollableElement.scrollTop = 0;
    }
  }, [location.pathname, scrollableElement]);

  return children;
}

export default withRouter(ScrollToTop);
