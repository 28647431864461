import React, { Fragment } from 'react';
import { array, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';

import css from './styles.module.scss';
import cssText from '../../css/text.module.scss';

import { connect } from 'react-redux';
import { feedbackActions } from '../actions';

import Form from './form';
import Item from "./item";

const Comments = (props) => {
  const {
    comments,
    createComment,
    feedbackId,
    currentUserId
  } = props;

  const { t } = useTranslation();

  const handleCreateComment = (comment, actions) => {
    comment.commentable_id = feedbackId;
    comment.commentable_type = 'Feedback';

    createComment(comment).then((data) => {

    }, (error) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
    })
  };

  const renderCommentForm = () => {
    if (currentUserId) {
      return (
        <Fragment>
          <div className={clsx(cssText.roboto_medium, cssText.paragraph_2, cssText.cl_black_dark)}>
            { t('feedback.comments.form.placeholder') }
          </div>
          <Form onSubmit={handleCreateComment} />
        </Fragment>
      )
    }

    return (
      <Paper className={clsx(css.warning_block, cssText.subheading)}>
        { t('feedback.comments.form.notAuthenticated') }
      </Paper>
    )
  };

  return (
    <div className={css.comments}>
      <div className={clsx(css.header, cssText.roboto_medium, cssText.h5, cssText.cl_black_dark)}>
        { t('feedback.comments.header') }
      </div>

      <List className={css.list} disablePadding>
        { comments.map((comment) => <Item key={comment.id} comment={comment} />) }
      </List>

      { renderCommentForm() }
    </div>
  )
};

Comments.propTypes = {
  comments: array.isRequired, // form props
  createComment: func.isRequired, // from feedbackActions
  feedbackId: number, // from feedback store
  currentUserId: number // from currentUser store
};

const mapStateToProps = ({ feedback, currentUser }) => (
  {
    feedbackId: feedback.id,
    currentUserId: currentUser.id
  }
);

const mapDispatchToProps = {
  createComment: feedbackActions.createComment
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
