import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DialogTitle from "alp-shared-components/dist/shared/modals/Dialog/Title";

import { useServiceWorker } from "./useServiceWorker";
import { Typography } from "./shared/menu/footer/shared";

const AppUpdateDialog = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const [open, setOpen] = useState(true);

  const { t } = useTranslation()

  useEffect(() => {
    if (showReload && waitingWorker) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [waitingWorker, showReload, reloadPage]);

  const handleOnClick = () => {
    reloadPage();
  }

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        { t('appUpdateDialog.title') }
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          { t('appUpdateDialog.description') }
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOnClick} color="primary">
          { t('buttonTitles.refresh') }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppUpdateDialog;
