import React from 'react';
import cx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MUCardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';

import DateToString from 'alp-shared-components/dist/shared/date-to-string';

import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';

import TextInfoContent from './components/text-info-content';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    boxShadow: 'none',
    borderRadius: 0,
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));

const CardMedia = withStyles(() => ({
  root: ({ bgColor = 'rgba(0, 0, 0, 0.08)' }) => ({
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    backgroundColor: bgColor,
  }),
}))(MUCardMedia);

const Tile = (props) => {
  const { post } = props;
  const { title, description, created_at } = post;

  const { t, i18n } = useTranslation();

  const styles = useStyles();

  return (
    <Card className={cx(styles.root)}>
      <Link component={RouterLink} to={`/blog/${post.slug}`} underline="none">
        <CardMedia
          image={post.image_medium_url}
        />
      </Link>
      <CardContent className={styles.content}>
        <TextInfoContent
          overline={(
            <DateToString format="MMMM DD, YYYY" languageCode={i18n.language}>
              { created_at }
            </DateToString>)
          }
          heading={title}
          body={description}
        />


        <Link component={RouterLink} to={`/blog/${post.slug}`} underline="none">
          <Button color={'primary'} fullWidth className={styles.cta}>
            {t('blog.post.tile.more')} <ChevronRightRounded/>
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
}

export default Tile;
