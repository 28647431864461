import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './styles.module.scss';
import Donation from "../index";

const Cancelled = () => {
  const { t } = useTranslation();

  return(
    <Donation>
      <span className={css.text}>{ t('donation.cancelledMessage') }</span>
    </Donation>
  )
};

export default Cancelled;
