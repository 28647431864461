import React from 'react';
import { object } from 'prop-types';
import clsx from 'clsx';

import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link';

import cssText from '../../../css/text.module.scss';

const Body = (props) => {
  const { feedback } = props;
  const link = `feedback/${feedback.id}`;

  return (
    <Link component={RouterLink} to={link} underline="none">
      <span
        className={clsx(cssText.roboto_regular, cssText.paragraph_1, cssText.cl_grey_medium) }
      >
        { feedback.preview_body }
      </span>
    </Link>
  )
};

Body.propTypes = {
  feedback: object // from props
};

export default Body;
