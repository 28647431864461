import React from 'react';
import { func, bool } from 'prop-types';
import { default as MUPaper } from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ShowSubscriptionsButton from 'alp-shared-components/dist/monetization/subscription/show-subscriptions-button';

import css from './styles.module.scss';
import computerImage from './images/computer.png'

import Header from "./header";

import { withMonetization } from '../monetization/provider';
import CloseButton from "./close-button";

const Paper = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.landing
  }
}))(MUPaper);

const Landing = (props) => {
  const { setOpenSubscriptionDialog, hidden } = props;
  const { t } = useTranslation();

  const renderTitle = () => {
    return (
      <Typography variant="h6" color="textPrimary">
        { t('landing.monetization.subscriptionsButton.description', { languageName: process.env.REACT_APP_LANGUAGE_NAME }) }
      </Typography>
    )
  }

  const openSubscriptionDialog = () => {
    setOpenSubscriptionDialog(true)
  }

  const renderContent = () => {
    return (
      <div className={css.content}>
        <img src={computerImage} alt="computer" />
        <div className={css.subscriptionButton}>
          <ShowSubscriptionsButton
            onClick={openSubscriptionDialog}
            title={renderTitle()}
          />
        </div>
      </div>
    )
  }

  return (
    <Collapse in={!hidden} timeout={1000} mountOnEnter unmountOnExit>
      <Paper className={css.landing} elevation={0} square>
        <div className={css.closeButton}>
          <CloseButton color="inherit" />
        </div>
        <Header />
        { renderContent() }
      </Paper>
    </Collapse>
  )
}

const mapStateToProps = ({ landing }) => (
  {
    hidden: landing.hidden
  }
);

Landing.propTypes = {
  setOpenSubscriptionDialog: func,
  hidden: bool
}

export default withMonetization(connect(mapStateToProps)(Landing));
