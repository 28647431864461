import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { object, number, func } from 'prop-types';
import { connect } from 'react-redux';

import withAPI from '../../api/context';
import { authenticationModalActions } from "../../shared/authentication-modal/actions";
import {alertifyActions} from "../../shared/alertify/actions";

const { Provider: ContextProvider, Consumer } = createContext();

const Subscription = (props) => {
  const {
    children,
    api,
    group,
    currentUserId,
    login,
    openAlertify
  } = props;

  const { subscribed } = group;

  const [isSubscribed, setIsSubscribed] = useState(subscribed);

  const { t } = useTranslation();

  useEffect(() => {
    setIsSubscribed(subscribed)
  }, [subscribed]);

  const toggleSubscription = (groupId) => {
    if (currentUserId) {
      api.toggleGroupSubscription(groupId).then(({ subscribed }) => {
        let successMessage = '';

        if (subscribed) {
          successMessage = t('group.subscription.successMessage.subscribe', { groupTitle: group.title })
        } else {
          successMessage = t('group.subscription.successMessage.unsubscribe', { groupTitle: group.title })
        }
        openAlertify({ type: 'success', text: successMessage });
        setIsSubscribed(subscribed)
      })
    } else {
      login('groupSubscription');
    }
  }

  const values = {
    toggleSubscription,
    subscribed: isSubscribed,
  };

  return (
    <ContextProvider value={values}>
      { children }
    </ContextProvider>
  );
};

const mapStateToProps = ({ currentUser }) => (
  { currentUserId: currentUser.id }
);

const mapDispatchToProps = {
  login: authenticationModalActions.openAuthenticationModal,
  openAlertify: alertifyActions.openAlertify
};

const SubscriptionProvider = withAPI(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);

const withSubscription = (Component) => (props) => (
  <Consumer>
    {(values) => <Component {...values} {...props} />}
  </Consumer>
);


SubscriptionProvider.propTypes = {
  api: object,
  children: object,
  group: object,
  currentUserId: number,
  login: func,
  openAlertify: func
};

export { SubscriptionProvider, withSubscription };
