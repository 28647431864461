import { actions } from './actions';
import { getDataFromLocalStorage } from '../../local-storage';
import { LEFT_MENU_KEYS } from '../../local-storage/constants';
const menuOpened = getDataFromLocalStorage(LEFT_MENU_KEYS.open)

const initialState = {
  opened: menuOpened,
  variant: 'temporary', // [temporary, persistent]
  subscriptions: []
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_MENU:
      return { ...state, opened: !state.opened };
    case actions.OPEN_MENU:
      return { ...state, opened: true };
    case actions.CLOSE_MENU:
      return { ...state, opened: false };
    case actions.SET_VARIANT:
      return { ...state, variant: action.payload };
    case actions.SET_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    default:
      return state;
  }
};

export default menu;
