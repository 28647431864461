import { alertifyActions } from '../shared/alertify/actions';

const nameSpace = 'CURRENT_USER_';

const actions = {
  SET_DATA: nameSpace + 'SET_DATA',
  CLEAR: nameSpace + 'CLEAR'
};

const fetch = () => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.fetchCurrentUser().then((data) => {
      dispatch(setData(data));
      return data;
    })
  )
);

const logout = () => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.logout().then((data) => {
      if (data) {
        dispatch(clear());

        dispatch(alertifyActions.openAlertify({
          type: 'success',
          text: 'authentication.logout.successMessage'
        }));
      }
      else {
        dispatch(alertifyActions.openAlertify({
          type: 'warning',
          text: 'authentication.logout.warningMessage'
        }));
      }
      return data;
    })
  )
);

const socialNetworkAuthenticate = (user, socialAccount) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.socialNetworkAuthenticate(user, socialAccount).then((data) => {
      dispatch(setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'authentication.socialNetwork.successMessage',
        interpolations: { provider: socialAccount.provider }
      }));

      return data;
    })
  )
);

const sendResetPasswordInstructions = (user) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.sendResetPasswordInstructions(user).then((data) => data, (error) => {
      dispatch(alertifyActions.openAlertify({type: 'error', text: error.response.data.message}));
      return Promise.reject(error);
    })
  )
);

const changePassword = (user) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.changePassword(user).then((data) => {
      dispatch(setData(data));

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'authentication.changePassword.successMessage',
        interpolations: { fullName: data.full_name }
      }));

      return data;
    }, (error) => {
      dispatch(alertifyActions.openAlertify({type: 'error', text: error.response.data.message}));
      return Promise.reject(error);
    })
  )
);

const confirmAccount = (token) => (
  (dispatch, getState, { ITL }) => (
    ITL.api.authentication.confirmAccount(token).then((data) => {
      dispatch(setData(data));

      window.fbq && window.fbq("trackCustom", "ConfirmEmail", { email: data.email });

      dispatch(alertifyActions.openAlertify({
        type: 'success',
        text: 'authentication.confirm.successMessage'
      }));

      return data;
    }, (error) => {
      dispatch(alertifyActions.openAlertify({type: 'error', text: error.response.data.message}));
    })
  )
);

const setData = (data) => (
  {
    type: actions.SET_DATA,
    payload: data
  }
);

const clear = () => (
  { type: actions.CLEAR }
);

const currentUserActions = {
  fetch,
  logout,
  socialNetworkAuthenticate,
  sendResetPasswordInstructions,
  changePassword,
  confirmAccount,
  setData,
  clear
};

export { actions, currentUserActions };
