import query from './query/reducer';
import { actions } from './actions';

const initialState = {
  data: [],
  hasMoreItems: false,
  availableSourceLanguages: null
};

const mediaItems = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, data: action.payload };
    case actions.ADD_DATA:
      return { ...state, data: [...state.data, ...action.payload] };
    case actions.SET_HAS_MORE_ITEMS:
      return { ...state, hasMoreItems: action.payload };
    case actions.SET_ERROR:
      return { ...state, error: action.payload };
    case actions.SET_AVAILABLE_SOURCE_LANGUAGES:
      return { ...state, availableSourceLanguages: action.payload };
    default:
      return {
        ...state,
        query: query(state.query, action)
      };
  }
};

export default mediaItems;
