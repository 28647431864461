import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Typography from '@material-ui/core/Typography';
import Base from './base';

import css from './styles.module.scss';
import Person from "./thank-you/person";

import withAPI from '../api/context';

const ThankYou = (props) => {
  const { api } = props;

  const { t } = useTranslation();

  const [profilesData, setProfilesData] = useState([]);

  useEffect(() => {
    fetchProfilesData()
  }, []);

  const fetchProfilesData = () => {
    const fields = ['id', 'slug', 'full_name', 'avatar', 'about', 'profile_social_accounts', 'volunteer_description'];
    const q = {
      volunteer_description_present: true
    }

    const params = { fields, q }

    api.fetchProfilesData(params).then(setProfilesData)
  }

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - Thank You`;
    const description = 'Please reach out to us if you are interested in helping with UI translations, content translations, beta-testing, or any other tasks';

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/thank-you`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  return (
    <Base cssGrid={css.thank_you}>
      { renderMetaTags() }

      <div className={css.thank_you}>
        <h1>{t('thankYou.header')}</h1>

        <div className={css.content}>
          <Typography variant="body2" paragraph>
            {t('thankYou.partOne')}
          </Typography>
        </div>

      </div>

      { profilesData.map((profileData) => <Person key={profileData.id} profileData={profileData} /> ) }
    </Base>
  )
};

export default withAPI(ThankYou);
