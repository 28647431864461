import ahoy from 'ahoy.js';

export default () => {
  ahoy.configure({
    urlPrefix: process.env.REACT_APP_ITL_URL
  })

  const events = {
    track(name, params = {}) {
      ahoy.track(name, { ...params });
    }
  };

  return events;
};
