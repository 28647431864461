import React from 'react';
import { object } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MULinearProgress from '@material-ui/core/LinearProgress';

const LinearProgress = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey['300'],
    height: 6,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  bar: {
    backgroundColor: theme.palette.error.main
  }
}))(MULinearProgress);

const WatchedProgressBar = (props) => {
  const { mediaItem } = props;
  const { duration, watched_progress_duration } = mediaItem;

  const progressValue = Math.floor(watched_progress_duration * 100 / (duration * 1000));

  if (progressValue) {
    return (
      <LinearProgress
        variant="determinate"
        value={progressValue}
      />
    );
  }

  return null
};

WatchedProgressBar.propTypes = {
  mediaItem: object
}

export default WatchedProgressBar;
