import { tap, includes } from 'lodash';
import { actions, queryActions } from './actions';
import { mediaItemsActions } from '../actions';
import { setDataToLocalStorage } from '../../local-storage';
import { QUERY_FILTER_KEYS } from '../../local-storage/constants';

const ACTIONS_TYPES = [
  actions.SET_SOURCE_LANGUAGE,
  actions.SET_TRANSLATION_LANGUAGE,
  actions.SET_LEVELS,
  actions.ADD_LEVEL,
  actions.REMOVE_LEVEL,
  actions.SET_THEME,
  actions.SET_PAYER,
  actions.SET_PRODUCER,
  actions.TOGGLE_WITH_PRICE,
  actions.SET_SEARCH_VALUE,
  actions.SET_WITH_PRICE,
  actions.SET_ORDER,
  actions.CLEAR_ALL,
  actions.SET_WATCHER
];

export default ({ dispatch, getState }) => (next) => (action) => tap(next(action), () => {
  if (!includes(ACTIONS_TYPES, action.type)) {
    return;
  }

  setDataToLocalStorage(QUERY_FILTER_KEYS.params, getState().mediaItems.query)

  dispatch(queryActions.setPage(1));
  dispatch(mediaItemsActions.fetchData());
});
