import React from 'react';
import { func, string } from 'prop-types';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import FieldFilter from '../../shared/field-filter';

import { states } from '../../feedback/constants';
import { queryActions } from '../query/actions';
import { feedbacksActions } from '../actions';

const StateFilter = (props) => {
  const {
    queryState,
    setQueryState,
    fetchFeedbacksData,
    setPage
  } = props;

  const { t } = useTranslation();

  const itemTitle = (field) => t(`feedback.states.${field}`);

  const onChange = () => {
    setPage(1);
    fetchFeedbacksData();
  };

  return (
    <FieldFilter
      items={states}
      selectedItem={queryState}
      emptyTitle={t('feedbacks.filter.state.empty')}
      setQueryField={setQueryState}
      itemTitle={itemTitle}
      onChange={onChange}
    />
  )
};

StateFilter.propsTypes = {
  fetchFeedbacksData: func.isRequired, // from feedbacksActions
  setQueryState: func.isRequired, // from queryActions
  queryState: string, // from feedbacks query
  setPage: func.isRequired // from queryActions
};

const mapStateToProps = ({ feedbacks: { query } }) => (
  { queryState: query.state }
);

const mapDispatchToProps = {
  setQueryState: queryActions.setState,
  setPage: queryActions.setPage,
  fetchFeedbacksData: feedbacksActions.fetchData
};

export default connect(mapStateToProps, mapDispatchToProps)(StateFilter);
