import React from 'react';
import { array, object, bool, func } from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { compose } from 'redux'

import last from 'lodash/last';

import { connect } from 'react-redux';
import Crumb from './crumb';

import css from './styles.module.scss';

import Menu from '../../../ui/menu';

import withFolderActions from '../with-folder-actions';

import Add from '../folders/menu/items/add';
import Delete from '../folders/menu/items/delete';
import CopyLink from '../folders/menu/items/copy-link';
import Remove from '../folders/menu/items/remove';
import Rename from '../folders/menu/items/rename';
import Share from '../folders/menu/items/share';
import Save from '../folders/menu/items/save';

import { folderShareLink } from '../../utils';

const BreadCrumbs = (props) =>  {
  const {
    breadCrumbs,
    parentFolder, // the folder which is parent - last folder in breadcrumbs
    isYourProfile,

    openNewFolderModal,
    openEditFolderModal,
    openShareModal,
    onSaveFolder,
    onRemoveFolder,
    onDeleteFolder,

    openMenu,
    closeMenu,
    menuAnchorEl
  } = props;

  const previousParentFolder = () => {
    if (breadCrumbs.length === 1) {
      return breadCrumbs[0]
    }
    return last(breadCrumbs.slice(0, breadCrumbs.length - 1));
  };

  const onOpenNewFolderModal = () => {
    openNewFolderModal({ name: '' });
  };

  const onOpenEditFolderModal = () => {
    openEditFolderModal(parentFolder)
  };

  const handleOnSaveFolder = () => {
    onSaveFolder(parentFolder);
  };

  const handleOpenShareModal = () => {
    openShareModal(parentFolder);
  };

  const handleOnRemoveFolder = () => {
    onRemoveFolder(parentFolder, isYourProfile ? previousParentFolder() : parentFolder);
  };

  const handleOnDeleteFolder = () => {
    onDeleteFolder(parentFolder, previousParentFolder())
  };

  const renderSeparator = (key) => <NavigateNextIcon key={`separator-${key}`} color="primary" />;

  const renderCrumbs = () => {
    return breadCrumbs.reduce((sum, crumb) => (
        sum === null ?
          [<Crumb key={crumb.id} crumb={crumb} />] :
          [...sum, renderSeparator(crumb.id), <Crumb key={crumb.id} crumb={crumb} />]
      ), null)

  };

  const renderMore = () => {
    if (menuItems().length === 0) {
      return null;
    }

    return(
      <IconButton className={css.more} size="small" onClick={openMenu}>
        <MoreVertIcon color="primary" />
      </IconButton>
    )
  };

  const menuItems = () => {
    const items = [];

    if (isYourProfile) {
      items.push(
        <Add
          key="add"
          visible={parentFolder.owned || parentFolder.id === null}
          onClick={onOpenNewFolderModal}
        />
      );
    }

    if (parentFolder.id) {
      items.push(
        <CopyLink
          key="copy-link"
          copiedText={folderShareLink(parentFolder)}
          onCopy={closeMenu}
        />
      );
      items.push(
        <Share
          key="share"
          onClick={handleOpenShareModal}
        />
      );
      items.push(
        <Save
          key="save"
          visible={!parentFolder.saved && !parentFolder.owned}
          onClick={handleOnSaveFolder}
        />
      );
      items.push(
        <Remove
          key="remove"
          visible={parentFolder.saved && !parentFolder.owned}
          onClick={handleOnRemoveFolder}
        />
      );

      items.push(
        <Rename
          key="rename"
          visible={parentFolder.owned && isYourProfile}
          onClick={onOpenEditFolderModal}
        />
      );
      items.push(
        <Delete
          key="delete"
          visible={parentFolder.owned && isYourProfile}
          onClick={handleOnDeleteFolder}
        />
      );
    }

    return items;
  };

  return (
    <div className={css.bread_crumbs}>
      { renderCrumbs() }
      { renderMore() }

      <Menu anchorEl={menuAnchorEl} onClose={closeMenu}>
        { menuItems() }
      </Menu>
    </div>
  );

};

BreadCrumbs.propTypes = {
  breadCrumbs: array.isRequired, // from files
  parentFolder: object.isRequired, // from files.breadCrumbs
  menuAnchorEl: object, // from withFolderActions
  isYourProfile: bool.isRequired, // from mapStateToProps

  openNewFolderModal: func.isRequired, // from withFolderActions
  openEditFolderModal: func.isRequired, // from withFolderActions
  openShareModal: func.isRequired, // from withFolderActions
  onSaveFolder: func.isRequired, // from withFolderActions
  onRemoveFolder: func.isRequired, // from withFolderActions
  onDeleteFolder: func.isRequired, // from withFolderActions

  openMenu: func.isRequired, // from withFolderActions
  closeMenu: func.isRequired // from withFolderActions
};

BreadCrumbs.defaultProps = {
  menuAnchorEl: null
};

const mapStateToProps = ({ profile, currentUser, profile: { files } }) => (
  {
    breadCrumbs: files.breadCrumbs,
    isYourProfile: profile.id === currentUser.id
  }
);

export default compose(
  connect(mapStateToProps),
  withFolderActions
)(BreadCrumbs);
