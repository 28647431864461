import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import CustomDragLayer from 'alp-shared-components/dist/shared/drag-n-drop/custom-drag-layer';
import { KnownWordIdsProvider } from 'alp-shared-components/dist/shared/providers/known-word-ids';
import { WordListWordIdsProvider } from 'alp-shared-components/dist/shared/providers/word-list-word-ids';
import { WordListsDialogProvider } from 'alp-shared-components/dist/shared/word-lists-dialog/provider';
import { KnownWordActionsProvider } from "alp-shared-components/dist/shared/providers/known-word-actions";
import FlashcardsProvider from "alp-shared-components/dist/words/word-list/providers/flashcards/provider";
import { ListFunctionsProvider } from "alp-shared-components/dist/words/word-list/providers/list-functions/provider";
import { ApiProvider } from 'alp-shared-components/dist/shared/providers/api';
import { getDeviceOrientation, DEVICE_ORIENTATIONS } from 'alp-shared-components/dist/shared/utils';

import WordLists from "alp-shared-components/dist/words/word-lists";

import css from './styles.module.scss';

import withBigdefAPI from "../../api/context-bigdef";
import withLearnerDataAPI from "../../api/context-learner-data";
import { default as BigdefContainer } from "../../shared/bigdef/container";
import withAPI from "../../api/context";

const learnerDataApiFlashcardsFunctions = (api) => {
  return {
    fetchFlashcardSettings: api.fetchFlashcardSettings,
    fetchFlashcards: api.fetchFlashcards,
    fetchDictionaryWordNotes: api.fetchDictionaryWordNotes,
    saveDictionaryWordNotes: api.saveDictionaryWordNotes,
    markWordAsKnown: api.flashcardsMarkWordAsKnown,
    flashcardsNextInterval: api.flashcardsNextInterval,
    fetchWordLists: api.fetchWordLists,
    fetchWordsCountInfo: api.fetchWordsCountInfo
  }
}

const bigdefApiFlashcardsFunctions = (api) => {
  return {
    fetchDictionaryWordTranslations: api.fetchDictionaryWordTranslations,
    fetchPictures: api.fetchPictures,
    fetchAudioSamples: api.fetchAudioSamples
  }
}

const clientApiFlashcardsFunctions = (api) => {
  return {
    fetchSampleSentences: api.fetchSampleSentences,
    updateFlashCardSetting: api.updateFlashCardSetting,
    createFlashCardFeedback: api.createFlashcardFeedback
  }
}

export class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bigdefWord: null,
      wordListWords: [],
      knownWords: [],
      wordListsActions: {},

      flashcardsWithAddToKnown: true,

      orientation: getDeviceOrientation()
    };
  }

  componentDidMount() {
    this.fetchUsersWords();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const orientation = getDeviceOrientation();
    this.setState({ orientation })
  }

  fetchUsersWords() {
    const { bigdefApi } = this.props;

    Promise.all([bigdefApi.knownDictionaryWordIds(), bigdefApi.wordListDictionaryWordIds()]).then((words) => {
      this.setState({ knownWords: words[0], wordListWords: words[1] })
    });
  }

  openBigdef = (word) => {
    this.setState({ bigdefWord: word })
  }

  closeBigdefContainer = () => {
    this.setState({ bigdefWord: null })
  }

  setWordListsActions = (actions) => {
    this.setState({ wordListsActions: actions });
  }

  checkDictionaryWordInWordList = (attrs) => {
    const { learnerDataApi } = this.props;
    const { wordListId, dictionaryWordItem, checked } = attrs;

    return learnerDataApi.checkDictionaryWordInWordList(wordListId, dictionaryWordItem, checked);
  }

  successCheckDictionaryWord = (data, checked, dictionaryWordId) => {
    const { bigdefApi } = this.props;
    const { wordListsActions } = this.state;

    if (checked) {
      const dropItem = { wordListId: data.word_list_id, known: data.is_known_word_list };
      wordListsActions.addWordToList(data, dropItem);
    } else {
      wordListsActions.removeWordFromList(data);
    }

    bigdefApi.wordListDictionaryWordIds().then((data) => this.setState({ wordListWords: data }))
  }

  successChangeKnownWord = (data, checked, dictionaryWordId) => {
    const { bigdefApi } = this.props;
    const { wordListsActions } = this.state;
    bigdefApi.knownDictionaryWordIds().then((data) => this.setState({ knownWords: data }))

    if (checked) {
      const dropItem = { wordListId: data.word_list_id, known: data.is_known_word_list };
      wordListsActions.addWordToList(data, dropItem);
    } else {
      wordListsActions.removeWordFromList(data);
    }
  }

  successCreateWordList = (wordList) => {
    const { wordListsActions } = this.state;
    wordListsActions.addListToData(wordList);
  }

  successSaveWordList = (wordListId, attrs) => {
    const { wordListsActions } = this.state;
    wordListsActions.updateList(wordListId, attrs);
  }

  successRemoveWordFromList = (word) => {
    const { bigdefApi } = this.props;

    if (word.is_known_word_list) {
      bigdefApi.knownDictionaryWordIds().then(this.setKnownWords);
    } else {
      bigdefApi.wordListDictionaryWordIds().then(this.setWordListWords);
    }
  }

  successAddWordToList = (word) => {
    const { bigdefApi } = this.props;

    if (word.is_known_word_list) {
      bigdefApi.knownDictionaryWordIds().then(this.setKnownWords);
    } else {
      bigdefApi.wordListDictionaryWordIds().then(this.setWordListWords);
    }
  }

  successRemoveList = (id) => {
    const { bigdefApi } = this.props;
    bigdefApi.wordListDictionaryWordIds().then(this.setWordListWords);
  }

  setKnownWords = (words) => {
    this.setState({ knownWords: words })
  }

  setWordListWords = (words) => {
    this.setState({ wordListWords: words })
  }

  renderWordLists() {
    const { learnerDataApi, i18n } = this.props;
    const { orientation } = this.state;
    const isLandscape = orientation === DEVICE_ORIENTATIONS.LANDSCAPE;

    return (
      <FlashcardsProvider openBigdef={this.openBigdef} withInfo={!isLandscape} withFooter={!isLandscape}>
        <WordLists
          getData={learnerDataApi.fetchWordLists}
          moveWord={learnerDataApi.moveItemBetweenWordLists}
          removeWord={learnerDataApi.removeUserWord}
          addList={learnerDataApi.createWordList}
          setWordListsActions={this.setWordListsActions}
          successAddWordToList={this.successAddWordToList}
          successRemoveWordFromList={this.successRemoveWordFromList}
          targetTranslationLanguageCode={i18n.language}
        />
      </FlashcardsProvider>
    )
  }

  renderBigdefContainer() {
    const { api, bigdefApi } = this.props;
    const { bigdefWord } = this.state;

    return (
      <BigdefContainer
        open={Boolean(bigdefWord)}
        onClose={this.closeBigdefContainer}
        word={bigdefWord}
        getTranslations={bigdefApi.fetchDictionaryWordTranslations}
        fetchSampleSentences={api.fetchSampleSentences}
        fetchAudioSamples={bigdefApi.fetchAudioSamples}
        fetchPictures={bigdefApi.fetchPictures}
        lookup={bigdefApi.lookup}
        upVote={api.upVote}
        downVote={api.downVote}
        removeVote={api.removeVote}
        onSubmitSuggestion={bigdefApi.submitDictionaryWordSuggestion}
      />
    )
  }

  render() {
    const { learnerDataApi, bigdefApi, api } = this.props;
    const { wordListWords, knownWords } = this.state;

    return (
      <WordListWordIdsProvider wordListWordIds={wordListWords}>
        <KnownWordIdsProvider knownWordIds={knownWords}>
          <KnownWordActionsProvider
            changeKnownWord={learnerDataApi.changeKnownWord}
            successChangeKnownWord={this.successChangeKnownWord}
          >
            <WordListsDialogProvider
              fetchWordLists={learnerDataApi.fetchWordLists}
              checkDictionaryWord={this.checkDictionaryWordInWordList}
              successCheckDictionaryWord={this.successCheckDictionaryWord}
              createWordList={learnerDataApi.createWordList}
              saveWordList={learnerDataApi.updateWordList}
              successCreateWordList={this.successCreateWordList}
              successSaveWordList={this.successSaveWordList}
            >
              <div className={css.content}>
                <div className={css.words}>
                  <ListFunctionsProvider
                    getData={learnerDataApi.fetchWordListWords}
                    openBigdef={this.openBigdef}
                    rename={learnerDataApi.updateWordList}
                    remove={learnerDataApi.removeWordList}
                    setAsDefault={learnerDataApi.setAsDefaultWordList}
                    fetchAdditionalItemInfo={learnerDataApi.fetchAdditionalInfoUserWord}
                    successRemoveList={this.successRemoveList}
                    exportUrl={`${process.env.REACT_APP_LEARNER_DATA_API_URL}/word_lists`}
                  >
                    <ApiProvider
                      learnerDataApi={learnerDataApiFlashcardsFunctions(learnerDataApi)}
                      bigdefApi={bigdefApiFlashcardsFunctions(bigdefApi)}
                      clientApi={clientApiFlashcardsFunctions(api)}
                    >
                      { this.renderWordLists() }
                    </ApiProvider>
                  </ListFunctionsProvider>
                </div>
              </div>

              { this.renderBigdefContainer() }

              <CustomDragLayer />
            </WordListsDialogProvider>
          </KnownWordActionsProvider>
        </KnownWordIdsProvider>
      </WordListWordIdsProvider>
    )
  }
}

export default withAPI(
  withLearnerDataAPI(
    withBigdefAPI(
      withTranslation()(Content)
    )
  )
)
