import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

import css from './styles.module.scss';

const useDefaultStyles = makeStyles(styles, { name: 'TextInfoContent' });

const Index = ({
                           useStyles,
                           overline,
                           heading,
                           body,
                           overlineProps,
                           headingProps,
                           bodyProps,
                           ...props
                         }) => {
  const styles = useStyles(props);
  return (
    <>
      {overline && (
        <Typography
          component={'span'}
          {...overlineProps}
          className={cx(styles.overline, overlineProps.className)}
        >
          {overline}
        </Typography>
      )}
      <Typography
        component={'h4'}
        noWrap
        {...headingProps}
        className={cx(styles.heading, headingProps.className)}
      >
        {heading}
      </Typography>
      <Typography {...bodyProps} className={cx(styles.body, bodyProps.className, css.body)}>
        {body}
      </Typography>
    </>
  );
};

Index.propTypes = {
  overline: PropTypes.node,
  heading: PropTypes.node,
  body: PropTypes.node,
  overlineProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  headingProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  bodyProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  useStyles: PropTypes.func,
};

Index.defaultProps = {
  overline: null,
  heading: null,
  body: null,
  overlineProps: {},
  headingProps: {},
  bodyProps: {},
  useStyles: useDefaultStyles,
};

export default Index;
