import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { useTranslation } from "react-i18next";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MUCircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import DialogTitle from 'alp-shared-components/dist/shared/modals/Dialog/Title';
import withAPI from '../../../api/context';
import { alertifyActions } from '../../../shared/alertify/actions';
import { currentUserActions } from '../../../current-user/actions';
import { profileUrl } from '../../utils';

const defaultProfileSlug = process.env.REACT_APP_DEFAULT_PROFILE_SLUG;

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main
  }
}))(Button);

const CircularProgress = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    position: 'absolute'
  }
}))(MUCircularProgress);

const DeleteAccount = (props) => {
  const { history, api, openAlertify, clearAccount } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteAccountDelayed, setDeleteAccountDelayed] = useState(false);

  const { t } = useTranslation();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleting(false);
    setDeleteAccountDelayed(false);
  }

  const handleDeleteAccount = () => {
    setDeleting(true);

    api.deleteAccount().then(() => {
      history.push(profileUrl(defaultProfileSlug));
      clearAccount()

      openAlertify({
        type: 'success',
        text: 'profile.info.deleteAccount.successMessage'
      })
    }, ({response}) => {
      if (response.status === 422 && response.data.error === 'delete_restriction') {
        setDeleting(false);
        setDeleteAccountDelayed(true);
      } else {
        setDeleting(false);
        openAlertify({
          type: 'error',
          text: 'Something went Wrong'
        })
      }
    })
  }

  const renderContent = () => {
    if (deleteAccountDelayed) {
      return (
        <Typography>
          { t('profile.info.deleteAccount.dialog.deleteAccountDelayedDescription') }
        </Typography>
      )
    }

    return (
      <Typography>
        { t('profile.info.deleteAccount.dialog.description') }
      </Typography>
    );
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog}
      >
        <DialogTitle onClose={handleCloseDialog}>
          { t('profile.info.deleteAccount.dialog.header') }
        </DialogTitle>

        <DialogContent dividers>
          { renderContent() }
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            { t('buttonTitles.cancel') }
          </Button>

          { !deleteAccountDelayed && (
            <DeleteButton disabled={deleting} onClick={handleDeleteAccount}>
              { t('profile.info.deleteAccount.dialog.buttonTitle') }
              {` `}
              { deleting && <CircularProgress size={20} /> }
            </DeleteButton>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      <DeleteButton onClick={handleOpenDialog}>
        { t('profile.info.deleteAccount.buttonTitle') }
      </DeleteButton>

      { renderDialog() }
    </div>
  )
}

DeleteAccount.propTypes = {
  history: object,
  api: object,
  openAlertify: func,
  clearAccount: func
}

const mapDispatchToProps = {
  openAlertify: alertifyActions.openAlertify,
  clearAccount: currentUserActions.clear
};

export default withAPI(connect(null, mapDispatchToProps)(DeleteAccount));
