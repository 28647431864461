import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import css from './styles.module.scss';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className={css.empty}>
      <Typography variant="h6" color="textSecondary">
        { t('mediaItems.groups.continueWatching.empty') }
      </Typography>
    </div>
  )
}

export default Empty;
