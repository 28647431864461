import React, { useEffect, useState } from 'react';
import { array, bool, number, object, string } from "prop-types";
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import MUButton from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';

import withAPI from '../../../api/context';

import css from './styles.module.scss';
import Carousel from "../without-theme/carousel";

import { queryActions } from '../../query/actions';
import Empty from './empty';
import { entryActions } from '../../../entry/actions';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    '& > svg': {
      marginRight: 10
    }
  }
}))

const Button = withStyles((theme) => ({
  root: {
    textTransform: 'none'
  }
}))(MUButton);

const ContinueWatching = (props) => {
  const { api, currentUser, setWatcher, setSourceLanguage, params, endFetchData } = props;

  const {
    theme,
    order,
    levels,
    sourceLanguage,
    producerId,
    searchValue,
    withPrice,
    payerId,
    watcherId,
    perPage
  } = params

  const currentUserId = currentUser.id

  const styles = useStyles()

  const { t } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const [groups, setGroups] = useState([]);

  const renderHeader = () => {
    return (
      <div className={css.header}>
        <Typography
          classes={{ root: styles.header }}
          variant="h6"
          weight="bold"
          bottomSpace="small"
          color="textPrimary"
        >
          <VisibilityIcon color="primary" />
          { t('mediaItems.groups.continueWatching.headerTitle') }
        </Typography>
        { currentUserId && groups.length > 0 && (
          <Button color="primary" onClick={handleSeeMore}>
            { t('mediaItems.groups.seeMore') }
          </Button>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (!currentUserId) {
      return;
    }

    if (watcherId) {
      return;
    }

    if (fetching) {
      return;
    }

    fetchGroups();
  }, [currentUserId,     theme, order, levels,
    sourceLanguage,
    producerId,
    searchValue,
    withPrice,
    payerId]);


  const fetchGroups = () => {
    setFetching(true);

    api.fetchGroupsContinueWatching(params).then((data) => {
      setGroups(data);
      setFetching(false);
    }, (_error) => endFetchData())
  }

  const handleSeeMore = () => {
    setSourceLanguage({ name: t('sourceLanguage.all'), code: null })
    setWatcher({ id: currentUser.id, fullName: currentUser.full_name });
  }

  const renderContent = () => {
    if (watcherId) {
      return null;
    }

    if (groups.length === 0) {
      return <Empty />
    }

    if (currentUserId) {
      return <Carousel groups={groups} handleSeeMore={handleSeeMore} perPage={perPage} />
    }
  }

  if (currentUserId) {
    return (
      <div className={css.continueWatching}>
        {!watcherId && renderHeader()}
        {renderContent()}
      </div>
    )
  }

  return null;
}

ContinueWatching.propTypes = {
  perPage: number,
  theme: string,
  order: string,
  levels: array,
  sourceLanguage: object,
  producerId: number,
  searchValue: string,
  withPrice: bool,
  payerId: number,
  watcherId: number
}

ContinueWatching.defaultProps = {
  perPage: 20
}

const mapStateToProps = ({ mediaItems: { query }, currentUser }) => (
  {
    currentUser
  }
);

const mapDispatchToProps = {
  setWatcher: queryActions.setWatcher,
  setSourceLanguage: queryActions.setSourceLanguage,
  endFetchData: entryActions.endFetchData
};

export default withAPI(connect(mapStateToProps, mapDispatchToProps)(ContinueWatching));
