import React, { Component } from 'react';
import PropTypes from 'prop-types';

import queryString from 'querystring';

import Media from 'react-media';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { FloatingMediaSettingsProvider } from 'alp-shared-components/dist/shared/floating-media/settings-provider';

import css from './styles.module.scss';

import Info from './info';
import Statistics from './statistics';
import Words from './words';

import { menuActions } from '../shared/menu/actions';
import withAPI from '../api/context';
import { profileActions } from './actions';
import { profileUrl } from "./utils";

import { tabs } from './constants';
import Content from "./content";

class Profile extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchProfileData();
    this.handleCurrentTab();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;

    if (prevProps.match.params.id !== id) {
      this.fetchProfileData();
    }
  }

  componentWillUnmount() {
    const { setCurrentTab } = this.props;
    setCurrentTab(tabs.info);
  }

  fetchProfileData() {
    const { match: { params: { id } }, location: { search }, fetchData } = this.props;

    let currentFolderId = null;
    const encodeCurrentFolderId = queryString.parse(search.replace(/^\?/, '')).p;

    if (encodeCurrentFolderId) {
      currentFolderId = parseInt(atob(encodeCurrentFolderId));
    }

    fetchData(id, currentFolderId).then(this.fetchProfileDataSuccess);
  }

  handleCurrentTab() {
    const { setCurrentTab, location: { search } } = this.props;

    const tab = queryString.parse(search)['?tab'];
    let tabIndex = tabs[tab];

    const encodeCurrentFolderId = queryString.parse(search.replace(/^\?/, '')).p;

    if (encodeCurrentFolderId) {
      setCurrentTab(tabs['files']);
    }

    if (tabIndex) {
      setCurrentTab(tabIndex);
    }
  }

  fetchProfileDataSuccess = (profile) => {
    const { match: { params: { id } }, history } = this.props;

    if (profile.slug !== id) {
      history.replace({ pathname: profileUrl(profile.slug), search: this.props.location.search })
    }
  };

  onChangeTab = (e, currentTab) => {
    const { setCurrentTab } = this.props;
    setCurrentTab(currentTab);
  };

  onChangeMedia = (matchMedia) => {
    const { setMenuVariant, closeMenu } = this.props;

    matchMedia ? (setMenuVariant('persistent')) : setMenuVariant('temporary') && closeMenu();
  };

  renderTabContent = () => {
    const { currentTab } = this.props;

    switch (currentTab) {
      case 0:
        return <Info />;
      case 1:
        return <Content />;
      case 2:
        return <Statistics />;
      case 3:
        return <Words />;
      default:
        return <Content />;
    }
  };

  render() {
    const { t } = this.props;
    const { currentTab } = this.props;

    return (
      <FloatingMediaSettingsProvider>
        <Media
          query={{minWidth: 1680}}
          onChange={this.onChangeMedia}
        >
          {() =>
            <div className={css.profile}>
              <div className={css.wrapper}>
                <Paper className={css.content}>
                  <Tabs
                    value={currentTab}
                    onChange={this.onChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={t('profile.tabs.info')}/>
                    <Tab label={t('profile.tabs.files')}/>
                    <Tab label={t('profile.tabs.statistics')}/>
                    <Tab label={t('profile.tabs.words')}/>
                  </Tabs>

                  {this.renderTabContent()}
                </Paper>
              </div>
            </div>
          }
        </Media>
      </FloatingMediaSettingsProvider>
    );
  }
};

Profile.propTypes = {
  match: PropTypes.shape({ // from Router
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  setMenuVariant: PropTypes.func.isRequired, // from menuActions
  fetchData: PropTypes.func.isRequired, // from profileActions
  t: PropTypes.func.isRequired, // from withTranslation
  currentTab: PropTypes.number, // from profile store
  setCurrentTab: PropTypes.func // from profileActions
};

const mapDispatchToProps = {
  setMenuVariant: menuActions.setVariant,
  closeMenu: menuActions.closeMenu,
  fetchData: profileActions.fetchData,
  setCurrentTab: profileActions.setCurrentTab
};

const mapStateToProps = ({ profile }) => ({
  currentTab: profile.currentTab
});

export default withAPI(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Profile))
);
