import React from 'react';
import { bool, func } from 'prop-types';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { useTranslation } from 'react-i18next';

import Item from '../../../../../ui/menu/item';

const Save = (props) => {
  const { onClick, visible } = props;
  const { t } = useTranslation();

  if (visible) {
    return (
      <Item onClick={onClick} icon={<AddBoxIcon color="primary" />} text={t('profile.files.folderActions.save')} />
    )
  }

  return null;
};

Save.propsTypes = {
  visible: bool.isRequired, // from props
  onClick: func.isRequired // from props
};

export default Save
