import React, { Children } from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import Base from './base';
import css from './styles.module.scss';

const Article = (props) => {
  const { title, children } = props;

  return (
    <div className={css.article}>
      <div className={css.title}>{ title }</div>
      <div className={css.content}>
        { Children.map(children, (child) => child) }
      </div>
    </div>
  )
};

const Terms = () => {
  const { t } = useTranslation();

  const renderMetaTags = () => {
    const title = `${process.env.REACT_APP_PROJECT_NAME} - Terms`;
    const description = `Thanks for using ${process.env.REACT_APP_PROJECT_NAME}, we hope you like it. The Terms of Use are below. To help get the general point across, as many folks skip reading these, we’ve summarized the key points here.`;

    const image = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/background-logo.png`;
    const ogUrl = `${process.env.REACT_APP_3EARS_PRODUCTION_URL}/terms`;

    return (
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    )
  };

  return (
    <Base>
      { renderMetaTags() }

      <div className={css.terms}>
        <h1>{ t('terms.head', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</h1>

        <div className={css.article}>
          <div className={css.title}>{ t('terms.introduction.title') }</div>
          <div className={css.content}>
            { t('terms.introduction.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }
            <ul>
              <li>
                { t('terms.introduction.point_one') }
              </li>
              <li>
                { t('terms.introduction.point_two', { projectName: process.env.REACT_APP_PROJECT_NAME }) }
              </li>
            </ul>
          </div>
        </div>

        <Article title={t('terms.registration.title')} >
          <div>{ t('terms.registration.part_one', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.registration.part_two', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.registration.part_three', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.registration.part_four', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.registration.part_five', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.registration.part_six', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.userProfileInformation.title')} >
          <div>{ t('terms.userProfileInformation.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.userContentAndLinks.title')} >
          <div>{ t('terms.userContentAndLinks.part_one', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.userContentAndLinks.part_two', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.limitationOfLiability.title')} >
          <div>{ t('terms.limitationOfLiability.part_one', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.limitationOfLiability.part_two', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.limitationOfLiability.part_three', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.limitationOfLiability.part_four', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.singleUserUsage.title')} >
          <div>{ t('terms.singleUserUsage.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.termination.title')} >
          <div>{ t('terms.termination.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.policies.title')} >
          <div>{ t('terms.policies.part_one', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_two', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_three', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_four', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_five', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_six', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
          <div>{ t('terms.policies.part_seven', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.modificationToAgreement.title')} >
          <div>{ t('terms.modificationToAgreement.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.modificationTo3ears.title', { projectName: process.env.REACT_APP_PROJECT_NAME })} >
          <div>{ t('terms.modificationTo3ears.content', { projectName: process.env.REACT_APP_PROJECT_NAME }) }</div>
        </Article>

        <Article title={t('terms.contactUs.title')} >
          <div>{ t('terms.contactUs.content') }</div>
        </Article>

        <Article title={t('terms.version.title')} />
      </div>
    </Base>
  );
};

export default Terms;
