import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import clsx from 'clsx';

import cssText from '../../../css/text.module.scss';

const Title = (props) => {
  const { feedback } = props;

  const className = clsx(cssText.cl_blue_primary, cssText.paragraph_2);
  const { t } = useTranslation();

  return (
    <Breadcrumbs separator="|">

      <span className={className}>
        { t(`feedback.kinds.${feedback.kind}`) }
      </span>

      <span className={className}>
        { t(`feedback.states.${feedback.state}`) }
      </span>
    </Breadcrumbs>
  )
};

Title.propTypes = {
  feedback: object // from props
};

export default Title;
