import React from 'react';
import { bool, func, node, string } from 'prop-types';

import { default as MUList } from '@material-ui/core/List';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import css from './styles.module.scss';

const List = (props) => {
  const { children, head, listOpened, setListOpened } =  props;

  const toggleList = () => {
    setListOpened(!listOpened)
  };

  return (
    <MUList dense component="div" disablePadding>
      <ListItem button onClick={toggleList}>
        <ListItemText className={css.menuItemText} primary={head}/>
        {listOpened ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
      </ListItem>

      <Collapse in={listOpened} timeout="auto" unmountOnExit>
        <MUList dense component="div" disablePadding className={css.list}>
          { children }
        </MUList>
      </Collapse>
    </MUList>
  )
};

List.propTypes = {
  children: node.isRequired,
  head: string.isRequired,
  listOpened: bool,
  setListOpened: func
};

export default List;
