import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TranslateIcon from '@material-ui/icons/Translate';
import TextField from '@material-ui/core/TextField';
import { queryActions } from '../../media-items/query/actions';

const MediaItemsFilterMultiLanguage = (props) => {
  const {
    multiLanguage,
    sourceLanguage = {},
    translationLanguage = '',
    setMultiLanguage,
    setSourceLanguage,
    setTranslationLanguage
  } = props;

  const toggleMultiLanguage = () => setMultiLanguage(!multiLanguage);

  const handleSourceLanguageChange = ({ target: { value, key }}) => setSourceLanguage({ code: value, name: key });
  const handleTranslationLanguageChange = ({ target: { value }}) => setTranslationLanguage(value);

  return (
    <List dense disablePadding>
      <ListItem button onClick={toggleMultiLanguage}>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary="MultiLanguage" />
        <ListItemSecondaryAction onClick={toggleMultiLanguage}>
          <Checkbox checked={multiLanguage} />
        </ListItemSecondaryAction>
      </ListItem>
      {multiLanguage && (
        <Fragment>
          <ListItem>
            <TextField
              value={sourceLanguage.code}
              label="Source Language"
              onChange={handleSourceLanguageChange}
            />
          </ListItem>
          <ListItem>
            <TextField
              value={translationLanguage}
              label="Translation Language"
              onChange={handleTranslationLanguageChange}
            />
          </ListItem>
        </Fragment>
      )}
    </List>
  )
};

const mapStateToProps = ({ mediaItems: { query } }) => {
  const { multiLanguage, sourceLanguage, translationLanguage } = query;
  return { multiLanguage, sourceLanguage, translationLanguage };
};

const mapDispatchToProps = {
  setMultiLanguage: queryActions.setMultiLanguage,
  setSourceLanguage: queryActions.setSourceLanguage,
  setTranslationLanguage: queryActions.setTranslationLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaItemsFilterMultiLanguage);
