import React, { useState, useEffect } from 'react';
import { array, object, func } from 'prop-types';

import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';

import Svg from "../../ui/svg";

import MenuList from './list';
import { queryActions } from '../../media-items/query/actions';
import { getDataFromLocalStorage, setDataToLocalStorage } from "../../local-storage";
import { LEFT_MENU_KEYS } from "../../local-storage/constants";

const listOpenedStored = getDataFromLocalStorage(LEFT_MENU_KEYS.openTheme)

const Themes = (props) => {
  const {
    themes,
    queryTheme,
    setTheme,
    history
  } = props;

  const [listOpened, setListOpened] = useState(listOpenedStored ? true : listOpenedStored);

  useEffect(() => {
    setDataToLocalStorage(LEFT_MENU_KEYS.openTheme, listOpened)
  }, [listOpened])

  const { t } = useTranslation();

  const clickOnTheme = (theme) => () => {
    history.replace('/');

    if (queryTheme === theme) {
      setTheme(null)
    }
    else {
      setTheme(theme)
    }
  };

  return (
    <MenuList head={t('menu.themes')} listOpened={listOpened} setListOpened={setListOpened}>
      { themes.map((theme) => {
        const isThemeSelected = theme === queryTheme

        return (
          <ListItem
            selected={isThemeSelected}
            button
            key={theme}
            onClick={clickOnTheme(theme)}
          >
            <ListItemIcon>
              <Svg name={theme} />
            </ListItemIcon>
            <ListItemText primary={t(`themesList.${theme}`)}/>
            { isThemeSelected && <CheckIcon color="primary" />}
          </ListItem>
        )
      })}
    </MenuList>
  )
};

Themes.propTypes = {
  themes: array,
  queryTheme: array.isRequired, // from mediaItems.query store
  setTheme: func.isRequired, // from queryActions
  history: object // from props
};

const mapStateToProps = ({ header, mediaItems: { query } }) => (
  {
    queryTheme: query.theme,
    themes: header.themes
  }
);

const mapDispatchToProps = {
  setTheme: queryActions.setTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(Themes)

