import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import menu from './shared/menu/reducer';
import header from './shared/header/reducer';
import entry from './entry/reducer';
import mediaItems from './media-items/reducer';
import mediaItemsQueryMiddleware from './media-items/query/middleware';
import alertify from './shared/alertify/reducer';
import profile from './profile/reducer';
import currentUser from './current-user/reducer';
import authenticationModal from './shared/authentication-modal/reducer';
import feedbackCreator from './shared/feedback-creator/reducer';
import feedbacks from './feedbacks/reducer';
import feedback from './feedback/reducer';
import history from './history';
import monetization from './shared/monetization/reducer';
import landing from './shared/landing/reducer';
import { alertifyActions } from './shared/alertify/actions';
import { authenticationModalActions } from "./shared/authentication-modal/actions";

export default ({ ITL }) => {
  const reducers = combineReducers({
    alertify,
    menu,
    header,
    entry,
    mediaItems,
    profile,
    currentUser,
    authenticationModal,
    feedbackCreator,
    feedbacks,
    feedback,
    monetization,
    landing
  });

  const store = createStore(
    reducers,
    applyMiddleware(
      thunk.withExtraArgument({ ITL }),
      mediaItemsQueryMiddleware
    )
  );

  ITL.api.onError = (error) => {
    if (!error.response) {
      return;
    }

    switch (error.response.status) {
      case 500:
        store.dispatch(alertifyActions.openAlertify({ type: 'error', text: 'error500' }));
        break;
      case 401:
        store.dispatch(authenticationModalActions.openAuthenticationModal());
        break;
      case 404:
        history.push('/not-found');
        break;
      // case 422:
      //   store.dispatch(alertifyActions.openAlertify({ type: 'error', text: 'validations.globalMessage' }));
      //   break;
      default:
        break;
    }
  };

  return store;
};
