const actions = {
  OPEN_ALERTIFY: 'OPEN_ALERTIFY',
  DISMISS_ALERTIFY: 'DISMISS_ALERTIFY'
};

const openAlertify = (payload) => ({
  type: actions.OPEN_ALERTIFY, payload
});

const dismissAlertify = () => ({
  type: actions.DISMISS_ALERTIFY
});

const alertifyActions = {
  openAlertify,
  dismissAlertify
};

export { actions, alertifyActions };
