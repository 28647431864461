import { actions } from './actions';

const initialState = {
  id: null
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, ...action.payload };
    case actions.CLEAR:
      return initialState;
    default:
      return state
  }
};

export default currentUser;
