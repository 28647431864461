import React from 'react';
import { string } from 'prop-types';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  VKShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  VKIcon
} from "react-share";

import css from './styles.module.scss';
import ahoy from '../../setup_ahoy';

const SharedButtons = (props) => {
  const { shareLink } = props;

  const beforeOnClick = (socialProvider) => () => {
    ahoy().track('click_share_button', { social_provider: socialProvider, source: 'statistic', place: 'profile' });
  }

  return (
    <div className={css.social_buttons}>
      <div className={css.button}>
        <FacebookShareButton url={shareLink} beforeOnClick={beforeOnClick('facebook')}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className={css.button}>
        <TwitterShareButton url={shareLink} beforeOnClick={beforeOnClick('twitter')}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div className={css.button}>
        <LinkedinShareButton url={shareLink} beforeOnClick={beforeOnClick('linkedin')}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className={css.button}>
        <VKShareButton url={shareLink} beforeOnClick={beforeOnClick('vkontakte')}>
          <VKIcon size={32} round />
        </VKShareButton>
      </div>
    </div>
  )
}

SharedButtons.propTypes = {
  shareLink: string
}

export default SharedButtons;
